import React, { Fragment, useEffect, useRef, useState } from "react";

const PaymentRedirectPage = ({ match }) => {
  const [body, setBody] = useState("");
  const [encRequest, setEncRequest] = useState("");
  const [formbody, setFormbody] = useState("");
  const [accessCode, setAccessCode] = useState("");

  // useEffect(() => {
  //   const request = new XMLHttpRequest();
  //   request.open("GET", "https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction");
  //   request.onload = function () {
  //     if (request.status === 200) {
  //       setBody(request.responseText);
  //       setEncRequest(ccav.encrypt(body, workingKey));
  //       setFormbody(`<form id="nonseamless" method="post" name="redirect" action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"/> <input type="hidden" id="encRequest" name="encRequest" value="${encRequest}"><input type="hidden" name="access_code" id="access_code" value="${accessCode}"><script language="javascript">document.redirect.submit();</script></form>`);
  //     } else {
  //       console.log("Error: " + request.status);
  //     }
  //   };
  //   request.send();
  // }, []);
  const formRef = useRef(null);
  useEffect(() => {
    formRef.current.submit();
    console.log(match.params.encRequest);
  }, []);
  return (
    <div>
      <h1>Wait</h1>
      <p>
        We are Redirecting to Payment page...
      </p>
      <form ref={formRef} id="nonseamless" method="post" name="redirect" action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
        <input type="hidden" id="encRequest" name="encRequest" value={match.params.encRequest} />
        <input type="hidden" name="access_code" id="access_code" value={"AVHY36KC71BV97YHVB"} />
        <script language="javascript">document.redirect.submit();</script>
      </form>
    </div>
  );
};

export default PaymentRedirectPage;
