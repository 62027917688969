import { CModal } from "@coreui/react";
import React from "react";

function Mapdiractionloading({ ShowModal, close }) {
  return (
    <CModal
      show={ShowModal}
      onClose={close}
      className="modal-centered"
      centered
      size="xl"
      closeOnBackdrop={false}
      fade
    >
      Waiting we are getting your location....
    </CModal>
  );
}

export default Mapdiractionloading;
