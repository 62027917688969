import React from "react";
const Device = React.lazy(() =>
  import("./views/devices/transmitter/transmiter")
);
const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const Subscription = React.lazy(() =>
  import("./views/subscription/Subscription")
);
const Feedback = React.lazy(() => import("./views/feedback/Feedback"));
const DeviceDetails = React.lazy(() =>
  import("./views/devices/components/diveceDetails")
);
const MapDetails = React.lazy(() =>
  import("./views/mapFunctionallity/open_map")
);
const Transmiter = React.lazy(() =>
  import("./views/devices/transmitter/transmiter")
);
const MyProfile = React.lazy(() => import("./views/profile/myprofile"));
const Reports = React.lazy(() => import("./views/reports/allreports"));
const PonEdfa = React.lazy(() => import("./views/devices/Pon-Edfa/ponedfa"));
const Edfa = React.lazy(() => import("./views/devices/Edfa/edfa"));
const GponOLT = React.lazy(() => import("./views/devices/gPon/gpon_olt"));
const GeponOLT = React.lazy(() => import("./views/devices/gePon/gepon-olt"));
const devicetype = React.lazy(() => import("./views/devices/maindevicescreen"));
const deviceAdd = React.lazy(() => import("./views/devices/deviceadd"));
const CustomerLocations = React.lazy(() => import("./views/customers/customers_locations"));
const CustomerLocationsAdd = React.lazy(() => import("./views/customers/customer_add"));
const RenuwalPlan = React.lazy(() =>
  import("./views/subscription/renewalplan")
);
const DrawNew = React.lazy(() =>
  import("./views/mapFunctionallity/draws/draws")
);
const ReportPort = React.lazy(() =>
  import("./views/reports/portreport")
);
const Information = React.lazy(() =>
  import("./views/information/information")
);
const routes = [
  { path: "/app/", exact: true, name: "Home" },
  { path: "/app/dashboard", name: "Dashboard", component: Dashboard },
  {
    path: "/app/subscription",
    name: "Subscription",
    component: Subscription,
    exact: true,
  },
  { path: "/app/feedback", name: "Feedback", component: Feedback },
  {
    path: "/app/customers",
    name: "Customers",
    component: CustomerLocations,
    exact: true
  },
  { path: "/app/device", name: "Device", component: Dashboard, exact: true },
  { path: "/app/draws", name: "Draws", component: DrawNew, exact: true },
  { path: "/app/profile", exact: true, name: "Profile", component: MyProfile },
  { path: "/app/reports", exact: true, name: "Reports", component: Reports },
  { path: "/app/customers/addnew", name: "Customers New", component: CustomerLocationsAdd },
  {
    path: "/app/subscription/renewalplan",
    name: "renewal",
    component: RenuwalPlan,
  },
  {
    path: "/app/devices/:deviceName/:portnumber/portreport",
    name: "Port Report",
    component: ReportPort,
  },
  {
    path: "/app/informations",
    exact: true,
    name: "information",
    component: Information,
  },
  // {
  //   path: "/app/device/transmiter",
  //   exact: true,
  //   name: "Transmiter",
  //   component: Transmiter,
  // },
  // {
  //   path: "/app/device/pon-edfa",
  //   exact: true,
  //   name: "Pon-Edfa",
  //   component: PonEdfa,
  // },
  // { path: "/app/device/edfa", exact: true, name: "Edfa", component: Edfa },
  // {
  //   path: "/app/device/gpon-olt",
  //   exact: true,
  //   name: "Gpon-OLT",
  //   component: GponOLT,
  // },
  // {
  //   path: "/app/device/gepon-olt",
  //   exact: true,
  //   name: "Gepon-OLT",
  //   component: GeponOLT,
  // },

  // {
  //   path: "/app/device/transmitter",
  //   exact: true,
  //   name: "Transmitter",
  //   component: Transmiter,
  // },
  // {
  //   path: "/app/device/pon-edfa",
  //   exact: true,
  //   name: "Pon Edfa",
  //   component: PonEdfa,
  // },
  {
    path: "/app/device/:deviceName",
    exact: true,
    name: "device",
    component: devicetype,
  },
  
  {
    path: "/app/device/:deviceName/:portdefault/new",
    name: "New Device",
    component: deviceAdd,
  },
  {
    path: "/app/devices/:deviceName/:id",
    exact: true,
    name: "Device Details",
    component: DeviceDetails,
  },
  // {
  //   path: "/app/devices/:deviceName/:port/:id/maping/:id",
  //   exact: true,
  //   name: "Map Details",
  //   component: MapDetails,
  // },
];

export default routes;
