import { CCard, CModal } from "@coreui/react";
import React from "react";
import simpleload from "../../assets/Loading/simpleload.gif";
const LoadingModel = ({ handleModel, handleCloseModel }) => {
  return (
    <div>
      <CModal
        show={handleModel}
        onClose={handleCloseModel}
        className="modal-centered"
        centered
        role="dialog"
        size="sm"
        closeOnBackdrop={false}
        style={{
          backgroundColor: "transparent",
          border: 0,
          borderColor: "transparent",
        }}
        // color="transparent"
        // backdrop={false}
        fade
      >
        <CCard
          style={{
            backgroundColor: "transparent",
            border: 0,
            borderColor: "transparent",
          }}
        >
          <img src={simpleload} />
        </CCard>
      </CModal>
    </div>
  );
};

export default LoadingModel;
