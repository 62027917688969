import {
  CInput,
  CRow,
  CCol,
  CButton,
  CTabContent,
  CTabs,
  CTabPane,
  CNavItem,
  CNavLink,
  CNav,
  CCard,
  CCardHeader,
  CCardBody,
} from "@coreui/react";
import {
  Satellite,
  Delete,
  ArrowForward,
  FlashOnRounded,
} from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import L from "leaflet";
import { MapApi } from "src/Config/API";
import drawpoliicon from "../../../../../../assets/mapsicons/draw-polyline.svg";
import swal from "sweetalert";

export function CustomePLCSShow({
  data,
  index,
  mapDataFinal,
  handleImageView,
  setPLCSIDForSaveLossData,
  sethandleLossModalForPLCS,
  PostPoliline,
  forftth,
  // setSelectedLossId,
  DeleteDataByID,
  getMasteData,
  MapKey,
}) {
  const [InputData, setInputData] = useState("");
  const [InputImage, setInputImage] = useState(null);
  const [DisableAddFiberLine, setDisableAddFiberLine] = useState(false);
  const [remaining_fiberline, setremaining_fiberline] = useState();
  useEffect(() => {
    setInputData(data.name);
  }, [data]);

  function EditPLCSById(id, InputValue, InputImage) {
    const form = new FormData();
    form.append("name", InputValue);
    // form.append("loss_id", SelectedLossId);

    if (InputImage !== null) {
      form.append("images", InputImage, InputImage.name);
    }
    console.log(form);
    // var data = {
    //   name: InputValue,
    //   images: InputImage,
    // };
    MapApi.UpdateMasterDataById(id, form, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        swal("Saved!", "Information saved successfully!", "success");

        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const [getlatforcurruntLocation, setgetlatforcurruntLocation] = useState("");
  const [getlangforcurruntLocation, setgetlangforcurruntLocation] =
    useState("");
  const [
    LoadingModalShowForDirectionOpenMap,
    setLoadingModalShowForDirectionOpenMap,
  ] = useState(false);
  const getMyLocation = ({ lat, lang }) => {
    const location = window.navigator && window.navigator.geolocation;
    // setLoadingModalShowForDirectionOpenMap(true);
    var options = {
      enableHighAccuracy: true,
    };
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLoadingModalShowForDirectionOpenMap(false);
          setgetlatforcurruntLocation(position.coords.latitude);
          setgetlangforcurruntLocation(position.coords.longitude);

          console.log(
            "https://www.google.com/maps/dir/" +
              position.coords.latitude +
              "," +
              position.coords.longitude +
              "/" +
              lat +
              "," +
              lang
          );

          // window.location.replace(
          //   "https://www.google.com/maps/dir/" +
          //     position.coords.latitude +
          //     "," +
          //     position.coords.longitude +
          //     "/" +
          //     lat +
          //     "," +
          //     lang
          // );
        },
        (error) => {
          console.log(error);
        },
        options
      );
    }
  };
  return (
    <>
      {forftth ? (
        <>
          <CCard>
            <CCardHeader>
              <center>
                <h5>PLC</h5>{" "}
                {data.max !== null ? (
                  <center>
                    <b>
                      {" "}
                      <div style={{}}>
                        {"1 X "}
                        {data.max.name ?? ""}
                      </div>
                    </b>
                  </center>
                ) : (
                  <div></div>
                )}
              </center>
            </CCardHeader>
            <CCardBody>
              <CTabs activeTab="loss">
                <CTabContent>
                  <CTabPane data-tab="loss">
                    <CRow>
                      <CCol>
                        <CButton
                          onClick={() => {
                            setPLCSIDForSaveLossData();
                            // setSelectedLossId();
                            sethandleLossModalForPLCS();
                            // setPLCSIDForSaveLossData(data.id);
                            // setSelectedLossId(data.loss_id);
                            // sethandleLossModalForPLCS(!handleLossModalForPLCS);
                          }}
                          color="info"
                          variant="outline"
                          block
                        >
                          Select PLC <ArrowForward />
                        </CButton>
                      </CCol>
                    </CRow>
                    {data.loss !== null ? (
                      <center>{data.loss_type ?? ""}</center>
                    ) : (
                      <div></div>
                    )}
                    <div
                      style={{
                        backgroundColor: "#bfd8ff",
                        padding: 10,
                        fontWeight: "bold",
                        borderRadius: 5,
                        marginLeft: 1,
                        marginRight: 1,
                        marginTop: 10,
                      }}
                    >
                      <center>
                        {" "}
                        INPUT OPTICAL POWER :{" "}
                        <CRow>
                          {mapDataFinal.port.wdm_status === 1 ? (
                            <CCol>
                              <div
                                style={{
                                  backgroundColor: "#fff",
                                  padding: 1,
                                  fontWeight: "bold",
                                  borderRadius: 5,
                                  fontSize: 14,
                                  margin: 1,
                                }}
                              >
                                1490 NM : &nbsp;{" "}
                                {data.loss_1490NM !== null
                                  ? data.loss_1490NM + " DB"
                                  : ""}
                              </div>{" "}
                            </CCol>
                          ) : (
                            <></>
                          )}
                        </CRow>
                        {data.loss_1550NM !== null ? (
                          <CRow>
                            <CCol>
                              <div
                                style={{
                                  backgroundColor: "#fff",
                                  padding: 1,
                                  fontWeight: "bold",
                                  borderRadius: 5,
                                  margin: 1,
                                }}
                              >
                                &nbsp;1550 NM :{" "}
                                {data.loss_1550NM !== null
                                  ? data.loss_1550NM + " DB"
                                  : ""}
                              </div>
                            </CCol>
                          </CRow>
                        ) : (
                          <></>
                        )}
                      </center>{" "}
                    </div>

                    <br />
                    <CRow>
                      <CCol
                        style={{
                          backgroundColor: "#bfd8ff",
                          padding: 10,
                          borderRadius: 5,
                          margin: 5,
                        }}
                      >
                        <center>Type</center>
                      </CCol>
                      <CCol
                        style={{
                          backgroundColor: "#bfd8ff",
                          padding: 10,
                          borderRadius: 5,
                          margin: 5,
                        }}
                      >
                        <center>
                          {" "}
                          {data.loss !== null ? (
                            data.loss.high_name
                          ) : (
                            <div>Max</div>
                          )}{" "}
                        </center>
                      </CCol>
                    </CRow>

                    {MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                      <></>
                    ) : data.max !== null ? (
                      data.max.loss_1550NM !== null ? (
                        <>
                          CATV
                          <CRow>
                            <CCol
                              style={{
                                backgroundColor: "#bfd8ff",
                                padding: 10,
                                borderRadius: 5,
                                margin: 5,
                              }}
                            >
                              <CInput
                                style={{
                                  width: "100%",
                                  fontWeight: "bold",
                                }}
                                value={"1550NM"}
                              />
                            </CCol>
                            <CCol
                              style={{
                                backgroundColor: "#bfd8ff",
                                padding: 10,
                                borderRadius: 5,
                                margin: 5,
                              }}
                            >
                              <div class="input-box-DB">
                                <CInput
                                  style={{ width: "100%" }}
                                  // mask="99 . 99"
                                  // readOnly
                                  value={
                                    data.max !== null ? data.max.loss_1550NM : 0
                                  }
                                />
                                <span class="prefix">DB</span>
                              </div>
                            </CCol>
                          </CRow>
                        </>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                    {mapDataFinal.port.wdm_status === 1 ? (
                      <>
                        INTERNET
                        <CRow>
                          <CCol
                            style={{
                              backgroundColor: "#bfd8ff",
                              padding: 10,
                              borderRadius: 5,
                              margin: 5,
                            }}
                          >
                            {mapDataFinal.port.wdm_status === 1 ? (
                              <>
                                <CInput
                                  style={{
                                    width: "100%",

                                    fontWeight: "bold",
                                  }}
                                  // mask="99 . 99"
                                  // readOnly
                                  value={"1490NM"}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </CCol>
                          <CCol
                            style={{
                              backgroundColor: "#bfd8ff",
                              padding: 10,
                              borderRadius: 5,
                              margin: 5,
                            }}
                          >
                            {mapDataFinal.port.wdm_status === 1 ? (
                              <>
                                <div class="input-box-DB">
                                  <CInput
                                    style={{ width: "100%" }}
                                    // mask="99 . 99"
                                    // readOnly
                                    value={
                                      data.max !== null
                                        ? data.max.loss_1490NM
                                        : 0
                                    }
                                  />
                                  <span class="prefix">DB</span>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </CCol>
                        </CRow>
                      </>
                    ) : (
                      <></>
                    )}
                    <CRow>
                      <CCol
                        style={{
                          backgroundColor: "#bfd8ff",
                          padding: 5,
                          fontWeight: "bold",
                          borderRadius: 5,
                          margin: 5,
                        }}
                      >
                        <center>
                          Remaining FiberLine{"\n\n\n\n\n\n"}
                          {" :  "}
                          {data.max !== null
                            ? data.max.remaining_fiberline
                            : "0"}
                        </center>
                      </CCol>
                    </CRow>
                  </CTabPane>

                  <CTabPane data-tab="other_info">
                    <CInput
                      onChange={(v) => setInputData(v.target.value)}
                      value={InputData}
                    ></CInput>
                    <CRow>
                      <CCol>
                        <p
                          style={{
                            color: "black",
                            fontsize: 20,
                            fontWeight: "bold",
                          }}
                        >
                          Upload Image{" "}
                          {data.images !== null ? (
                            <Satellite onClick={() => handleImageView()} />
                          ) : (
                            <></>
                          )}
                        </p>
                      </CCol>
                    </CRow>
                    <CInput
                      type="file"
                      placeholder=""
                      onChange={(v) => setInputImage(v.target.files[0])}
                      // value={InputImage}
                    ></CInput>
                    <br />

                    <CRow>
                      <CCol>
                        <CButton
                          onClick={() =>
                            EditPLCSById(data.id, InputData, InputImage)
                          }
                          color="primary"
                          block
                          // variant="outline"
                        >
                          {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                          Save{" "}
                        </CButton>
                      </CCol>
                      <CCol>
                        <CButton
                          onClick={() => DeleteDataByID()}
                          color="secondary"
                          block
                          variant="outline"
                        >
                          <Delete color="#ff0000" style={{ color: "red" }} />
                        </CButton>
                      </CCol>
                    </CRow>
                    <br />
                  </CTabPane>
                </CTabContent>
                <CNav variant="tabs" justified="center">
                  <CNavItem>
                    <CNavLink
                      data-tab="loss"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Loss{" "}
                    </CNavLink>
                  </CNavItem>

                  <CNavItem>
                    <CNavLink
                      data-tab="other_info"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Other Info
                    </CNavLink>
                  </CNavItem>
                </CNav>
              </CTabs>
              {data.images !== null ? (
                <CButton
                  color="primary"
                  block
                  style={{ marginTop: 10 }}
                  onClick={() => handleImageView()}
                >
                  Show Image &nbsp;
                  <Satellite
                    onClick={
                      () => handleImageView()
                      // handleImageView(
                      //   data.images
                      // )
                    }
                  />
                </CButton>
              ) : (
                <></>
              )}
            </CCardBody>
          </CCard>
        </>
      ) : (
        <Marker
          position={[
            parseFloat(data.latlang.lat),
            parseFloat(data.latlang.lang),
          ]}
          icon={L.icon({
            iconUrl:
              data.max !== null
                ? MapApi.getSplitterIcon(data.max.name)
                : MapApi.getSplitterIcon("0"),
            iconAnchor: [35, 35],
            popupAnchor: [10, -44],
            iconSize: [70, 40],
          })}
        >
          <>
            {data.images !== null ? (
              <Tooltip sticky>
                <center>{InputData}</center>

                <img src={data.images} width="200" height={"200"} />
              </Tooltip>
            ) : (
              <Tooltip sticky>
                <center>{InputData}</center>
              </Tooltip>
            )}
          </>
          <Popup minWidth={300} closeButton={false}>
            <center>
              <h5>PLC</h5>
            </center>
            <CTabs activeTab="input">
              <CTabContent>
                <CTabPane data-tab="input">
                  <br />
                  <div
                    style={{
                      backgroundColor: "#bfd8ff",
                      padding: 2,
                      fontWeight: "bold",
                      borderRadius: 5,
                      margin: 2,
                    }}
                  >
                    <center>
                      {" "}
                      INPUT OPTICAL POWER :
                      <CRow>
                        {mapDataFinal.port.wdm_status === 1 ? (
                          <CCol>
                            {" "}
                            <div
                              style={{
                                backgroundColor: "#fff",
                                padding: 2,
                                fontWeight: "bold",
                                borderRadius: 5,
                                margin: 5,
                              }}
                            >
                              1490 NM: &nbsp;{" "}
                              {data.loss_1490NM !== null
                                ? data.loss_1490NM + " DB"
                                : ""}
                            </div>{" "}
                          </CCol>
                        ) : (
                          <></>
                        )}
                      </CRow>
                      {MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                        <></>
                      ) : (
                        <>{data.loss_1550NM !== null ? (
                          <CRow>
                            <CCol>
                              <div
                                style={{
                                  backgroundColor: "#fff",
                                  padding: 2,
                                  fontWeight: "bold",
                                  borderRadius: 5,
                                  margin: 2,
                                }}
                              >
                                &nbsp;1550 NM:{" "}
                                {data.loss_1550NM !== null
                                  ? data.loss_1550NM + " DB"
                                  : ""}
                              </div>
                            </CCol>
                          </CRow>
                        ) : (
                          <></>
                        )}</>
                      )}
                      
                    </center>{" "}
                  </div>

                  <br />
                </CTabPane>

                <CTabPane data-tab="plc">
                  <CRow>
                    <CCol>
                      <CButton
                        onClick={() => {
                          setPLCSIDForSaveLossData();
                          // setSelectedLossId();
                          sethandleLossModalForPLCS();
                          // setPLCSIDForSaveLossData(data.id);
                          // setSelectedLossId(data.loss_id);
                          // sethandleLossModalForPLCS(!handleLossModalForPLCS);
                        }}
                        color="info"
                        variant="outline"
                        block
                      >
                        Select PLC <ArrowForward />
                      </CButton>
                      <br />
                    </CCol>
                  </CRow>

                  {data.max !== null ? (
                    <center>
                      <b>
                        {" "}
                        <div
                          style={{
                            backgroundColor: "#bfd8ff",
                            padding: 10,
                            borderRadius: 5,
                            margin: 5,
                          }}
                        >
                          {"1 X "}
                          {data.max.name ?? ""}
                        </div>
                      </b>
                      {data.loss_type ?? ""}
                    </center>
                  ) : (
                    <div></div>
                  )}

                  <br />
                </CTabPane>

                <CTabPane data-tab="output">
                  <br />
                  <CRow>
                    <CCol
                      style={{
                        backgroundColor: "#bfd8ff",
                        padding: 10,
                        borderRadius: 5,
                        margin: 5,
                      }}
                    >
                      <center>Type</center>
                    </CCol>
                    <CCol
                      style={{
                        backgroundColor: "#bfd8ff",
                        padding: 10,
                        borderRadius: 5,
                        margin: 5,
                      }}
                    >
                      <center>
                        {" "}
                        {data.loss !== null ? (
                          data.loss.high_name
                        ) : (
                          <div>Max</div>
                        )}{" "}
                      </center>
                    </CCol>
                  </CRow>

                  {MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                    <></>
                  ) : (
                    <>
                      {MapKey === "transmitter" ? (
                        <>
                          CATV
                          <CRow>
                            <CCol
                              style={{
                                backgroundColor: "#bfd8ff",
                                padding: 10,
                                borderRadius: 5,
                                margin: 5,
                              }}
                            >
                              <CInput
                                style={{
                                  width: "100%",
                                  fontWeight: "bold",
                                }}
                                value={"1550NM"}
                              />
                            </CCol>
                            <CCol
                              style={{
                                backgroundColor: "#bfd8ff",
                                padding: 10,
                                borderRadius: 5,
                                margin: 5,
                              }}
                            >
                              <div class="input-box-DB">
                                <CInput
                                  style={{ width: "100%" }}
                                  // mask="99 . 99"
                                  // readOnly
                                  value={
                                    data.max !== null ? data.max.total_loss : 0
                                  }
                                />
                                <span class="prefix">DB</span>
                              </div>
                            </CCol>
                          </CRow>
                        </>
                      ) : data.max !== null ? (
                        data.max.loss_1550NM !== null ? (
                          <>
                            CATV
                            <CRow>
                              <CCol
                                style={{
                                  backgroundColor: "#bfd8ff",
                                  padding: 10,
                                  borderRadius: 5,
                                  margin: 5,
                                }}
                              >
                                <CInput
                                  style={{
                                    width: "100%",
                                    fontWeight: "bold",
                                  }}
                                  value={"1550NM"}
                                />
                              </CCol>
                              <CCol
                                style={{
                                  backgroundColor: "#bfd8ff",
                                  padding: 10,
                                  borderRadius: 5,
                                  margin: 5,
                                }}
                              >
                                <div class="input-box-DB">
                                  <CInput
                                    style={{ width: "100%" }}
                                    // mask="99 . 99"
                                    // readOnly
                                    value={
                                      data.max !== null
                                        ? data.max.loss_1550NM
                                        : 0
                                    }
                                  />
                                  <span class="prefix">DB</span>
                                </div>
                              </CCol>
                            </CRow>
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  {mapDataFinal.port.wdm_status === 1 ? (
                    <>
                      INTERNET
                      <CRow>
                        <CCol
                          style={{
                            backgroundColor: "#bfd8ff",
                            padding: 10,
                            borderRadius: 5,
                            margin: 5,
                          }}
                        >
                          {mapDataFinal.port.wdm_status === 1 ? (
                            <>
                              <CInput
                                style={{
                                  width: "100%",

                                  fontWeight: "bold",
                                }}
                                // mask="99 . 99"
                                // readOnly
                                value={"1490NM"}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </CCol>
                        <CCol
                          style={{
                            backgroundColor: "#bfd8ff",
                            padding: 10,
                            borderRadius: 5,
                            margin: 5,
                          }}
                        >
                          {mapDataFinal.port.wdm_status === 1 ? (
                            <>
                              <div class="input-box-DB">
                                <CInput
                                  style={{ width: "100%" }}
                                  // mask="99 . 99"
                                  // readOnly
                                  value={
                                    data.max !== null ? data.max.loss_1490NM : 0
                                  }
                                />
                                <span class="prefix">DB</span>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </CCol>
                      </CRow>
                    </>
                  ) : (
                    <></>
                  )}
                  <CRow>
                    <CCol
                      style={{
                        backgroundColor: "#bfd8ff",
                        padding: 10,
                        borderRadius: 5,
                        margin: 5,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Remaining FiberLine{"\n\n"}{" "}
                      {data.max !== null ? data.max.remaining_fiberline : "0"}
                    </CCol>
                    <CCol
                      style={{
                        backgroundColor: "#bfd8ff",
                        padding: 10,
                        borderRadius: 5,
                        margin: 5,
                      }}
                    >
                      {data.max !== null ? (
                        <CButton
                          color="primary"
                          block
                          onClick={
                            () => PostPoliline()
                            // PostPoliline(
                            //   data.max.id,
                            //   parseFloat(data.latlang.lat),
                            //   parseFloat(data.latlang.lang)
                            // )
                          }
                          // disabled={data.max.remaining_fiberline > 0 ? false : true}
                        >
                          Add FiberLine
                        </CButton>
                      ) : (
                        <div></div>
                      )}
                    </CCol>
                  </CRow>
                  <br />
                </CTabPane>

                <CTabPane data-tab="other_info">
                  <CInput
                    onChange={(v) => setInputData(v.target.value)}
                    value={InputData}
                  ></CInput>
                  <CRow>
                    <CCol>
                      <p
                        style={{
                          color: "black",
                          fontsize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        Upload Image{" "}
                        {data.images !== null ? (
                          <Satellite onClick={() => handleImageView()} />
                        ) : (
                          <></>
                        )}
                      </p>
                    </CCol>
                  </CRow>
                  <CInput
                    type="file"
                    placeholder=""
                    onChange={(v) => setInputImage(v.target.files[0])}
                    // value={InputImage}
                  ></CInput>
                  <br />

                  <CRow>
                    <CCol>
                      <CButton
                        onClick={() =>
                          EditPLCSById(data.id, InputData, InputImage)
                        }
                        color="primary"
                        block
                        // variant="outline"
                      >
                        {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                        Save{" "}
                      </CButton>
                    </CCol>
                    <CCol>
                      <CButton
                        onClick={() => DeleteDataByID()}
                        color="secondary"
                        block
                        variant="outline"
                      >
                        <Delete color="#ff0000" style={{ color: "red" }} />
                      </CButton>
                    </CCol>
                  </CRow>
                  <br />
                </CTabPane>
              </CTabContent>
              <CNav variant="tabs" justified="center">
                <CNavItem>
                  <CNavLink
                    data-tab="input"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    Input Power{" "}
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink data-tab="plc" style={{ fontFamily: "sans-serif" }}>
                    Add PLC{" "}
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink
                    data-tab="output"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    OutPut Power{" "}
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink
                    data-tab="other_info"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    Other Info
                  </CNavLink>
                </CNavItem>
              </CNav>
            </CTabs>
            <br />
            <CRow>
              <CCol>
                <CButton
                  variant="outline"
                  color="primary"
                  block
                  onClick={() => {
                    getMyLocation({
                      lat: parseFloat(mapDataFinal.latlang.lat),
                      lang: parseFloat(mapDataFinal.latlang.lang),
                    });
                    setLoadingModalShowForDirectionOpenMap(true);
                  }}
                >
                  {LoadingModalShowForDirectionOpenMap
                    ? "Wait Loading..."
                    : "Get Location"}{" "}
                  {/* <ArrowForward /> */}
                </CButton>
                <br />
                {getlatforcurruntLocation !== "" ? (
                  <center>
                    {" "}
                    <a
                      href={
                        "https://www.google.com/maps/dir/" +
                        getlatforcurruntLocation +
                        "," +
                        getlangforcurruntLocation +
                        "/" +
                        parseFloat(data.latlang.lat) +
                        "," +
                        parseFloat(data.latlang.lang)
                      }
                      target="_BLANK"
                    >
                      Get Directon <ArrowForward />
                    </a>
                  </center>
                ) : (
                  ""
                )}
              </CCol>
            </CRow>
          </Popup>
        </Marker>
      )}
    </>
  );
}
