import { CButton, CCol, CRow } from "@coreui/react";
import swal from "sweetalert";

import React, { useEffect, useState } from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import { MapApi } from "src/Config/API";
import L from "leaflet";
import AddDevicesModalFromFiberLine from "./modals/addDevicesModalFromFiberLine";
import { ArrowForward } from "@material-ui/icons";
import FiberlineInfo from "./components/fiberlineInfo";

function ShowFiberLine({
  data,
  DeleteDataByID,
  MapKey,
  mapDataFinal,
  getMasteData,
  checkPolyLines,
  wdmStatus,
  setcheckPolyLinesContinue,
  setcheckPolyLines,
  map,
  CLossSpliceJoint,
  setPoliId,
}) {
  const [showDevices, setshowDevices] = useState(false);
  const onCloseDevices = () => {
    setshowDevices(!showDevices);
  };

  // function getDistance(PoliId) {
  //   var PolyData = [];
  //   MapApi.getMasterDataById(PoliId, MapKey)
  //     .then((response) => {
  //       console.log(response.data);
  //       if (response.data.data.latlang !== []) {
  //         console.log(response.data.data.latlang);
  //         var len = response.data.data.latlang.length,
  //           newData = { aaData: [] },
  //           i;

  //         for (i = 0; i < len; i += 1) {
  //           newData.aaData.push([
  //             response.data.data.latlang[i].lat,
  //             response.data.data.latlang[i].lang,
  //             {
  //               id: response.data.data.latlang[i].id,
  //               // color: PolyData.color,
  //             },
  //           ]);
  //         }

  //         if (map !== null) {
  //           console.log("data");
  //           var polyline = L.polyline(newData.aaData).addTo(map);
  //           var previousPoint;
  //           console.log(newData.aaData);
  //           var finalMeters = 0;
  //           //leafletjs.com/reference.html#polyline-getlatlngs
  //           var polygon = polyline.getLatLngs().forEach(function (latLng) {
  //             if (previousPoint) {
  //               finalMeters += previousPoint.distanceTo(latLng);
  //             }
  //             previousPoint = latLng;
  //           });
  //           var MeterDetails = {
  //             meters: finalMeters,
  //           };
  //           MapApi.UpdateMasterDataById(PoliId, MeterDetails, MapKey)
  //             .then((response) => {
  //               console.log(response.data);
  //             })
  //             .catch((error) => {
  //               console.log(error);
  //             });
  //           console.log(finalMeters);
  //         }
  //       }
  //       // Object.values(response.data.data.latlang).map((datata) => {
  //       //   console.log(datata);

  //       //   // PolyData.push(datata);
  //       // });
  //     })
  //     .catch((error) => { });
  // }

  function BackwordPolyLineById(id) {
    MapApi.BackWordPoliline(id, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);

        getDistance(id);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function ForwordPolyLineById(id) {
    MapApi.ForwordPoliline(id, MapKey)
      .then((response) => {
        console.log(response.data);
        getDistance(id);
        if (response.data.success === false) {
          swal({
            title: "Warning!",
            text: "You can not Forword Fiber Line",
            icon: "warning",
            button: "Okay!",
          });
        }

      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getDistance(PoliId) {
    var PolyData = [];
    MapApi.getMasterDataById(PoliId, MapKey)
      .then((response) => {
        console.log(response.data);
        if (response.data.data.latlang !== []) {
          console.log(response.data.data.latlang);
          var len = response.data.data.latlang.length,
            newData = { aaData: [] },
            i;

          for (i = 0; i < len; i += 1) {
            newData.aaData.push([
              response.data.data.latlang[i].lat,
              response.data.data.latlang[i].lang,
              {
                id: response.data.data.latlang[i].id,
                // color: PolyData.color,
              },
            ]);
          }

          if (map !== null) {
            console.log("data");
            var polyline = L.polyline(newData.aaData, { color: "#00FFFFFF", weight: 0 }).addTo(map);
            var previousPoint;
            console.log(newData.aaData);
            var finalMeters = 0;
            //leafletjs.com/reference.html#polyline-getlatlngs
            var polygon = polyline.getLatLngs().forEach(function (latLng) {
              if (previousPoint) {
                finalMeters += previousPoint.distanceTo(latLng);
                // L.marker(latLng)
                //   .bindPopup(
                //     "Distance from previous point: " +
                //       previousPoint.distanceTo(latLng).toFixed(2) + // http://leafletjs.com/reference.html#latlng-distanceto
                //   `    " meter(s)"
                //   )
                //   .addTo(map);
              }
              previousPoint = latLng;
            });
            var MeterDetails = {
              meters: finalMeters,
            };
            MapApi.UpdateMasterDataById(PoliId, MeterDetails, MapKey)
              .then((response) => {
                getMasteData();
                console.log(response.data);
              })
              .catch((error) => {
                console.log(error);
              });
            console.log(finalMeters);
          }
        }
        // Object.values(response.data.data.latlang).map((datata) => {
        //   console.log(datata);

        //   // PolyData.push(datata);
        // });
      })
      .catch((error) => { });
  }
  var len = data.latlang.length,
    newData = { aaData: [] },
    i;

  for (i = 0; i < len; i += 1) {
    newData.aaData.push([
      data.latlang[i].lat,
      data.latlang[i].lang,
      {
        id: data.latlang[i].id,
        color: data.color,
      },
    ]);
  }

  var LatLngData = [];
  data.latlang.map((data) => {
    LatLngData.push(data);
  });
  var newColor = data.color;

  const [first, setfirst] = useState(data.polylinedata.line_width);
  useEffect(() => {
    setfirst(data.polylinedata.line_width);
  }, [data]);

  return (
    <>
      <>
        <Tooltip sticky permanent>{(data.meters / 1000).toFixed(2) + "Km"}</Tooltip>
        {data.enable === true ? (
          <Popup closeOnEscapeKey minWidth={300} closeButton={false}>
            <FiberlineInfo
              data={data}
              DeleteDataByID={() => DeleteDataByID()}
              MapKey={MapKey}
              getMasteData={() => getMasteData()}
            />
          </Popup>
        ) : (
          <></>
        )}
      </>

      {checkPolyLines === true ? (
        <>
          {data.status === "Enable" ? (
            <Marker position={newData.aaData[newData.aaData.length - 1]}>
              <Popup closeOnEscapeKey minWidth={400} autoClose>
                <b>
                  <h4>Join your Fiber Line with</h4>
                </b>
                <AddDevicesModalFromFiberLine
                  MapKey={MapKey}
                  data={data}
                  getMasteData={() => getMasteData()}
                  map={map}
                  wdmStatus={wdmStatus}
                  mapDataFinal={mapDataFinal}
                  setcheckPolyLines={() => setcheckPolyLines()}
                  setcheckPolyLinesContinue={() => setcheckPolyLinesContinue()}
                  show={showDevices}
                  CLossSpliceJoint={CLossSpliceJoint}
                  onClose={() => onCloseDevices()}
                />
                <br />

                <CRow>
                  <CCol>
                    <CButton
                      block
                      color="danger"
                      onClick={() => BackwordPolyLineById(data.id)}
                    >
                      Back
                    </CButton>
                  </CCol>
                  <CCol>
                    <CButton
                      block
                      color="success"
                      onClick={() => ForwordPolyLineById(data.id)}
                    >
                      Forword
                    </CButton>
                  </CCol>
                </CRow>
                <br />
                <CRow>
                  <CCol>
                    <CButton
                      block
                      color="primary"
                      onClick={() => {
                        setcheckPolyLinesContinue();
                        setPoliId();
                      }}
                    >
                      Continue Fiber Line
                    </CButton>
                  </CCol>
                </CRow>
                <br />
              </Popup>
            </Marker>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div></div>
      )}

    </>
  );
}

export default ShowFiberLine;
