import React from 'react'

function TemrsAndConditions() {
    return (
        <div>  <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <br />
                    <br />
                    <h2>Terms & Conditions</h2>
                    <br />
                    <p>
                        Navya Techs (FTTH Network Design Software) - Terms and Conditions
                    </p>
                    <h4>1. Acceptance of Terms</h4>
                    <p>
                        By accessing and using the FTTH network design software (the "Navya Techs"), you agree to abide by these terms and conditions. If you do not agree with these terms, please do not use the Software.
                    </p>
                    <h4>2. License and Usage</h4>
                    <p>
                        a. The Software is licensed, not sold, to you for use in designing Fiber-to-the-Home (FTTH) networks.
                        <br />b. You may use the Software for your own internal business purposes only.
                        <br /> c. You may not sublicense, distribute, rent, lease, or sell the Software or any part of it to any third party. </p>

                    <h4>3. Intellectual Property</h4>

                    <p>
                        a. The Software and all associated content, including but not limited to text, graphics, designs, and documentation, are the property of Navya Techs.
                        <br /> b. You may not modify, reverse engineer, decompile, or disassemble the Software.
                    </p>

                    <h4>4. Data Privacy</h4>

                    <p>
                        a. The Software may collect and process user data in accordance with our <a href="https://navyatechs.com/privacy" target='_black'>Privacy Policy</a>.
                        <br />b. You agree to comply with all applicable data protection laws and regulations when using the Software.   </p>

                    <h4>5. Warranty and Support</h4>

                    <p>
                        a. The Software is provided "as is" without warranty of any kind, either express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
                        <br /> b. Navya Techs does not guarantee that the Software will be error-free, uninterrupted, or free from viruses.
                    </p>

                    <h4>6. Limitation of Liability</h4>

                    <p>
                        a. In no event shall Navya Techs be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with the use of the Software.
                        <br />b. Your use of the Software is at your own risk.
                    </p>

                    <h4>7. Termination</h4>
                    <p>a. Navya Techs reserves the right to terminate your access to the Software at any time without notice, if you breach these terms and conditions.
                        <br />b. Upon termination, you must cease all use of the Software and delete all copies.
                    </p>

                    <h4>8. Governing Law</h4>
                    <p>This Agreement shall be governed by and construed in accordance with the laws of Bhuj Jurisdiction.</p>
                    <h4>9. Refund Policy</h4>
                    <p>Refunds are not available.</p>

                    <h4>10. Cancellation Policy</h4>
                    <p>Only Top-Up Plan is available. you can not cancle the subscription.</p>

                    <h4>11. Changes to Terms</h4>

                    <p>
                        a. Navya Techs reserves the right to modify or update these terms and conditions at any time.
                        <br />  b. It is your responsibility to review the terms periodically for changes.  </p>

                </div>
            </div>
        </div></div>
    )
}

export default TemrsAndConditions