import React, {
  useState,
} from "react";

import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CRow,
  CTooltip,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import CalculateLoss from "src/views/CalculateLoss/calculateloss";
import { LossAPI } from "src/Config/API";
const TheHeaderDropdownSetting = () => {
  const [ItemName, setItemName] = useState("");
  const [ShowModal, setShowModal] = useState(false);
  const [LossData, setLossData] = useState([]);
  const [LossCurrentId, setLossCurrentId] = useState(0);
  const [Loading, setLoading] = useState(false);
  const handleClick = (iname, id) => {
    setItemName(iname);
    setShowModal(true);
    setLossCurrentId(id)
    getData(id);
  };

  const getData = (cat_id) => {
    setLoading(true);
    LossAPI.getLossData(cat_id)
      .then(function (response) {
        setLossData(response.data);
        setLoading(false);
        console.log(response);
      })
      .catch(function (err) {
        setLoading(false);
        console.log("err");
      });
  };

  return (
    <div>
      <CRow>

        <CDropdown inNav className="c-header-nav-item mx-2">
          <CDropdownToggle className="c-header-nav-link" caret={false}>
            {/* <b> Calculation Loss Settings</b>  */}
            <CTooltip content={"Calculation Loss Settings"}>

              <CIcon name="cil-settings" className="iconHVR" style={{ width: 18, height: 18 }} />
            </CTooltip>&nbsp;
          </CDropdownToggle>
          <CDropdownMenu placement="bottom-end" className="pt-0">
            <CDropdownItem
              header
              tag="div"
              className="text-center"
              color="light"
            >
              <strong>Loss Setting</strong>
            </CDropdownItem>
            <div
              className="small mb-3"
              style={{ color: "gray", marginLeft: 20, marginTop: 20 }}
            >
              <strong>Calculate Loss</strong>
            </div>
            <CDropdownItem
              className="d-block"
              onClick={() => handleClick("fiberloss", 1)}
            >
              <div className=" mb-1">
                <strong>Optical Fiber Loss </strong>
              </div>
            </CDropdownItem>
            <CDropdownItem
              className="d-block"
              onClick={() => handleClick("couplerlosswith", 2)}
            >
              <div className="  mb-1">
                <strong> Optical Coupler Loss (With Connector)</strong>
              </div>
            </CDropdownItem>
            <CDropdownItem
              className="d-block"
              onClick={() => handleClick("couplerlosswithout", 3)}
            >
              <div className="  mb-1">
                <strong>Optical Coupler Loss (Without Connector)</strong>
              </div>
            </CDropdownItem>
            <CDropdownItem
              className="d-block"
              onClick={() => handleClick("splicesloss", 4)}
            >
              <div className="  mb-1">
                <strong>Splices Loss</strong>
              </div>
            </CDropdownItem>

            <CDropdownItem
              className="d-block"
              onClick={() => handleClick("adapterloss", 5)}
            >
              <div className="  mb-1">
                <strong>Adapter Loss</strong>
              </div>
            </CDropdownItem>
            <CDropdownItem
              className="d-block"
              onClick={() => handleClick("patchcoard", 6)}
            >
              <div className="  mb-1">
                <strong>Patch Coard</strong>
              </div>
            </CDropdownItem>
            <CDropdownItem
              className="d-block"
              onClick={() => handleClick("PLCsplitterwith", 7)}
            >
              <div className="  mb-1">
                <strong>PLC Splitter (With Connector)</strong>
              </div>
            </CDropdownItem>
            <CDropdownItem
              className="d-block"
              onClick={() => handleClick("PLCsplitterwithout", 8)}
            >
              <div className="  mb-1">
                <strong>PLC Splitter (Without Connector)</strong>
              </div>
            </CDropdownItem>
            <CDropdownItem className="text-center border-top">
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </CRow>
      <CalculateLoss
        show={ShowModal}
        onHide={() => setShowModal(false)}
        forOpen={ItemName}
        lossdata={LossData}
        loading={Loading}
        onresetData={() => getData(LossCurrentId)}
      />
    </div>
  );
};

export default TheHeaderDropdownSetting;
