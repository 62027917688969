import {
  CButton,
  CCol,
  CDropdown,
  CInput,
  CRow,
  CTooltip,
  CTabs,
  CTabContent,
  CTabPane,
  CNav,
  CNavItem,
  CNavLink,
} from "@coreui/react";
import { DeleteOutline } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { MapApi } from "src/Config/API";
import swal from "sweetalert";

const FiberlineInfo = ({ data, getMasteData, MapKey, DeleteDataByID }) => {
  const [OFCompany, setOFCompany] = useState("");
  const [FCSelect, setFCSelect] = useState("");
  const [CType, setCType] = useState("");
  const [noTube, setnoTube] = useState("");
  const [stColor, setstColor] = useState("");
  const [SelectedTubeColorArr, setSelectedTubeColorArr] = useState([]);
  const [PolilineWidthSelected, setPolilineWidthSelected] = useState(0);

  useEffect(() => {
    setPolilineWidthSelected(data.polylinedata.line_width);
    setOFCompany(data.polylinedata.optical_fiber_company);
    setFCSelect(data.polylinedata.fiber_core);
    setCType(data.polylinedata.cable_type);
    setnoTube(data.polylinedata.number_of_tube);
    setstColor(data.polylinedata.core_color);
    setSelectedTubeColorArr(data.tube_data);
  }, [data]);
  const PolyColors = [
    {
      color: "#0066AA",
      name: "Blue",
      number: "1",
      fontcolor: "#ffffff",
    },
    {
      color: "#F57921",
      name: "Orange",
      number: "2",
      fontcolor: "#000000",
    },
    {
      color: "#71BE47",
      name: "Green",
      number: "3",
      fontcolor: "#000000",
    },
    {
      color: "#784E28",
      name: "Brown",
      number: "4",
      fontcolor: "#ffffff",
    },
    {
      color: "#BCBDBF",
      name: "Slate",
      number: "5",
      fontcolor: "#000000",
    },
    {
      color: "#FFFFFF",
      name: "White",
      number: "6",
      fontcolor: "#000000",
    },
    {
      color: "#D71920",
      name: "Red",
      number: "7",
      fontcolor: "#ffffff",
    },
    {
      color: "#241E20",
      name: "Black",
      number: "8",
      fontcolor: "#ffffff",
    },
    {
      color: "#EAC900",
      name: "Yellow",
      number: "9",
      fontcolor: "#000000",
    },
    {
      color: "#762284",
      name: "Violet",
      number: "10",
      fontcolor: "#ffffff",
    },
    {
      color: "#F599C2",
      name: "Pink(Rose)",
      number: "11",
      fontcolor: "#000000",
    },
    {
      color: "#02A89D",
      name: "Aqua",
      number: "12",
      fontcolor: "#000000",
    },
  ];
  const PolyWith = [
    {
      width: "2",
    },
    {
      width: "4",
    },
    {
      width: "6",
    },
    {
      width: "8",
    },
    {
      width: "10",
    },
    {
      width: "12",
    },
  ];
  function EditPolyLineColorById(id, InputValue) {
    var data = {
      color: InputValue,
    };
    MapApi.UpdateMasterDataById(id, data, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddPolyLineTubeColorById(id, InputValue) {
    var data = {
      polyline_id: id,
      color: InputValue,
    };
    MapApi.addFiberTubeColorByFID(data, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function DeletePolyLineTubeColorById(id) {
    MapApi.DeleteFiberTubeColorByFID(id, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function EditPolyLineWithById(id, InputValue) {
    var data = {
      data: { line_width: InputValue },
    };
    MapApi.UpdateMasterDataById(id, data, MapKey)
      .then((rsponse) => {
        // console.log(rsponse.data);
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function EditPolyLineById(id, OFCompany, FCSelect, CType, noTube) {
    var data = {
      data: {
        optical_fiber_company: OFCompany,
        fiber_core: FCSelect,
        cable_type: CType,
        number_of_tube: noTube,
      },
    };
    MapApi.UpdateMasterDataById(id, data, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
        swal({
          title: "Ops!",
          text: error.response.data.error,
          icon: "warning",
          button: "Okay!",
        });
      });
  }
  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          color: "black",
          borderRadius: 5,
          marginTop: 5,
          fontWeight: "bold",
          marginBottom: 5,
        }}
      >
        <center>
          <h4>{data.name}</h4>
        </center>
      </div>
      <CTabs activeTab="page_1">
        <CTabContent>
          <CTabPane data-tab="page_1">
            <br />
            <center>
              <h5> Choose Line width For Fiber Line</h5>
            </center>
            <CRow className={"justify-content-center"}>
              <div class="slider">
                <input type="range" min="2" value={PolilineWidthSelected} max="12" id="fader" step="2" list="volsettings" onChange={(value) => {
                  console.log(value.target.value);
                  EditPolyLineWithById(data.id, value.target.value)
                }} /><datalist id="volsettings"><option>2</option><option>4</option><option>6</option><option>8</option><option>10</option><option>12</option></datalist> <label for="fader">{PolilineWidthSelected}
                </label></div>
              {/* {PolyWith.map((Cdata) => (
                <div
                  style={{
                    backgroundColor:
                      Cdata.width === data.polylinedata.line_width
                        ? "#0066AA"
                        : "#ffffff",
                    width: 45,
                    height: 20,
                    borderRadius: 15,
                    color:
                      Cdata.width === data.polylinedata.line_width
                        ? "#ffffff"
                        : "#000000",
                    // marginBottom: 5,
                    // marginRight: 2,
                    margin: 2,
                    cursor: "pointer",
                    border: "1px solid rgba(10, 9, 9)",
                  }}
                  onClick={() => EditPolyLineWithById(data.id, Cdata.width)}
                >
                  <center>
                    <h5>{Cdata.width}</h5>
                  </center>
                </div>
              ))} */}
            </CRow>
            <br />
            <center>
              {" "}
              <h5> Choose Color For Fiber Line</h5>
            </center>
            <CRow className={"justify-content-center"}>
              {PolyColors.map((Cdata) => (
                <CTooltip content={Cdata.name} placement="top">
                  <div
                    style={{
                      backgroundColor: Cdata.color,
                      width: 45,
                      height: 45,
                      borderRadius: 5,
                      // marginBottom: 5,
                      // marginRight: 2,
                      color: Cdata.fontcolor,
                      margin: 2,
                      cursor: "pointer",
                      border: "1px solid rgba(10, 9, 9)",
                    }}
                    onClick={() => EditPolyLineColorById(data.id, Cdata.color)}
                  >
                    <center>
                      <h5>{Cdata.number}</h5>{" "}
                    </center>
                  </div>
                </CTooltip>
              ))}
            </CRow>
            <CCol>
              <br />
              <CRow>
                <CButton
                  onClick={() => DeleteDataByID(data.id)}
                  color="danger"
                  block
                >
                  Delete Fiber Line
                </CButton>
              </CRow>
            </CCol>
          </CTabPane>
          <CTabPane data-tab="page_2">
            <table class="table table-bordered table-sm">
              <tbody>
                <tr>
                  <td>Optical Fiber Company</td>
                  <td>
                    <CInput
                      value={OFCompany}
                      onChange={(e) => setOFCompany(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Fiber Core Select</td>
                  <td>
                    <CDropdown className="mt-0 w-`100`">
                      <select
                        caret
                        color="secondary"
                        className="w-100 h-20 custom-select-lg dropdowns"
                        // name="patientTypeRegister"
                        value={FCSelect}
                        onChange={(e) => setFCSelect(e.target.value)}
                      >
                        <option value="1F">1F</option>
                        <option value="2F">2F</option>
                        <option value="4F">4F</option>
                        <option value="8F">8F</option>
                        <option value="12F">12F</option>
                        <option value="24F">24F</option>
                        <option value="48F">48F</option>
                        <option value="96F">96F</option>
                      </select>
                    </CDropdown>
                  </td>
                </tr>
                <tr>
                  <td>Core Type</td>
                  <td>
                    <CDropdown className="mt-0 w-`100`">
                      <select
                        caret
                        color="secondary"
                        className="w-100 h-20 custom-select-lg dropdowns"
                        // name="patientTypeRegister"
                        value={CType}
                        onChange={(e) => setCType(e.target.value)}
                      >
                        <option value="FTTH" selected>
                          FTTH
                        </option>
                        <option value="UNARMERD_2GI">UNARMERD 2GI</option>
                        <option value="UNARMERD_2_FRP">UNARMERD 2 FRP</option>
                        <option value="NARMERD_2_FRP_Yarn">
                          NARMERD 2 FRP Yarn
                        </option>
                        <option value="AURMERED">AURMERED</option>
                        <option value="AURMERED_MULTITUBE">
                          AURMERED MULTITUBE
                        </option>
                      </select>
                    </CDropdown>
                  </td>
                </tr>
                <tr>
                  <td>Number of Buffer Tube</td>
                  <td>
                    <CDropdown className="mt-0 w-`100`">
                      <select
                        caret
                        color="secondary"
                        className="w-100 h-20 custom-select-lg dropdowns"
                        // name="patientTypeRegister"
                        value={noTube}
                        onChange={(e) => setnoTube(e.target.value)}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>

                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                    </CDropdown>
                  </td>
                </tr>
                <tr>
                  <td>Select Buffer Tube Color</td>
                  <td>
                    <CDropdown className="mt-0 w-`100`">
                      <select
                        caret
                        color="secondary"
                        className="w-100 h-20 custom-select-lg dropdowns"
                        // name="patientTypeRegister"
                        value={stColor}
                        onChange={(e) => setstColor(e.target.value)}
                      >
                        {PolyColors.map((Cdata) => (
                          <option
                            value={Cdata.color}
                            style={{
                              backgroundColor: Cdata.color,
                              color: Cdata.fontcolor,
                            }}
                            onClick={() => {
                              console.log("addline")
                              if (noTube > SelectedTubeColorArr.length) {
                                AddPolyLineTubeColorById(
                                  data.id,
                                  Cdata.color,
                                );
                                SelectedTubeColorArr.push({ "polyline_id": data.id, "color": Cdata.color });
                                setSelectedTubeColorArr([...SelectedTubeColorArr]);
                              } else {
                                swal({
                                  title: "Ops!",
                                  text:
                                    "You can not select more Tube color\n you select " +
                                    noTube,
                                  icon: "warning",
                                  button: "Okay!",
                                });
                              }
                            }}
                          >
                            {Cdata.name}
                          </option>
                        ))}
                      </select>
                    </CDropdown>
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>Tube Color</h5>
            <CRow className={"justify-content-center"}>
              {SelectedTubeColorArr.map((Cdata, index) => {
                if (SelectedTubeColorArr.length !== 0)
                  return (
                    <>
                      <div
                        style={{
                          backgroundColor: Cdata.color,
                          width: 45,
                          height: 45,
                          borderRadius: 5,

                          margin: 2,
                          cursor: "pointer",
                          border: "1px solid rgba(10, 9, 9)",
                        }}
                      >
                        <center>
                          <DeleteOutline
                            onClick={() =>
                              DeletePolyLineTubeColorById(Cdata.id)
                            }
                            style={{ color: "#ff0000" }}
                          />
                        </center>
                      </div>
                    </>
                  );
              })}
            </CRow>
          </CTabPane>
          <br />
          <CRow className={"justify-content-center"}>
            <CCol>
              <CButton
                color="info"
                block
                onClick={() =>
                  EditPolyLineById(data.id, OFCompany, FCSelect, CType, noTube)
                }
              >
                Save
              </CButton>
            </CCol>
            <CCol>
              <CButton
                disabled
                color="warning"
                block
                style={{ color: "black", borderRadius: 15 }}
              >
                {(data.meters / 1000).toFixed(2) + "Km"}
              </CButton>
            </CCol>
          </CRow>
          <br />
        </CTabContent>
        <CNav variant="tabs" justified="center">
          <CNavItem>
            <CNavLink data-tab="page_1" style={{ fontFamily: "sans-serif" }}>
              Page 1{" "}
            </CNavLink>
          </CNavItem>

          <CNavItem>
            <CNavLink data-tab="page_2" style={{ fontFamily: "sans-serif" }}>
              Page 2
            </CNavLink>
          </CNavItem>
        </CNav>
      </CTabs>
    </>
  );
};

export default FiberlineInfo;
