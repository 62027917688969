import { CButton } from '@coreui/react';
import React, { useEffect, useState } from 'react'
import { MapContainer, Polyline, Popup, TileLayer } from 'react-leaflet'
import { MapApi } from 'src/Config/API';

function Testmap() {
    const [map, setMap] = useState(null);
    const [Lines, setLines] = useState([]);
    const [mapdata, setmapdata] = useState({
        "success": true,
        "data": {
            "id": 1,
            "type": "master",
            "name": "master",
            "total_loss": "9.00",
            "icon_type": null,
            "icon_name": null,
            "images": null,
            "core_swap": null,
            "meters": "10.00",
            "color": null,
            "loss_id": null,
            "min_id": null,
            "max_id": null,
            "point_id": null,
            "master_id": null,
            "latlang_id": 1,
            "port_id": 1,
            "created_at": "2023-07-20T19:29:16.000000Z",
            "updated_at": "2023-07-20T19:29:16.000000Z",
            "deleted_at": null,
            "polylines": [
                {
                    "id": 4,
                    "type": "polyline",
                    "name": "master",
                    "total_loss": "8.92",
                    "meters": "161.87570414549",
                    "color": "#02A89D",
                    "point_id": 1,
                    "status": "Enable",
                    "master_id": 1,
                    "latlang": [
                        {
                            "id": 12,
                            "lat": "23.232297311267",
                            "lang": "69.643846259653",
                            "polyline_id": 4,
                            "status": 1,
                            "created_at": "2023-07-22T15:27:24.000000Z",
                            "updated_at": "2023-07-22T15:27:24.000000Z",
                            "deleted_at": null
                        },
                        {
                            "id": 13,
                            "lat": "23.232016387536",
                            "lang": "69.644039869308",
                            "polyline_id": 4,
                            "status": 1,
                            "created_at": "2023-07-22T15:27:29.000000Z",
                            "updated_at": "2023-07-23T05:42:20.000000Z",
                            "deleted_at": null
                        },
                        {
                            "id": 14,
                            "lat": "23.232265256159",
                            "lang": "69.644499868155",
                            "polyline_id": 4,
                            "status": 1,
                            "created_at": "2023-07-22T15:27:32.000000Z",
                            "updated_at": "2023-07-23T05:48:21.000000Z",
                            "deleted_at": null
                        },
                        {
                            "id": 76,
                            "lat": "23.232589297066",
                            "lang": "69.645091295242",
                            "polyline_id": 4,
                            "status": 1,
                            "created_at": "2023-07-23T05:48:38.000000Z",
                            "updated_at": "2023-07-23T05:48:38.000000Z",
                            "deleted_at": null
                        }
                    ],
                    "enable": true,
                    "polylinedata": {
                        "id": 2,
                        "optical_fiber_company": null,
                        "fiber_core": "1F",
                        "cable_type": "FTTH",
                        "number_of_tube": "1",
                        "core_color": null,
                        "line_width": 5,
                        "transmitter_id": 4,
                        "created_at": null,
                        "updated_at": null,
                        "deleted_at": null
                    },
                    "tube_data": [

                    ]
                }
            ]
        }
    });
    useEffect(() => {
        // MapApi.getMapFiberLineAllData(match.params.portnumber, MapKey)
        //     .then((response) => {
        //         setMapFiberLineData(response.data.data);
        //         console.log(response.data.data);
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
    }, [])

    const filltheData = () => {
        setmapdata({
            "success": true,
            "data": {
                "id": 1,
                "type": "master",
                "name": "master",
                "total_loss": "9.00",
                "icon_type": null,
                "icon_name": null,
                "images": null,
                "core_swap": null,
                "meters": "10.00",
                "color": null,
                "loss_id": null,
                "min_id": null,
                "max_id": null,
                "point_id": null,
                "master_id": null,
                "latlang_id": 1,
                "port_id": 1,
                "created_at": "2023-07-20T19:29:16.000000Z",
                "updated_at": "2023-07-20T19:29:16.000000Z",
                "deleted_at": null,
                "polylines": [
                    {
                        "id": 4,
                        "type": "polyline",
                        "name": "master",
                        "total_loss": "8.92",
                        "meters": "161.87570414549",
                        "color": "#02A89D",
                        "point_id": 1,
                        "status": "Enable",
                        "master_id": 1,
                        "latlang": [
                            {
                                "id": 12,
                                "lat": "23.232297311267",
                                "lang": "69.643846259653",
                                "polyline_id": 4,
                                "status": 1,
                                "created_at": "2023-07-22T15:27:24.000000Z",
                                "updated_at": "2023-07-22T15:27:24.000000Z",
                                "deleted_at": null
                            },
                            {
                                "id": 13,
                                "lat": "23.232016387536",
                                "lang": "69.644039869308",
                                "polyline_id": 4,
                                "status": 1,
                                "created_at": "2023-07-22T15:27:29.000000Z",
                                "updated_at": "2023-07-23T05:42:20.000000Z",
                                "deleted_at": null
                            },
                            {
                                "id": 14,
                                "lat": "23.232265256159",
                                "lang": "69.644499868155",
                                "polyline_id": 4,
                                "status": 1,
                                "created_at": "2023-07-22T15:27:32.000000Z",
                                "updated_at": "2023-07-23T05:48:21.000000Z",
                                "deleted_at": null
                            },
                            {
                                "id": 76,
                                "lat": "23.232589297066",
                                "lang": "69.645091295242",
                                "polyline_id": 4,
                                "status": 1,
                                "created_at": "2023-07-23T05:48:38.000000Z",
                                "updated_at": "2023-07-23T05:48:38.000000Z",
                                "deleted_at": null
                            }
                        ],
                        "enable": true,
                        "polylinedata": {
                            "id": 2,
                            "optical_fiber_company": null,
                            "fiber_core": "1F",
                            "cable_type": "FTTH",
                            "number_of_tube": "1",
                            "core_color": null,
                            "line_width": 5,
                            "transmitter_id": 4,
                            "created_at": null,
                            "updated_at": null,
                            "deleted_at": null
                        },
                        "tube_data": [

                        ]
                    }
                ]
            }
        })
    }
    const removeLineLast = () => {
        mapdata.data.polylines[0].latlang.pop();
        console.log(mapdata.data);
        setmapdata({ ...mapdata });
        // setLines(...Lines);
    }


    return (
        <div> <MapContainer
            ref={setMap}
            center={[
                23.232297311267,
                69.643846259653,
            ]}
            dragging={true}
            doubleClickZoom={true}
            scrollWheelZoom={true}
            attributionControl={true}
            //   zoomControl={false}
            // onPopupClose={handlePopupClose}
            zoom={13}
        // onClick={(e) => handleClick(e)}
        >
            <TileLayer
                url={`https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}`}
                maxZoom={20}
                subdomains={["mt1", "mt2", "mt3"]}
            />
            {mapdata.data.polylines.map((datap) => {
                var len = datap.latlang.length,
                    newData = { aaData: [] },
                    i;

                for (i = 0; i < len; i += 1) {
                    newData.aaData.push([
                        datap.latlang[i].lat,
                        datap.latlang[i].lang,
                        {
                            id: datap.latlang[i].id,
                            color: datap.color,
                        },
                    ]);
                }
                var newColor = datap.color;

                return <Polyline
                    // ref={polylineRef}
                    positions={newData.aaData}
                    pathOptions={{ color: newColor }}
                    weight={10}
                >
                    <Popup closeOnEscapeKey minWidth={300} closeButton={false}>
                        <CButton
                            onClick={() => removeLineLast()}
                            color="danger"
                            block
                        >
                            Delete Fiber Line
                        </CButton>

                    </Popup>

                </Polyline>
            })}


        </MapContainer>
            <button onClick={filltheData}> fill</button>
        </div>
    )
}

export default Testmap