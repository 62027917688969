import { CLabel } from "@coreui/react";
import { useCallback } from "react";
import { Handle, Position } from "reactflow";

const handleStyle = { left: 10 };

function WDMPNode({ data, isConnectable }) {
  return (
    <div>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <div
        style={{
          width: 70,
          borderRadius: 30,
          alignContent: "center",
        }}
        className="ornode"
      >
        <center>
          <CLabel style={{ fontSize: 10 }}>
            WDM <br />
            Passive
          </CLabel>
        </center>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{
            
          borderStyle: 2,
          borderColor: "white",
          background: "black",
        }}
      />
    </div>
  );
}

export default WDMPNode;
