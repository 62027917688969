import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CInput,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CTabs,
  CTooltip,
} from "@coreui/react";
import {
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  Delete,
  Satellite,
} from "@material-ui/icons";
import L from "leaflet";
import { useEffect, useRef, useState } from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import { MapApi } from "src/Config/API";
import swal from "sweetalert";

export function CustomeCouplerShow({
  data,
  viewas,
  map,
  mapDataFinal,
  getMasteData,
  MapKey,
  mapRef,
  DeleteDataByID,
  handleImageView,
  setCouplorIDForSaveLossData,
  sethandleLossModal,
  PostPolilineMax,
  PostPolilineMin,
  PostPolilineMaxContinue,
  PostPolilineMinContinue,
  forftth,
}) {
  const [InputData, setInputData] = useState("");
  const [InputImage, setInputImage] = useState(null);
  const [UnablePower, setUnablePower] = useState(false);

  useEffect(() => {
    setInputData(data.name);
  }, [data]);
  function EditCouplrById(id, InputValue, InputImage) {
    const form = new FormData();
    form.append("name", InputValue);
    if (InputImage !== null) {
      form.append("images", InputImage, InputImage.name);
    }

    MapApi.UpdateMasterDataById(id, form, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        swal("Saved!", "Information saved successfully!", "success");
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const [getlatforcurruntLocation, setgetlatforcurruntLocation] = useState("");
  const [getlangforcurruntLocation, setgetlangforcurruntLocation] =
    useState("");
  const [
    LoadingModalShowForDirectionOpenMap,
    setLoadingModalShowForDirectionOpenMap,
  ] = useState(false);
  const getMyLocation = ({ lat, lang }) => {
    const location = window.navigator && window.navigator.geolocation;
    var options = {
      enableHighAccuracy: true,
    };
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLoadingModalShowForDirectionOpenMap(false);
          setgetlatforcurruntLocation(position.coords.latitude);
          setgetlangforcurruntLocation(position.coords.longitude);

          console.log(
            "https://www.google.com/maps/dir/" +
              position.coords.latitude +
              "," +
              position.coords.longitude +
              "/" +
              lat +
              "," +
              lang
          );
        },
        (error) => {
          console.log(error);
        },
        options
      );
    }
  };
  function EditCouplrRotationIconById(id, InputValue) {
    var data = {
      icon_type: InputValue,
    };
    MapApi.UpdateMasterDataById(id, data, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const popupElRef = useRef(null);
  const hideElement = () => {};
  return (
    <>
      {forftth ? (
        <>
          <CCard>
            <CCardHeader>
              <center>
                <h5>Coupler</h5>
                {data.loss !== null ? (
                  <div
                    style={
                      {
                        // backgroundColor: "#bfd8ff",
                        // padding: 10,
                        // borderRadius: 5,
                        // margin: 5,
                      }
                    }
                  >
                    {data.loss.high_name ?? ""}
                    {" X "}
                    {data.loss.low_name ?? ""}
                  </div>
                ) : (
                  <></>
                )}
              </center>
            </CCardHeader>
            <CCardBody>
              <CTabs activeTab="loss">
                <CTabContent>
                  <CTabPane data-tab="loss">
                    <CRow style={{ marginBottom: 10 }}>
                      <CCol>
                        <CButton
                          onClick={() => {
                            setCouplorIDForSaveLossData();
                            sethandleLossModal();
                          }}
                          color="info"
                          variant="outline"
                          block
                        >
                          Select Couplor <ArrowForward />
                        </CButton>
                      </CCol>
                    </CRow>
                    {data.loss !== null ? (
                      <center>{data.loss_type ?? ""}</center>
                    ) : (
                      <div></div>
                    )}
                    <div
                      style={{
                        backgroundColor: "#bfd8ff",
                        padding: 10,
                        fontWeight: "bold",
                        borderRadius: 5,
                        marginLeft: 1,
                        marginRight: 1,
                        marginTop: 10,
                      }}
                    >
                      <center>
                        {" "}
                        INPUT OPTICAL POWER :{" "}
                        <CRow>
                          {mapDataFinal.port.wdm_status === 1 ? (
                            <CCol>
                              <div
                                style={{
                                  backgroundColor: "#fff",
                                  padding: 1,
                                  fontWeight: "bold",
                                  borderRadius: 5,
                                  fontSize: 14,
                                  margin: 1,
                                }}
                              >
                                1490 NM : &nbsp;{" "}
                                {data.loss_1490NM !== null
                                  ? data.loss_1490NM + " DB"
                                  : ""}
                              </div>{" "}
                            </CCol>
                          ) : (
                            <></>
                          )}
                        </CRow>
                        {data.loss_1550NM !== null ? (
                          <CCol>
                            <div
                              style={{
                                backgroundColor: "#fff",
                                padding: 1,
                                fontWeight: "bold",
                                borderRadius: 5,
                                margin: 1,
                              }}
                            >
                              &nbsp;1550 NM :{" "}
                              {data.loss_1550NM !== null
                                ? data.loss_1550NM + " DB"
                                : ""}
                            </div>
                          </CCol>
                        ) : (
                          <></>
                        )}{" "}
                        <CRow></CRow>
                      </center>{" "}
                    </div>

                    <CRow style={{ marginTop: 10 }}>
                      <CCol
                        md="3"
                        style={{
                          backgroundColor: "#bfd8ff",
                          padding: 10,
                          borderRadius: 5,
                          margin: 5,
                        }}
                      >
                        <center>Type</center>
                      </CCol>
                      <CCol
                        style={{
                          backgroundColor: "#bfd8ff",
                          padding: 10,
                          borderRadius: 5,
                          margin: 5,
                        }}
                      >
                        <center>
                          {" "}
                          {data.loss !== null ? (
                            data.loss.high_name
                          ) : (
                            <div>Max</div>
                          )}{" "}
                        </center>
                      </CCol>
                      <CCol
                        style={{
                          backgroundColor: "#bfd8ff",
                          padding: 10,
                          borderRadius: 5,
                          margin: 2,
                        }}
                      >
                        <center>
                          {" "}
                          {data.loss !== null ? (
                            data.loss.low_name
                          ) : (
                            <div>Min</div>
                          )}{" "}
                        </center>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="3">
                        {MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                          ""
                        ) : data.max !== null ? (
                          data.max.loss_1550NM !== null ? (
                            "CATV"
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </CCol>
                      <CCol>
                        <center>OP Power</center>
                      </CCol>
                      <CCol>
                        <center>OP Power</center>
                      </CCol>
                    </CRow>
                    {MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                      <></> 
                    ) :data.max !== null ? (
                      data.max.loss_1550NM !== null ? (
                        <CRow>
                          <CCol
                            md="3"
                            style={{
                              backgroundColor: "#bfd8ff",
                              padding: 10,
                              borderRadius: 5,
                              margin: 5,
                            }}
                          >
                            <div
                              style={{
                                marginTop: 6,
                                fontWeight: "bold",
                              }}
                            >
                              <center>1550NM</center>
                            </div>
                          </CCol>
                          <CCol
                            style={{
                              backgroundColor: "#bfd8ff",
                              padding: 10,
                              borderRadius: 5,
                              margin: 5,
                            }}
                          >
                            <div class="input-box-DB">
                              <CInput
                                style={{ width: "100%" }}
                                // mask="99 . 99"
                                // readOnly
                                // value={"111.111"}
                                value={
                                  data.max !== null ? data.max.loss_1550NM : 0
                                }
                              />
                              <span class="prefix">DB</span>
                            </div>
                          </CCol>
                          <CCol
                            style={{
                              backgroundColor: "#bfd8ff",
                              padding: 10,
                              borderRadius: 5,
                              margin: 5,
                            }}
                          >
                            <div class="input-box-DB">
                              <CInput
                                style={{ width: "100%" }}
                                // readOnly
                                value={
                                  data.min !== null ? data.min.loss_1550NM : 0
                                }
                              />
                              <span class="prefix">DB</span>
                            </div>
                          </CCol>
                        </CRow>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                    {mapDataFinal.port.wdm_status === 1 ? (
                      <div>
                        INTERNET
                        <CRow>
                          <CCol
                            md="3"
                            style={{
                              backgroundColor: "#bfd8ff",
                              padding: 10,
                              borderRadius: 5,
                              margin: 2,
                              fontSize: 12,
                            }}
                          >
                            {mapDataFinal.port.wdm_status === 1 ? (
                              <>
                                <div
                                  style={{
                                    marginTop: 6,
                                    fontWeight: "bold",
                                  }}
                                >
                                  <center> 1490 NM</center>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </CCol>
                          <CCol
                            style={{
                              backgroundColor: "#bfd8ff",
                              padding: 10,
                              borderRadius: 5,

                              margin: 5,
                            }}
                          >
                            {mapDataFinal.port.wdm_status === 1 ? (
                              <>
                                <div class="input-box-DB">
                                  <CInput
                                    style={{ width: "100%", padding: 0 }}
                                    // mask="99 . 99"
                                    // readOnly
                                    value={
                                      data.max !== null
                                        ? data.max.loss_1490NM
                                        : 0
                                    }
                                  />
                                  <span class="prefix">DB</span>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </CCol>
                          <CCol
                            style={{
                              backgroundColor: "#bfd8ff",
                              padding: 10,
                              borderRadius: 5,
                              margin: 5,
                            }}
                          >
                            {mapDataFinal.port.wdm_status === 1 ? (
                              <>
                                <div class="input-box-DB">
                                  <CInput
                                    style={{ width: "100%", padding: 0 }}
                                    // readOnly
                                    value={
                                      data.min !== null
                                        ? data.min.loss_1490NM
                                        : 0
                                    }
                                  />
                                  <span class="prefix">DB</span>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            {/* <`br /> */}
                          </CCol>
                        </CRow>
                      </div>
                    ) : (
                      <></>
                    )}

                    <br />
                  </CTabPane>
                  <CTabPane data-tab="other_info">
                    <CInput
                      onChange={(v) => setInputData(v.target.value)}
                      value={InputData}
                    ></CInput>
                    <CRow>
                      <CCol>
                        <p
                          style={{
                            color: "black",
                            fontsize: 20,
                            fontWeight: "bold",
                          }}
                        >
                          Upload Image{" "}
                          {data.images !== null ? (
                            // <a
                            //   href={data.images}
                            //   target="blank"
                            // >
                            <Satellite onClick={() => handleImageView()} />
                          ) : (
                            // </a>
                            <></>
                          )}
                        </p>
                      </CCol>
                    </CRow>
                    <CInput
                      type="file"
                      placeholder=""
                      onChange={(v) => setInputImage(v.target.files[0])}
                      // value={InputImage}
                    ></CInput>
                    <br />
                    <CRow>
                      <CCol>
                        <CButton
                          onClick={() =>
                            EditCouplrById(data.id, InputData, InputImage)
                          }
                          color="primary"
                          block
                          // variant="outline"
                        >
                          {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                          Save{" "}
                        </CButton>
                      </CCol>
                      <CCol>
                        <CButton
                          onClick={() => DeleteDataByID()}
                          color="secondary"
                          block
                          variant="outline"
                        >
                          <Delete color="#ff0000" style={{ color: "red" }} />
                        </CButton>
                      </CCol>
                    </CRow>

                    <br />
                  </CTabPane>
                </CTabContent>
                <CNav variant="tabs" justified="center">
                  <CNavItem>
                    <CNavLink
                      data-tab="loss"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Loss{" "}
                    </CNavLink>
                  </CNavItem>

                  <CNavItem>
                    <CNavLink
                      data-tab="other_info"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Other Info
                    </CNavLink>
                  </CNavItem>
                </CNav>
              </CTabs>
              {data.images !== null ? (
                <CButton
                  color="primary"
                  block
                  style={{ marginTop: 10 }}
                  onClick={() => handleImageView()}
                >
                  Show Image &nbsp;
                  <Satellite
                    onClick={
                      () => handleImageView()
                      // handleImageView(
                      //   data.images
                      // )
                    }
                  />
                </CButton>
              ) : (
                <></>
              )}
            </CCardBody>
          </CCard>
        </>
      ) : (
        <>
          <Marker
            position={[
              parseFloat(data.latlang.lat),
              parseFloat(data.latlang.lang),
            ]}
            icon={
              data.icon_type === "row"
                ? L.icon({
                    iconUrl:
                      data.min_id !== null
                        ? MapApi.getCouplerIcon(
                            data.loss.low_name,
                            data.loss.high_name,
                            "row"
                          )
                        : MapApi.getCouplerIcon(0, 0, "row"),
                    iconRetinaUrl:
                      data.min_id !== null
                        ? MapApi.getCouplerIcon(
                            data.loss.low_name,
                            data.loss.high_name,
                            "row"
                          )
                        : MapApi.getCouplerIcon(0, 0, "row"),

                    iconAnchor: [35, 35],
                    popupAnchor: [10, -44],
                    iconSize: [70, 40],
                  })
                : L.icon({
                    iconUrl:
                      data.min_id !== null
                        ? MapApi.getCouplerIcon(
                            data.loss.low_name,
                            data.loss.high_name,
                            "column"
                          )
                        : MapApi.getCouplerIcon(0, 0, "column"),
                    iconRetinaUrl:
                      data.min_id !== null
                        ? MapApi.getCouplerIcon(
                            data.loss.low_name,
                            data.loss.high_name,
                            "column"
                          )
                        : MapApi.getCouplerIcon(0, 0, "column"),
                    iconAnchor: [35, 35],
                    popupAnchor: [10, -44],
                    iconSize: [40, 70],
                  })
            }
          >
            <>
              {data.images !== null ? (
                <Tooltip sticky>
                  <center>{InputData}</center>
                  <img src={data.images} width="200" height={"200"} />
                </Tooltip>
              ) : (
                <Tooltip sticky>
                  <center>{InputData}</center>
                </Tooltip>
              )}
            </>
            <Popup minWidth={300} closeButton={false} ref={popupElRef}>
              <center>
                <h5>Coupler</h5>
              </center>
              <CTabs activeTab="input">
                <CTabContent>
                  <CTabPane data-tab="input">
                    <br />

                    <div
                      style={{
                        backgroundColor: "#bfd8ff",
                        padding: 5,
                        fontWeight: "bold",
                        borderRadius: 5,
                        margin: 5,
                      }}
                    >
                      <center>
                        {" "}
                        INPUT OPTICAL POWER :
                        <CRow>
                          {mapDataFinal.port.wdm_status === 1 ? (
                            <CCol>
                              {" "}
                              <div
                                style={{
                                  backgroundColor: "#fff",
                                  padding: 2,
                                  fontSize:12,
                                  fontWeight: "bold",
                                  borderRadius: 5,
                                  margin: 5,
                                }}
                              >
                                1490 NM: &nbsp;{" "}
                                {data.loss_1490NM !== null
                                  ? data.loss_1490NM + " DB"
                                  : ""}
                              </div>{" "}
                            </CCol>
                          ) : (
                            <></>
                          )}
</CRow>
<CRow>
{MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                        <></>
                      ) : (<> {data.loss_1550NM !== null ? (
                        <CCol>
                          <div
                            style={{
                              backgroundColor: "#fff",
                              padding: 1,
                              fontSize:12,
                              fontWeight: "bold",
                              borderRadius: 5,
                              margin: 1,
                            }}
                          >
                            &nbsp;1550 NM :{" "}
                            {data.loss_1550NM !== null
                              ? data.loss_1550NM + " DB"
                              : ""}
                          </div>
                        </CCol>
                      ) : (
                        <></>
                      )}</>)}
                         
                        </CRow>
                      </center>{" "}
                    </div>
                    <br />
                   

                  </CTabPane>
                  <CTabPane data-tab="coupler">
                    <br />

                    <CRow>
                      <CCol>
                        <CButton
                          onClick={() => {
                            setCouplorIDForSaveLossData();
                            // setSelectedLossId();
                            sethandleLossModal();
                          }}
                          color="info"
                          variant="outline"
                          block
                        >
                          Select Couplor <ArrowForward />
                        </CButton>
                        <br />
                      </CCol>
                    </CRow>
                   
                    {data.loss !== null ? (
                      <center>
                        <b>
                          {" "}
                          <div
                            style={{
                              backgroundColor: "#bfd8ff",
                              padding: 10,
                              borderRadius: 5,
                              margin: 5,
                              cursor: "pointer",
                            }}
                            onClick={() => setUnablePower(!UnablePower)}
                          >
                            {data.loss.high_name ?? ""}
                            {" X "}
                            {data.loss.low_name ?? ""}
                          </div>
                        </b>
                        {data.loss_type ?? ""}
                      </center>
                    ) : (
                      <div></div>
                    )}
                    <br />

                  </CTabPane>
                  <CTabPane data-tab="output">
                    <br />
                      <CRow>
                        <CCol
                          md="3"
                          style={{
                            backgroundColor: "#bfd8ff",
                            padding: 10,
                            borderRadius: 5,
                            margin: 5,
                          }}
                        >
                          <center>Type</center>
                        </CCol>
                        <CCol
                          style={{
                            backgroundColor: "#bfd8ff",
                            padding: 10,
                            borderRadius: 5,
                            margin: 5,
                          }}
                        >
                          <center>
                            {" "}
                            {data.loss !== null ? (
                              data.loss.high_name
                            ) : (
                              <div>Max</div>
                            )}{" "}
                          </center>
                        </CCol>
                        <CCol
                          style={{
                            backgroundColor: "#bfd8ff",
                            padding: 10,
                            borderRadius: 5,
                            margin: 5,
                          }}
                        >
                          <center>
                            {" "}
                            {data.loss !== null ? (
                              data.loss.low_name
                            ) : (
                              <div>Min</div>
                            )}{" "}
                          </center>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md="3">
                          {MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                            ""
                          ) : data.max !== null ? (
                            data.max.loss_1550NM !== null ? (
                              "CATV"
                            ) : (
                              <></>
                            )
                          ) : (
                            <></>
                          )}
                        </CCol>
                        <CCol>
                          <center>OP Power</center>
                        </CCol>
                        <CCol>
                          <center>OP Power</center>
                        </CCol>
                      </CRow>
                      {MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                        <></>
                      ) : data.max !== null ? (
                        data.max.loss_1550NM !== null ? (
                          <CRow>
                            <CCol
                              md="3"
                              style={{
                                backgroundColor: "#bfd8ff",
                                padding: 10,
                                borderRadius: 5,
                                margin: 5,
                              }}
                            >
                              <div
                                style={{
                                  marginTop: 6,
                                  fontWeight: "bold",
                                }}
                              >
                                <center>1550NM</center>
                              </div>
                            </CCol>
                            <CCol
                              style={{
                                backgroundColor: "#bfd8ff",
                                padding: 10,
                                borderRadius: 5,
                                margin: 5,
                              }}
                            >
                              <div class="input-box-DB">
                                <CInput
                                  style={{ width: "100%" }}
                                  // mask="99 . 99"
                                  // readOnly
                                  // value={"111.111"}
                                  value={
                                    data.max !== null ? data.max.loss_1550NM : 0
                                  }
                                />
                                <span class="prefix">DB</span>
                              </div>
                            </CCol>
                            <CCol
                              style={{
                                backgroundColor: "#bfd8ff",
                                padding: 10,
                                borderRadius: 5,
                                margin: 5,
                              }}
                            >
                              <div class="input-box-DB">
                                <CInput
                                  style={{ width: "100%" }}
                                  // readOnly
                                  value={
                                    data.min !== null ? data.min.loss_1550NM : 0
                                  }
                                />
                                <span class="prefix">DB</span>
                              </div>
                            </CCol>
                          </CRow>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}

                      {mapDataFinal.port.wdm_status === 1 ? (
                        <div>
                          INTERNET
                          <CRow>
                            <CCol
                              md="3"
                              style={{
                                backgroundColor: "#bfd8ff",
                                padding: 10,
                                borderRadius: 5,
                                margin: 5,
                              }}
                            >
                              {mapDataFinal.port.wdm_status === 1 ? (
                                <>
                                  <div
                                    style={{
                                      marginTop: 6,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <center> 1490NM</center>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </CCol>
                            <CCol
                              style={{
                                backgroundColor: "#bfd8ff",
                                padding: 10,
                                borderRadius: 5,
                                margin: 5,
                              }}
                            >
                              {mapDataFinal.port.wdm_status === 1 ? (
                                <>
                                  <div class="input-box-DB">
                                    <CInput
                                      style={{ width: "100%" }}
                                      // mask="99 . 99"
                                      // readOnly
                                      value={
                                        data.max !== null
                                          ? data.max.loss_1490NM
                                          : 0
                                      }
                                    />
                                    <span class="prefix">DB</span>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </CCol>
                            <CCol
                              style={{
                                backgroundColor: "#bfd8ff",
                                padding: 10,
                                borderRadius: 5,
                                margin: 5,
                              }}
                            >
                              {mapDataFinal.port.wdm_status === 1 ? (
                                <>
                                  <div class="input-box-DB">
                                    <CInput
                                      style={{ width: "100%" }}
                                      // readOnly
                                      value={
                                        data.min !== null
                                          ? data.min.loss_1490NM
                                          : 0
                                      }
                                    />
                                    <span class="prefix">DB</span>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {/* <`br /> */}
                            </CCol>
                          </CRow>
                        </div>
                      ) : (
                        <></>
                      )}
                      <CRow>
                        <CCol
                          md="3"
                          style={{
                            backgroundColor: "#bfd8ff",
                            padding: 10,
                            borderRadius: 5,
                            margin: 5,
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Add FiberLine
                        </CCol>
                        <CCol
                          style={{
                            backgroundColor: "#bfd8ff",
                            padding: 10,
                            borderRadius: 5,
                            margin: 5,
                          }}
                        >
                          {data.max !== null ? (
                            <>
                              {data.max.status === "Disable" ? (
                                <CButton color="primary" block disabled>
                                  {/* <img src={drawpoliicon} height="20" /> */}
                                  Add FiberLine
                                  {/* <ShowChartRounded /> */}
                                </CButton>
                              ) : data.max.polyline_id !== null ? (
                                <CButton
                                  color="primary"
                                  block
                                  onClick={() => {
                                    PostPolilineMaxContinue();
                                    // closePopups();
                                  }}
                                >
                                  FiberLine Continue
                                  {/* <img src={drawpoliicon} height="20" /> */}
                                </CButton>
                              ) : (
                                <CButton
                                  color="primary"
                                  block
                                  onClick={() => {
                                    PostPolilineMax();
                                    // closePopups();
                                  }}
                                >
                                  Add FiberLine
                                  {/* <img src={drawpoliicon} height="20" /> */}
                                </CButton>
                              )}
                            </>
                          ) : (
                            <div></div>
                          )}
                        </CCol>
                        <CCol
                          style={{
                            backgroundColor: "#bfd8ff",
                            padding: 10,
                            borderRadius: 5,
                            margin: 5,
                          }}
                        >
                          {data.min !== null ? (
                            <>
                              {data.min.status === "Disable" ? (
                                <CButton color="primary" block disabled>
                                  {/* <img src={drawpoliicon} height="20" /> */}
                                  Add FiberLine
                                  {/* <ShowChartRounded /> */}
                                </CButton>
                              ) : data.min.polyline_id !== null ? (
                                <CButton
                                  color="primary"
                                  block
                                  onClick={() => {
                                    PostPolilineMinContinue();
                                    // closePopups();
                                  }}
                                >
                                  FiberLine Continue
                                  {/* <img src={drawpoliicon} height="20" /> */}
                                </CButton>
                              ) : (
                                <CButton
                                  color="primary"
                                  block
                                  onClick={() => {
                                    PostPolilineMin();
                                    hideElement();
                                  }}
                                >
                                  {/* <img src={drawpoliicon} height="20" /> */}
                                  Add FiberLine
                                  {/* <ShowChartRounded /> */}
                                </CButton>
                              )}
                            </>
                          ) : (
                            <div></div>
                          )}
                        </CCol>
                      </CRow>
                      <br />
                  </CTabPane>
                  <CTabPane data-tab="other_info">
                    <br />
                    <CInput
                      onChange={(v) => setInputData(v.target.value)}
                      value={InputData}
                    ></CInput>
                    <CRow>
                      <CCol>
                        <p
                          style={{
                            color: "black",
                            fontsize: 20,
                            fontWeight: "bold",
                          }}
                        >
                          Upload Image{" "}
                          {data.images !== null ? (
                            // <a
                            //   href={data.images}
                            //   target="blank"
                            // >
                            <Satellite onClick={() => handleImageView()} />
                          ) : (
                            // </a>
                            <></>
                          )}
                        </p>
                      </CCol>
                    </CRow>
                    <CInput
                      type="file"
                      placeholder=""
                      onChange={(v) => setInputImage(v.target.files[0])}
                      // value={InputImage}
                    ></CInput>
                    <br />
                    <CRow>
                      <CCol>
                        <CButton
                          onClick={() =>
                            EditCouplrById(data.id, InputData, InputImage)
                          }
                          color="primary"
                          block
                          // variant="outline"
                        >
                          {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                          Save{" "}
                        </CButton>
                      </CCol>
                      <CCol>
                        <CButton
                          onClick={() => DeleteDataByID()}
                          color="secondary"
                          block
                          variant="outline"
                        >
                          <Delete color="#ff0000" style={{ color: "red" }} />
                        </CButton>
                      </CCol>
                    </CRow>
                    <br />
                    <CRow>
                      <CCol>
                        <CTooltip content={"Landscape Coupler"} placement="top">
                          <CButton
                            onClick={() =>
                              EditCouplrRotationIconById(data.id, "row")
                            }
                            color="info"
                            block
                            variant="outline"
                          >
                            Landscape
                            {/* <Autorenew color="#ff0000" style={{ color: "blue" }} /> */}
                          </CButton>
                        </CTooltip>
                      </CCol>
                      <CCol>
                        <CTooltip content={"Portrait Coupler"} placement="top">
                          <CButton
                            onClick={() =>
                              EditCouplrRotationIconById(data.id, "Column")
                            }
                            color="info"
                            block
                            variant="outline"
                          >
                            Portrait
                            {/* <Sync color="#ff0000" style={{ color: "blue" }} /> */}
                          </CButton>
                        </CTooltip>
                      </CCol>
                    </CRow>
                    <br />
                  </CTabPane>
                </CTabContent>
                <CNav variant="tabs" justified="center">
               
                <CNavItem>
                    <CNavLink
                      data-tab="input"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Input Power{" "}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      data-tab="coupler"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Add Coupler
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      data-tab="output"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      OutPut Power{" "}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      data-tab="other_info"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Other Info
                    </CNavLink>
                  </CNavItem>
                </CNav>
              </CTabs>

              <br />
              <CRow>
                <CCol>
                  <CButton
                    variant="outline"
                    color="primary"
                    block
                    onClick={() => {
                      getMyLocation({
                        lat: parseFloat(mapDataFinal.latlang.lat),
                        lang: parseFloat(mapDataFinal.latlang.lang),
                      });
                      setLoadingModalShowForDirectionOpenMap(true);
                    }}
                  >
                    {LoadingModalShowForDirectionOpenMap
                      ? "Wait Loading..."
                      : "Get Location"}{" "}
                    {/* <ArrowForward /> */}
                  </CButton>
                  <br />
                  {getlatforcurruntLocation !== "" ? (
                    <center>
                      {" "}
                      <a
                        href={
                          "https://www.google.com/maps/dir/" +
                          getlatforcurruntLocation +
                          "," +
                          getlangforcurruntLocation +
                          "/" +
                          parseFloat(data.latlang.lat) +
                          "," +
                          parseFloat(data.latlang.lang)
                        }
                        target="_BLANK"
                      >
                        Get Directon <ArrowForward />
                      </a>
                    </center>
                  ) : (
                    ""
                  )}
                </CCol>
              </CRow>
            </Popup>
          </Marker>
        </>
      )}
    </>
  );
}
