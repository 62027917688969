import { CCard, CRow, CTooltip } from "@coreui/react";
import { Info, InfoOutlined } from "@material-ui/icons";
import React from "react";
import { OffcanvasProvider, Trigger, Offcanvas } from "react-simple-offcanvas";
import couplerImg from "../../assets/./tips/coupler/couplerimg.jpeg";
import couplerImgfill from "../../assets/./tips/coupler/fillcoupler.jpeg";
import OnOff from "../../assets/onofficon.png";

export default function TransmitterMapTips() {
  return (
    <OffcanvasProvider>
      <Trigger
        component="div"
        styles={{
          backgroundColor: "transparent",
          color: "gray",
          borderRadius: 5,
        }}
        children={
          <div>
            <CTooltip content={"Tips"}>
              <InfoOutlined className="iconHVR" />
            </CTooltip>
            &nbsp;
          </div>
        }
      />
      <Offcanvas
        title="Tips"
        styles={{ width: "80%" }}
        backdrop={false}
        children={
          <div style={{ marginLeft: 20 }}>
            <CCard style={{ padding: 10 }}>
              <h5> Devices :</h5>
              <br />
              <center>
                <img src={couplerImg} style={{ width: 40 }} />
                <img src={couplerImgfill} style={{ width: 40 }} />
              </center>
              <br />
              <br />
              <h5 style={{ marginTop: 20 }}>
                <center>
                  You can <strong style={{ color: "red" }}>ON</strong>/
                  <strong style={{ color: "Green" }}>OFF</strong> the Port using
                  This switch
                </center>
              </h5>
              <br />
              <br />
              <center style={{ marginTop: 20 }}>
                {" "}
                <img src={OnOff} style={{ width: 70, height: 45 }} />
              </center>
            </CCard>
          </div>
        }
      />
    </OffcanvasProvider>
  );
}
