import { CCard, CRow } from '@coreui/react'
import { Info } from '@material-ui/icons'
import React from 'react'
import { OffcanvasProvider, Trigger, Offcanvas } from 'react-simple-offcanvas'
import greenport from "../../assets/./duplicatePNGS/greenport.png";
import OnOff from "../../assets/onofficon.png";

export default function DeviceTipsDrawer() {
    return (
        <OffcanvasProvider>
            <Trigger component='button' styles={{ backgroundColor: "transparent", color: "gray", borderRadius: 5 }} children={
                <div style={{ fontSize: 15 }}>
                    <Info />&nbsp;
                    Tips
                </div>
            } />
            <Offcanvas title='Tips' backdrop={false} children={

                <div style={{ marginLeft: 20 }}>

                    <CCard style={{ padding: 10 }}>
                        <h5> Port Status :</h5>
                        <br />
                        <center >
                            <img
                                src={
                                    greenport
                                }
                                style={{ width: 40 }}
                            /></center>
                        <br />
                        <br />
                        <h5 style={{ marginTop: 20 }}>
                            <center>
                                You can <strong style={{ color: "red" }}>ON</strong>/<strong style={{ color: "Green" }}>OFF</strong> the Port using This switch
                            </center>
                        </h5>
                        <br />
                        <br />
                        <center style={{ marginTop: 20 }}> <img
                            src={
                                OnOff
                            }
                            style={{ width: 70, height: 45 }}
                        /></center>
                    </CCard>

                </div>
            } />
        </OffcanvasProvider>
    )
}
