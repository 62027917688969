import React, { Component, useEffect, useState } from "react";
import { Suspense } from "react";
import PrivateRoutes from "./Config/PrivateRoutes";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import MapForTransmitter from "./views/mapFunctionallity/deviceslist/transmitter/mapfortransmitter";
import MapForTransmitterForMobile from "./views/mapFunctionallity/deviceslist/transmitter/mapfortransmitterformobileview";
import MapForEdfa from "./views/mapFunctionallity/deviceslist/edfa-ponedfa/edfa/mapforedfa";
import MapForEdfaForMobile from "./views/mapFunctionallity/deviceslist/edfa-ponedfa/edfa/mapforedfaformobile";
import MapForPonEdfa from "./views/mapFunctionallity/deviceslist/edfa-ponedfa/pon-edfa/mapforponedfa";
import MapForPonEdfaForMobile from "./views/mapFunctionallity/deviceslist/edfa-ponedfa/pon-edfa/mapforponedfaformobile";
import MapForGPON from "./views/mapFunctionallity/deviceslist/edfa-ponedfa/gpon/mapforgpon";
import MapForGPONForMobile from "./views/mapFunctionallity/deviceslist/edfa-ponedfa/gpon/mapforgponformobile";
import MapforGEPon from "./views/mapFunctionallity/deviceslist/edfa-ponedfa/gepon/mapforgepon ";
import MapforGEPonForMobile from "./views/mapFunctionallity/deviceslist/edfa-ponedfa/gepon/mapforgeponformobile";
import FTTxDraw from "./views/mapFunctionallity/draws/draws";
import ViewMapScreen from "./views/components/ViewMapScreen";
import Paymetredirctpage from "./views/subscription/paymetredirctpage";
import Privacy from "./website/components/privacy";
import Testmap from "./views/mapFunctionallity/testdata/testmap";
import TemrsAndConditions from "./website/components/termsconditions";
import { PackagesAPI } from "./Config/API";
import swal from "sweetalert";
import WrappedFlowComponent from "./views/mapFunctionallity/draws/testn";
import Flow from "./views/mapFunctionallity/draws/testn";
import TestTrans from "./views/mapFunctionallity/deviceslist/transmitter/testtrans";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const WebSite = React.lazy(() => import("./website/screens/mainpage"));
const Contact = React.lazy(() => import("./website/screens/contact"));
const CreatePlan = React.lazy(() => import("./views/subscription/createplan"));

function App() {
  const [token, settoken] = useState();
  const [CurruntPlan, setCurruntPlan] = useState(false);
  if (!token) {
  }
  // useEffect(() => {
  //   getCurruntPlanData();
  // }, []);
  // const getCurruntPlanData = () => {
  //   // GET request for remote image in node.js

  //   PackagesAPI.getPackagesByID()
  //     .then((response) => {
  //       // console.log(response.data);
  //       if (response.data.current_plan === true) {
  //         setCurruntPlan(true);
  //         // console.log("Plan can not expire");
  //       } else {
  //         setCurruntPlan(false);
  //         swal({
  //           title: "Your Plan is Expire!",
  //           text: "You can not Access your Maps Without Purchase Plan!",
  //           icon: "warning",
  //           buttons: ["Okay", "Okay, go to plans"],
  //           closeOnClickOutside: false,
  //         }).then(function (isConfirm) {
  //           if (isConfirm) {
  //             // history.push("/app/payments");
  //           } else {
  //             getCurruntPlanData();
  //           }
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       // console.log(error.data);
  //     });
  // };
  return (
    <Router>
      <Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />

          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/privacy"
            name="Privacy"
            render={(props) => <Privacy {...props} />}
          />
          <Route
            exact
            path="/termsandconditions"
            name="Terms & Conditions"
            render={(props) => <TemrsAndConditions {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <Route
            exact
            path="/testmap"
            name="Page 500"
            render={(props) => <Testmap {...props} />}
          />
          {/* Transmitter */}
          <Route
            exact
            path="/app/devices/transmitter/:port/maping/:portnumber"
            name="Map Details"
            render={(props) => <MapForTransmitter {...props} />}
          />
          {/* mobile */}
          <Route
            exact
            path="/app/devices/transmitter/:port/maping/:portnumber/:lat/:lang/:portStatus/:WDMStatus/:DeviceToken"
            name="Map Detailss"
            // render={(props) => <TestTrans {...props} />}

            render={(props) => <MapForTransmitter {...props} />}
          />

          <Route
            exact
            path="/app/devices/transmitteen"
            name="Map Details"
            render={(props) => <TestTrans {...props} />}

            // render={(props) => <MapForTransmitterForMobile {...props} />}
          />
          {/* "/app/devices/transmitter/{portNumber}/maping/{pivotId}/{lat}/{lang}/{portStatus}/{wdmStatus}/{DeviceToken}" */}

          {/* edfa */}
          <Route
            exact
            path="/app/devices/edfa/:port/maping/:portnumber"
            name="Map Details"
            render={(props) => <MapForEdfa {...props} />}
          />
          {/* Draw FTTx */}
          <Route
            exact
            path="/app/devices/:deviceName/:port/maping/:portnumber/fttx/:id"
            name="Map Details"
            render={(props) => <FTTxDraw {...props} />}
          />
          {/* mobile */}
          <Route
            exact
            path="/app/devices/edfa/:port/maping/:portnumber/:lat/:lang/:portStatus/:WDMStatus/:DeviceToken"
            name="Map Details"
            // render={(props) => <MapForEdfaForMobile {...props} />}
            render={(props) => <MapForEdfa {...props} />}
          />

          {/* Pon Edfa */}
          <Route
            exact
            path="/app/devices/pon-edfa/:port/maping/:portnumber"
            name="Map Details"
            render={(props) => <MapForPonEdfa {...props} />}
          />

          {/* mobile */}
          <Route
            exact
            path="/app/devices/pon-edfa/:port/maping/:portnumber/:lat/:lang/:portStatus/:WDMStatus/:DeviceToken"
            name="Map Details"
            // render={(props) => <MapForPonEdfaForMobile {...props} />}
            render={(props) => <MapForPonEdfa {...props} />}
          />

          {/* GPON */}
          <Route
            exact
            path="/app/devices/gpon/:port/maping/:portnumber"
            name="Map Details"
            render={(props) => <MapForGPON {...props} />}
          />

          {/* mobile */}
          <Route
            exact
            path="/app/devices/gpon/:port/maping/:portnumber/:lat/:lang/:portStatus/:WDMStatus/:DeviceToken"
            name="Map Details"
            // render={(props) => <MapForGPONForMobile {...props} />}
            render={(props) => <MapForGPON {...props} />}
          />
          <Route
            exact
            path="/app/devices/test"
            name="Map Detailscc"
            render={(props) => <Flow />}
          />

          {/* GEPON */}
          <Route
            exact
            path="/app/devices/gepon/:port/maping/:portnumber"
            name="Map Details"
            render={(props) => <MapforGEPon {...props} />}
          />
          {/* FTTx */}
          <Route
            exact
            path="/app/devices/:mapkey/:port/maping/:portnumber"
            name="Map Details"
            render={(props) => <MapForTransmitterForMobile {...props} />}
          />
          {/* mobile */}
          <Route
            exact
            path="/app/devices/gepon/:port/maping/:portnumber/:lat/:lang/:portStatus/:WDMStatus/:DeviceToken"
            name="Map Details"
            render={(props) => <MapforGEPon {...props} />}
            // render={(props) => <MapforGEPonForMobile {...props} />}
          />
          <Route
            exact
            path="/app/checkDeviceLocation/:lat/:lang"
            name="Map Details"
            render={(props) => <ViewMapScreen {...props} />}
          />
          <Route
            exact
            path="/app/paymentgo/:encRequest/:accessCode"
            name="Map payment Details"
            render={(props) => <Paymetredirctpage {...props} />}
          />
          <Route
            exact
            path="/app/payments"
            name="payment"
            render={(props) => <CreatePlan {...props} />}
          />
          <Route
            exact
            path="/"
            name="Page 500"
            render={(props) => <WebSite />}
          />
          <Route
            exact
            path="/contacts"
            name="Page 500"
            render={(props) => <Contact />}
          />
          <PrivateRoutes
            path="/app"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
