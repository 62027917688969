import {
  CButton,
  CCol,
  CCard,
  CRow,
  CCollapse,
  CCardBody,
  CCardHeader,
  CModal,
  CModalBody,
  CModalHeader,
} from "@coreui/react";
import { ArrowForward } from "@material-ui/icons";
import React, { useState } from "react";
import { MapApi } from "src/Config/API";

const AddDevicesModalFromFiberLineForTrans = ({
  data,
  MapKey,
  getMasteData,
  setcheckPolyLines,
  setcheckPolyLinesContinue,
  mapDataFinal,
  CLossSpliceJoint,
  show,
  onClose,
}) => {
  const [Couplorcollapse, setCouplorCollapse] = useState(false);
  const [OPcollapse, setOPcollapse] = useState(false);
  const [ORPcollapse, setORPcollapse] = useState(false);

  const [Jointcollapse, setJointCollapse] = useState(false);
  const [Loopcollapse, setLoopCollapse] = useState(false);

  function PostPolilineContinueByLatLangCoupler(lat, lng, PointId, PolyData) {
    console.log({
      polyline_id: PolyData.id,
      lat: lat,
      lang: lng,
      point_id: PointId,
    });
    MapApi.addPolilineLatLang(
      {
        polyline_id: PolyData.id,
        lat: lat,
        lang: lng,
      },
      MapKey
    )
      .then((response) => {
        AddPointIdforCoupler(PointId, PolyData.id);
        getMasteData();
        // setcheckPolyLinesContinue();
        onClose();
        setcheckPolyLines();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddPointIdforCoupler(id, PointId) {
    var data = {
      point_id: PointId,
    };
    MapApi.UpdateMasterDataById(id, data, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddCouplorWhenLineDrawing(data, latlang) {
    var datas = {
      latlang: {
        // polyline_id: data.id,
        lat: latlang.lat,
        lang: latlang.lang,
      },
      type: "coupler",
      master_id: data.master_id,
      // loss_id: 1,
      point_id: data.id,
      name: "Coupler",
      icon_type: "coupler",
      icon_name: "this",
    };
    MapApi.addMaster(datas, MapKey)
      .then((respose) => {
        console.log(respose.data);
        setcheckPolyLines();
        onClose();
        getMasteData();
        // setEnableCoupler(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddJointWhenLineDrawing(data, latlang) {
    var data = {
      type: "joint",
      latlang: {
        polyline_id: data.id,
        lat: latlang.lat,
        lang: latlang.lang,
      },
      point_id: data.id,
      master_id: data.master_id,
      meters: CLossSpliceJoint[0].high_value,
      loss_id: 1,
      name: "Joint",
      icon_type: "coupler",
      icon_name: "this",
    };
    MapApi.addMaster(data, MapKey)
      .then((respose) => {
        console.log(respose.data);
        getMasteData();
        // setEnableCoupler(false);
        onClose();

        setcheckPolyLines();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddLoopsWhenLineDrawing(data, latlang) {
    var data = {
      latlang: {
        // polyline_id: data.id,
        lat: latlang.lat,
        lang: latlang.lang,
      },
      type: "loop",
      meters: "0",
      point_id: data.id,
      master_id: data.master_id,
      loss_id: 1,
      name: "loop",
      icon_type: "loop",
      icon_name: "this",
    };
    MapApi.addMaster(data, MapKey)
      .then((respose) => {
        console.log(respose.data);
        getMasteData();
        // setEnableCoupler(false);
        setcheckPolyLines();
        onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddORWhenLineDrawing(data, latlang, type) {
    var data = {
      // customer: {
      //   name: "w",
      //   phone: 898565232,
      //   address: "ewqewq",
      //   setupbox_company: "ewqw",
      // },
      latlang: {
        // polyline_id: data.id,
        lat: latlang.lat,
        lang: latlang.lang,
      },
      type: type,
      point_id: data.id,
      master_id: data.master_id,
      loss_id: 1,
      name: "Optical reciver",
      // op_power: 0,
      meters: 0,
      icon_type: "coupler",
      icon_name: "this",
    };
    MapApi.addMaster(data, MapKey)
      .then((respose) => {
        console.log(respose.data);
        getMasteData();
        // setEnableCoupler(false);
        onClose();

        setcheckPolyLines();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  var LatLngData = [];
  data.latlang.map((data) => {
    LatLngData.push(data);
  });

  return (
    <div>
      <CModal
      key={"1"}
        show={show}
        onClose={onClose}
        className="modal-centered"
        centered
        size="xl"
        closeOnBackdrop={false}
        fade
      >
        <CModalHeader closeButton>Add Devices{data.id}</CModalHeader>
        <CModalBody>
          <CRow>
            <CCol xs="12" sm="6" md="6">
              <CCard>
                <CCardHeader
                  style={{
                    backgroundColor: "#0090FF",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => setCouplorCollapse(!Couplorcollapse)}
                >
                  Coupler
                </CCardHeader>
                <CCollapse show={Couplorcollapse}>
                  <CCardBody>
                    <CRow>
                      <CCol>
                        <CRow>
                          {mapDataFinal.coupler.map((Cdata, i) => {
                            return (
                              <div>
                                {Cdata.device_status === "Disable" ? (
                                  <CButton
                                    disabled
                                    color="info"
                                    style={{
                                      margin: 2,
                                      borderRadius: 15,
                                    }}
                                  >
                                    {Cdata.name}
                                  </CButton>
                                ) : (
                                  <CButton
                                    onClick={() =>
                                      PostPolilineContinueByLatLangCoupler(
                                        Cdata.latlang.lat,
                                        Cdata.latlang.lang,
                                        Cdata.id,
                                        data
                                      )
                                    }
                                    color="info"
                                    style={{
                                      margin: 2,
                                      borderRadius: 15,
                                    }}
                                  >
                                    {Cdata.name}
                                  </CButton>
                                )}
                              </div>
                            );
                          })}
                        </CRow>
                      </CCol>
                    </CRow>
                    <br />
                    <CRow>
                      <CCol>
                        <CButton
                          block
                          style={{
                            backgroundColor: "#4833FF",
                            color: "white",
                          }}
                          onClick={() =>
                            AddCouplorWhenLineDrawing(
                              data,
                              LatLngData[LatLngData.length - 1]
                            )
                          }
                        >
                          Add New Coupler
                        </CButton>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCollapse>
              </CCard>
            </CCol>
            <CCol xs="12" sm="6" md="6">
              <CCard>
                <CCardHeader
                  style={{
                    backgroundColor: "#000000",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => setJointCollapse(!Jointcollapse)}
                >
                  Joints
                </CCardHeader>
                <CCollapse show={Jointcollapse}>
                  <CCardBody>
                    <CRow>
                      <CCol>
                        <CRow>
                          <CCol>
                            <CRow>
                              {mapDataFinal.joint.map((Cdata, i) => {
                                return (
                                  <div>
                                    {Cdata.device_status === "Disable" ? (
                                      <CButton
                                        disabled
                                        color="info"
                                        style={{
                                          margin: 2,
                                          borderRadius: 15,
                                        }}
                                      >
                                        {Cdata.name}
                                      </CButton>
                                    ) : (
                                      <CButton
                                        onClick={() =>
                                          PostPolilineContinueByLatLangCoupler(
                                            Cdata.latlang.lat,
                                            Cdata.latlang.lang,
                                            Cdata.id,
                                            data
                                          )
                                        }
                                        style={{
                                          backgroundColor: "black",
                                          color: "white",
                                          margin: 2,
                                          borderRadius: 15,
                                        }}
                                      >
                                        {Cdata.name}
                                      </CButton>
                                    )}
                                  </div>
                                );
                              })}
                            </CRow>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <br />
                    <CRow>
                      <CCol>
                        <CButton
                          block
                          style={{
                            backgroundColor: "#4833FF",
                            color: "white",
                          }}
                          onClick={() =>
                            AddJointWhenLineDrawing(
                              data,
                              LatLngData[LatLngData.length - 1]
                            )
                          }
                        >
                          Add New Joint
                        </CButton>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCollapse>
              </CCard>
            </CCol>
            <CCol xs="12" sm="6" md="6">
              <CCard>
                <CCardHeader
                  style={{
                    backgroundColor: "#D1B100",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={() => setLoopCollapse(!Loopcollapse)}
                >
                  Loops
                </CCardHeader>
                <CCollapse show={Loopcollapse}>
                  <CCardBody>
                    <CRow>
                      <CCol>
                        <CRow>
                          <CCol>
                            <CRow>
                              <CCol>
                                <CRow>
                                  <CCol></CCol>
                                </CRow>
                                <CRow style={{ marginLeft: 1 }}>
                                  {mapDataFinal.loop.map((Cdata, i) => {
                                    return (
                                      <div>
                                        {Cdata.device_status === "Disable" ? (
                                          <CButton
                                            disabled
                                            color="info"
                                            style={{
                                              margin: 2,
                                              borderRadius: 15,
                                            }}
                                          >
                                            {Cdata.name}
                                          </CButton>
                                        ) : (
                                          <CButton
                                            onClick={() =>
                                              PostPolilineContinueByLatLangCoupler(
                                                Cdata.latlang.lat,
                                                Cdata.latlang.lang,
                                                Cdata.id,
                                                data
                                              )
                                            }
                                            style={{
                                              backgroundColor: "#D1B100",
                                              color: "black",
                                              borderRadius: 15,
                                              margin: 2,
                                            }}
                                          >
                                            {Cdata.name}
                                          </CButton>
                                        )}
                                      </div>
                                    );
                                  })}
                                </CRow>
                              </CCol>
                            </CRow>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <br />
                    <CRow>
                      <CCol>
                        <CButton
                          block
                          style={{
                            backgroundColor: "#4833FF",
                            color: "white",
                          }}
                          onClick={() =>
                            AddLoopsWhenLineDrawing(
                              data,
                              LatLngData[LatLngData.length - 1]
                            )
                          }
                        >
                          Add New Loop
                        </CButton>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCollapse>
              </CCard>
            </CCol>

            <CCol xs="12" sm="6" md="6">
              <CCard>
                <CCardHeader
                  style={{
                    backgroundColor: "#00A65A",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => setOPcollapse(!OPcollapse)}
                >
                  Optical Receiver
                </CCardHeader>
                <CCollapse show={OPcollapse}>
                  <CCardBody>
                    <CRow>
                      <CCol>
                        <CRow>
                          <CCol>
                            <CRow>
                              <CCol>
                                <CRow style={{ marginLeft: 1 }}>
                                  {mapDataFinal.optical_reciver.map(
                                    (Cdata, i) => {
                                      return (
                                        <div>
                                          {Cdata.device_status === "Disable" ? (
                                            <CButton
                                              disabled
                                              color="info"
                                              style={{
                                                margin: 2,
                                                borderRadius: 15,
                                              }}
                                            >
                                              {Cdata.name}
                                            </CButton>
                                          ) : (
                                            <CButton
                                              onClick={() =>
                                                PostPolilineContinueByLatLangCoupler(
                                                  Cdata.latlang.lat,
                                                  Cdata.latlang.lang,
                                                  Cdata.id,
                                                  data
                                                )
                                              }
                                              style={{
                                                backgroundColor: "#00A65A",
                                                color: "white",
                                                borderRadius: 15,
                                                margin: 2,
                                              }}
                                            >
                                              {Cdata.name}
                                            </CButton>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </CRow>
                                <br />
                                <CRow>
                                  <CCol>
                                    <CButton
                                      block
                                      style={{
                                        backgroundColor: "#4833FF",
                                        color: "white",
                                      }}
                                      variant="outline"
                                      onClick={() =>
                                        AddORWhenLineDrawing(
                                          data,
                                          LatLngData[LatLngData.length - 1],
                                          "optical_reciver"
                                        )
                                      }
                                    >
                                      Add New Optical Receiver
                                    </CButton>
                                  </CCol>
                                </CRow>
                              </CCol>
                            </CRow>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCollapse>
              </CCard>
            </CCol>
            <CCol xs="12" sm="6" md="6">
              <CCard>
                <CCardHeader
                  style={{
                    backgroundColor: "#00A65A",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => setORPcollapse(!ORPcollapse)}
                >
                  Optical Receiver Passive
                </CCardHeader>
                <CCollapse show={ORPcollapse}>
                  <CCardBody>
                    <CRow>
                      <CCol>
                        <CRow>
                          <CCol>
                            <CRow>
                              <CCol>
                                <CRow style={{ marginLeft: 1 }}>
                                  {mapDataFinal.optical_reciver_passive.map(
                                    (Cdata, i) => {
                                      return (
                                        <div>
                                          {Cdata.device_status === "Disable" ? (
                                            <CButton
                                              disabled
                                              color="info"
                                              style={{
                                                margin: 2,
                                                borderRadius: 15,
                                              }}
                                            >
                                              {Cdata.name}
                                            </CButton>
                                          ) : (
                                            <CButton
                                              onClick={() =>
                                                PostPolilineContinueByLatLangCoupler(
                                                  Cdata.latlang.lat,
                                                  Cdata.latlang.lang,
                                                  Cdata.id,
                                                  data
                                                )
                                              }
                                              style={{
                                                backgroundColor: "#00A65A",
                                                color: "white",
                                                borderRadius: 15,
                                                margin: 2,
                                              }}
                                            >
                                              {Cdata.name}
                                            </CButton>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </CRow>
                                <br />
                                <CRow>
                                  <CCol>
                                    <CButton
                                      block
                                      style={{
                                        backgroundColor: "#4833FF",
                                        color: "white",
                                      }}
                                      variant="outline"
                                      onClick={() =>
                                        AddORWhenLineDrawing(
                                          data,
                                          LatLngData[LatLngData.length - 1],
                                          "optical_reciver_passive"
                                        )
                                      }
                                    >
                                      Add New Optical Receiver Passive
                                    </CButton>
                                  </CCol>
                                </CRow>
                              </CCol>
                            </CRow>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCollapse>
              </CCard>
            </CCol>
          </CRow>
        </CModalBody>
        
      </CModal>
    </div>
  );
};

export default AddDevicesModalFromFiberLineForTrans;
