import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function PrivateRoutes({ render: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        localStorage.getItem("token") ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to={"/login"} />
        )
      }
    />
  );
}
