import React, { useEffect, useRef, useState } from "react";

import {
  MapContainer,
  Marker,
  Popup,
  Tooltip,
  TileLayer,
  useMapEvents,
  Polyline,
} from "react-leaflet";
import '../../testdata/MapObject.css';
import InputMask from "react-input-mask";
import { useLocation } from "react-router-dom";
import opticalpassive from "../../../../assets/mapsicons/orpassive.svg";
// import {SidebarMap} from "./sidebarMap/SidebarMap";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CCollapse,
  CInput,
  CLabel,
  CModalHeader,
  CModal,
  CRow,
  CTooltip,
  CCardHeader,
  CDropdown,
  CTabs,
  CTabContent,
  CTabPane,
  CNav,
  CNavItem,
  CNavLink,
} from "@coreui/react";

import mainroom from "../../../../assets/mapsicons/mainicon.svg";

import jointIcon from "../../../../assets/mapsicons/joint.svg";

import loopIcon from "../../../../assets/mapsicons/loop.svg";

import opticalIcon from "../../../../assets/mapsicons/opticalrec.svg";

import L from '../../../style/style.css';
import { Backdrop, Icon } from "@material-ui/core";

import {
  AddLocation,
  Delete,
  Satellite,
  ArrowForward,
  DeleteForever,
  DeleteOutline,
  Info,
  Add,
} from "@material-ui/icons";

import { MapApi, LossAPI } from "src/Config/API";

import maploadingg from "../../../../assets/Loading/maploadingg.gif";
import swal from "sweetalert";

import { ToastContainer } from "react-bootstrap";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import Customers from "../../testdata/optical_reciver/customers";
import LossChart from "../../testdata/couplor/loss_chart";
import ImageModal from "../../testdata/components/image_model";
import AddDevicesModalFromFiberLineForTrans from "./components/addDevicesModalFromFiberLineforTrans";
function MapForTransmitter({ match }) {
  const location = useLocation();

  const [map, setMap] = useState(null);
  const [MapKey, setMapKey] = useState("transmitter" ?? "");
  // var finalMeters = null;
  const actions = [
    { icon: "C", name: "Coupler", onClick: () => setEnableCoupler(true) },
    { icon: "J", name: "Joint", onClick: () => setCheckJoint(true) },
    { icon: "L", name: "Loop", onClick: () => setCheckLoop(true) },
    {
      icon: "OR",
      name: "Optical Receiver Active",
      onClick: () => setCheckOR(true),
    },
    {
      icon: "OR-P",
      name: "Optical Receiver Passive",
      onClick: () => setCheckORP(true),
    },
    {
      icon: "MR",
      name: "Marker Enable Disable",
      onClick: () => setEnableMarkerModal(true),
    },
  ];

  const [EnableMarkerModal, setEnableMarkerModal] = useState(false);
  const [ShowModalForCustomer, setShowModalForCustomer] = useState(false);

  const [mapDataFinal, setmapDataFinal] = useState(null);
  const [EnableCoupler, setEnableCoupler] = useState(false);
  const [checkPolyLines, setcheckPolyLines] = useState(false);
  const [CheckJoint, setCheckJoint] = useState(false);
  const [CheckLoop, setCheckLoop] = useState(false);
  const [CheckOR, setCheckOR] = useState(false);
  const [CheckORP, setCheckORP] = useState(false);
  const [MapFiberLineData, setMapFiberLineData] = useState(null);

  const [PoliId, setPoliId] = useState(0);

  const [CustomerID, setCustomerID] = useState(0);

  const [handleImageModal, sethandleImageModal] = useState(false);
  const [getlatforcurruntLocation, setgetlatforcurruntLocation] = useState("");
  const [getlangforcurruntLocation, setgetlangforcurruntLocation] =
    useState("");
  const [
    LoadingModalShowForDirectionOpenMap,
    setLoadingModalShowForDirectionOpenMap,
  ] = useState(false);
  const getMyLocation = ({ lat, lang }) => {
    const location = window.navigator && window.navigator.geolocation;
    // setLoadingModalShowForDirectionOpenMap(true);
    var options = {
      enableHighAccuracy: true,
    };
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLoadingModalShowForDirectionOpenMap(false);
          setgetlatforcurruntLocation(position.coords.latitude);
          setgetlangforcurruntLocation(position.coords.longitude);

          console.log(
            "https://www.google.com/maps/dir/" +
            position.coords.latitude +
            "," +
            position.coords.longitude +
            "/" +
            lat +
            "," +
            lang
          );

          // window.location.replace(
          //   "https://www.google.com/maps/dir/" +
          //     position.coords.latitude +
          //     "," +
          //     position.coords.longitude +
          //     "/" +
          //     lat +
          //     "," +
          //     lang
          // );
        },
        (error) => {
          console.log(error);
        },
        options
      );
    }
  };
  const [imgURL, setimgURL] = useState("");

  const [showDevices, setshowDevices] = useState(false);
  const onCloseDevices = () => {
    setshowDevices(!showDevices);
  };
  const handleImageView = (img) => {
    setimgURL(img);
    sethandleImageModal(!handleImageModal);
  };

  const PolyColors = [
    {
      color: "#0066AA",
      name: "Blue",
      number: "1",
      fontcolor: "#ffffff",
    },
    {
      color: "#F57921",
      name: "Orange",
      number: "2",
      fontcolor: "#000000",
    },
    {
      color: "#71BE47",
      name: "Green",
      number: "3",
      fontcolor: "#000000",
    },
    {
      color: "#784E28",
      name: "Brown",
      number: "4",
      fontcolor: "#ffffff",
    },
    {
      color: "#BCBDBF",
      name: "Slate",
      number: "5",
      fontcolor: "#000000",
    },
    {
      color: "#FFFFFF",
      name: "White",
      number: "6",
      fontcolor: "#000000",
    },
    {
      color: "#D71920",
      name: "Red",
      number: "7",
      fontcolor: "#ffffff",
    },
    {
      color: "#241E20",
      name: "Black",
      number: "8",
      fontcolor: "#ffffff",
    },
    {
      color: "#EAC900",
      name: "Yellow",
      number: "9",
      fontcolor: "#000000",
    },
    {
      color: "#762284",
      name: "Violet",
      number: "10",
      fontcolor: "#ffffff",
    },
    {
      color: "#F599C2",
      name: "Pink(Rose)",
      number: "11",
      fontcolor: "#000000",
    },
    {
      color: "#02A89D",
      name: "Aqua",
      number: "12",
      fontcolor: "#000000",
    },
  ];
  const PolyWith = [
    {
      width: "2",
    },
    {
      width: "4",
    },
    {
      width: "6",
    },
    {
      width: "8",
    },
    {
      width: "10",
    },
    {
      width: "12",
    },
  ];
  useEffect(() => {
    
    console.log(match);
    console.log(location);

    if (match.params.DeviceToken !== null) {
      localStorage.setItem(
        "token",
        match.params.DeviceToken
      );
      setMapKey("transmitter");
      console.log(MapKey);

      if (MapKey !== undefined) {
        MapApi.getUpdateMapAllData(match.params.portnumber, MapKey)
          .then((response) => {
            console.log(response.data);
            getMasteData(MapKey);
            getPoliLineMasterData();
          })
          .catch((error) => { });
      }
      getJointLossSplices(4);
    }

  }, []);

  const getMasteData = () => {
    MapApi.getMapAllData(match.params.portnumber, MapKey)
      .then((response) => {
        setmapDataFinal(response.data.data);
        console.log(response.data.data);
        console.log(mapDataFinal);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPoliLineMasterData = () => {
    MapApi.getMapFiberLineAllData(match.params.portnumber, MapKey)
      .then((response) => {
        setMapFiberLineData(response.data.data);
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updatePolyLine = (id) => {
    MapApi.getMasterDataById(id, MapKey)
      .then((response) => {
        console.log(response.data.data);
        console.log(mapDataFinal);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function handleShowCustomerModal() {
    setShowModalForCustomer(!ShowModalForCustomer);
  }
  const [MapDataFinall, setMapDataFinall] = useState({
    FiberLine: [],
    Couplers: [],
    Joints: [],
  });
  const [PoliLineLocations, setPoliLineLocations] = useState([
    // [23.2326102, 69.6436178, { id: 1 }],
    // [23.23250154083, 69.643681911902, { id: 2 }],
  ]);
  const [addFiberLineCheck, setaddFiberLineCheck] = useState(false);
  const [AddCouplor, setAddCouplor] = useState([]);
  const [AddJoints, setAddJoints] = useState([]);
  const [SelectedLossId, setSelectedLossId] = useState(0);
  const [CouplorIDForSaveLossData, setCouplorIDForSaveLossData] = useState(0);

  const mapRef = useRef(null);

  function Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  //Main Room
  function RefreshCalculateData() {
    MapApi.RefreshCalculationData(match.params.portnumber, MapKey)
      .then((response) => {
        getMasteData();
      })
      .catch((error) => { });
  }
  function AddMainMarker() {
    const [OutPut, setOutPut] = useState(mapDataFinal.value);
    return (
      <div>
        <ToastContainer />

        <Marker
          position={[mapDataFinal.latlang.lat, mapDataFinal.latlang.lang]}
          icon={L.icon({
            iconUrl: mainroom,
            iconRetinaUrl: mainroom,
            iconAnchor: [5, 55],
            popupAnchor: [10, -44],
            iconSize: [75, 105],
          })}
        >
          <Popup closeOnEscapeKey closeButton={false}>
            {/* Port No : {match.params.portnumber} */}
            <center>
              {" "}
              <h3>Transmitter</h3>
            </center>
            <CRow
              style={{
                fontSize: 14,
                color: "black",
              }}
            >
              <CCol>Enter Output Power(1310 NM)</CCol>
            </CRow>
            <table class="table table-bordered table-sm">

              <tbody>
                <tr>
                  <td>

                    <div class="input-box-DB">
                      <InputMask
                        style={{ width: "100%" }}
                        mask="99.99"
                        value={OutPut}
                        onChange={(v) => setOutPut(v.target.value)}
                      ></InputMask>{" "}
                      <span class="prefix">DB</span>
                      <CTooltip content={" 1 DB Margin Loss, \n\n  Please Check Your Output Optical Power In Your Power Meter."}>
                        <Info />

                      </CTooltip>
                    </div>
                    <div style={{ fontsize: 4, color: "red" }}>upto 99.99</div>
                  </td>
                  <td>
                    <CButton
                      color="primary"
                      block
                      onClick={() => EditMainRoom(mapDataFinal.id, OutPut)}
                    >
                      Save
                    </CButton>
                  </td>
                </tr>
                <tr>
                  <td>
                    <CButton
                      variant="outline"
                      color="primary"
                      block
                      onClick={() => {
                        getMyLocation({
                          lat: parseFloat(mapDataFinal.latlang.lat),
                          lang: parseFloat(mapDataFinal.latlang.lang),
                        });
                        setLoadingModalShowForDirectionOpenMap(true);
                      }}
                    >
                      {LoadingModalShowForDirectionOpenMap
                        ? "Wait Loading..."
                        : "Get Location"}{" "}
                      {/* <ArrowForward /> */}
                    </CButton>
                    <br />
                    {getlatforcurruntLocation !== "" ? (
                      <center>
                        {" "}
                        <a
                          href={
                            "https://www.google.com/maps/dir/" +
                            getlatforcurruntLocation +
                            "," +
                            getlangforcurruntLocation +
                            "/" +
                            parseFloat(mapDataFinal.latlang.lat) +
                            "," +
                            parseFloat(mapDataFinal.latlang.lang)
                          }
                          target="_BLANK"
                        >
                          Get Directon <ArrowForward />
                        </a>
                      </center>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    {mapDataFinal.status === "Disable" ? (
                      <CButton color="primary" block disabled>
                        {/* <img src={drawpoliicon} height="20" /> */}
                        Add FiberLine
                        {/* <ShowChartRounded /> */}
                      </CButton>
                    ) : mapDataFinal.polyline_id !== null ? (
                      <CButton
                        variant="outline"
                        color="warning"
                        block
                        style={{ fontsize: 10 }}
                        onClick={() => {
                          setPoliId(mapDataFinal.polyline_id);
                          setcheckPolyLines(true);
                        }}
                      >
                        Continue FiberLine
                      </CButton>
                    ) : (
                      <CButton
                        variant="outline"
                        color="primary"
                        block
                        onClick={() =>
                          PostPoliline(
                            mapDataFinal.id,
                            mapDataFinal.latlang.lat,
                            mapDataFinal.latlang.lang
                          )
                        }
                      >
                        Add FiberLine
                      </CButton>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Popup>
        </Marker>
      </div>
    );
  }
  function EditMainRoom(id, InputData) {
    MapApi.UpdateMasterDataById(
      id,
      {
        value: InputData,
      },
      MapKey
    )
      .then((response) => {
        getMasteData();
      })
      .catch((error) => { });
  }
  function PostPoliline(pointID, lat, lang) {
    var data = {
      master_id: mapDataFinal.id,
      type: "polyline",
      color: "#0066AA",
      point_id: pointID,
      meters: 0,
      data: {
        line_width: "5",
        cable_type: "FTTH",
        fiber_core: "1F",
        number_of_tube: "1",
      },
    };
    MapApi.addMaster(data, MapKey)
      .then((response) => {
        console.log(response.data);
        setPoliId(response.data.data.id);
        MapApi.addPolilineLatLang(
          {
            polyline_id: response.data.data.id,
            lat: lat,
            lang: lang,
          },
          MapKey
        )
          .then((response) => {
            console.log(response.data);
            setcheckPolyLines(true);
            updatePolyLine(response.data.id);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        swal({
          title: "Ops!",
          text: error.response.data.error,
          icon: "warning",
          button: "Okay!",
        });
        console.log(error);
      });
  }
  function PostPolilineContinue() {
    const map = useMapEvents({
      click(event) {
        const { lat, lng } = event.latlng;

        MapApi.addPolilineLatLang(
          {
            polyline_id: PoliId,
            lat: lat,
            lang: lng,
          },
          MapKey
        )
          .then((response) => {
            console.log(response.data);
            getDistance(PoliId);
            getMasteData();
            getPoliLineMasterData();
            updatePolyLine(PoliId);
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });

    return null;
  }
  function PostPolilineContinueByLatLangCoupler(lat, lng, PointId, PolyData) {
    console.log({
      polyline_id: PolyData.id,
      lat: lat,
      lang: lng,
      point_id: PointId,
    });
    MapApi.addPolilineLatLang(
      {
        polyline_id: PolyData.id,
        lat: lat,
        lang: lng,
      },
      MapKey
    )
      .then((response) => {
        AddPointIdforCoupler(PointId, PolyData.id);
        getMasteData();
        setcheckPolyLines(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddFiberLine() {
    return <Polyline positions={PoliLineLocations} color="#ce1010" />;
  }
  function AddDataFiberLine() {
    const [position, setPosition] = useState({ latitude: 0, longitude: 0 });
    const map = useMapEvents({
      click(event) {
        const { lat, lng } = event.latlng;
        setPosition({
          latitude: lat,
          longitude: lng,
        });
        if (addFiberLineCheck === true) {
          PoliLineLocations.push([lat, lng]);
          setPoliLineLocations([...PoliLineLocations]);
        }
      },
    });
    console.log(PoliLineLocations);
    console.log(MapDataFinall);

    return null;
  }
  function AddPoliMarkers() {
    return (
      <Marker
        title="couplor"
        position={PoliLineLocations[PoliLineLocations.length - 1]}
      // icon={mapIcon}
      >
        <Popup closeOnEscapeKey minWidth={300} closeButton={false}>
          {" "}
          <CRow>
            <CCol>
              <CButton
                variant="outline"
                color="warning"
                block
                onClick={() => {
                  AddCouplor.push(
                    PoliLineLocations[PoliLineLocations.length - 1]
                  );
                  setAddCouplor([...AddCouplor]);
                  MapDataFinall.Couplers.push(
                    PoliLineLocations[PoliLineLocations.length - 1]
                  );
                  setMapDataFinall({ ...MapDataFinall });
                }}
              >
                Add Coupler
              </CButton>
            </CCol>
            <CCol>
              <CButton variant="outline" color="info" block>
                Add PLC
              </CButton>
            </CCol>
          </CRow>
          <br />
          <CRow>
            {" "}
            <CCol>
              <CButton
                variant="outline"
                color="primary"
                block
                onClick={() => {
                  AddJoints.push(
                    PoliLineLocations[PoliLineLocations.length - 1]
                  );
                  MapDataFinall.Joints.push(
                    PoliLineLocations[PoliLineLocations.length - 1]
                  );
                  setMapDataFinall({ ...MapDataFinall });
                  setAddJoints([...AddJoints]);
                }}
              >
                Add Joints
              </CButton>
            </CCol>
          </CRow>
        </Popup>
      </Marker>
    );
  }
  function DeleteDataByID(id) {
    MapApi.DeleteMasterDataById(id, MapKey)
      .then((rsponse) => {
        // console.log(rsponse.data);
        getMasteData();
        getPoliLineMasterData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getDistance(PoliId) {
    var PolyData = [];
    MapApi.getMasterDataById(PoliId, MapKey)
      .then((response) => {
        console.log(response.data);
        if (response.data.data.latlang !== []) {
          console.log(response.data.data.latlang);
          var len = response.data.data.latlang.length,
            newData = { aaData: [] },
            i;

          for (i = 0; i < len; i += 1) {
            newData.aaData.push([
              response.data.data.latlang[i].lat,
              response.data.data.latlang[i].lang,
              {
                id: response.data.data.latlang[i].id,
                // color: PolyData.color,
              },
            ]);
          }

          if (map !== null) {
            console.log("data");
            var polyline = L.polyline(newData.aaData).addTo(map);
            var previousPoint;
            console.log(newData.aaData);
            var finalMeters = 0;
            //leafletjs.com/reference.html#polyline-getlatlngs
            var polygon = polyline.getLatLngs().forEach(function (latLng) {
              if (previousPoint) {
                finalMeters += previousPoint.distanceTo(latLng);
                // L.marker(latLng)
                //   .bindPopup(
                //     "Distance from previous point: " +
                //       previousPoint.distanceTo(latLng).toFixed(2) + // http://leafletjs.com/reference.html#latlng-distanceto
                //   `    " meter(s)"
                //   )
                //   .addTo(map);
              }
              previousPoint = latLng;
            });
            var MeterDetails = {
              meters: finalMeters,
            };
            MapApi.UpdateMasterDataById(PoliId, MeterDetails, MapKey)
              .then((response) => {
                console.log(response.data);
              })
              .catch((error) => {
                console.log(error);
              });
            console.log(finalMeters);
          }
        }
        // Object.values(response.data.data.latlang).map((datata) => {
        //   console.log(datata);

        //   // PolyData.push(datata);
        // });
      })
      .catch((error) => { });
  }

  //Optical Fiber Line

  function EditPolyLineColorById(id, InputValue) {
    const map = mapRef.current;
    var data = {
      color: InputValue,
    };
    MapApi.UpdateMasterDataById(id, data, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getPoliLineMasterData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddPolyLineTubeColorById(id, InputValue, index) {
    const map = mapRef.current;
    var data = {
      polyline_id: id,
      color: InputValue,
    };
    MapApi.addFiberTubeColorByFID(data, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getPoliLineMasterData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function DeletePolyLineTubeColorById(id) {
    MapApi.DeleteFiberTubeColorByFID(id, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getPoliLineMasterData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function EditPolyLineWithById(id, InputValue) {
    const map = mapRef.current;
    var data = {
      data: { line_width: InputValue },
    };
    MapApi.UpdateMasterDataById(id, data, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getPoliLineMasterData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function EditPolyLineById(id, OFCompany, FCSelect, CType, noTube) {
    const map = mapRef.current;
    var data = {
      data: {
        optical_fiber_company: OFCompany,
        fiber_core: FCSelect,
        cable_type: CType,
        number_of_tube: noTube,
      },
    };
    MapApi.UpdateMasterDataById(id, data, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getPoliLineMasterData();
      })
      .catch((error) => {
        console.log(error);
        swal({
          title: "Ops!",
          text: error.response.data.error,
          icon: "warning",
          button: "Okay!",
        });
      });
  }
  function ShowFiberLine({ data, i }) {
    const [OFCompany, setOFCompany] = useState("");
    const [FCSelect, setFCSelect] = useState("1F");
    const [CType, setCType] = useState("FTTH");
    const [noTube, setnoTube] = useState("1");
    const [stColor, setstColor] = useState("");
    const [SelectedTubeColorArr, setSelectedTubeColorArr] = useState([]);
    const [UnableDevice, setUnableDevice] = useState(false);
    const [Couplorcollapse, setCouplorCollapse] = useState(false);
    const [OPcollapse, setOPcollapse] = useState(false);
    const [ORPcollapse, setORPcollapse] = useState(false);

    const [Jointcollapse, setJointCollapse] = useState(false);
    const [Loopcollapse, setLoopCollapse] = useState(false);

    useEffect(() => {
      setOFCompany(data.polylinedata.optical_fiber_company);
      setFCSelect(data.polylinedata.fiber_core);
      setCType(data.polylinedata.cable_type);
      setnoTube(data.polylinedata.number_of_tube);
      setstColor(data.polylinedata.core_color);
      setSelectedTubeColorArr(data.tube_data);
    }, [data]);
    var len = data.latlang.length,
      newData = { aaData: [] },
      i;

    for (i = 0; i < len; i += 1) {
      newData.aaData.push([
        data.latlang[i].lat,
        data.latlang[i].lang,
        {
          id: data.latlang[i].id,
          color: data.color,
        },
      ]);
    }

    var LatLngData = [];
    data.latlang.map((data) => {
      LatLngData.push(data);
    });
    var newColor = data.color;
    return (
      <>
        <Polyline
          positions={newData.aaData}
          pathOptions={{ color: newColor }}
          weight={data.polylinedata.line_width}
        // onMouseOver={(e) => e.target.openPopup()}
        >
          <Tooltip sticky>
            {/* {data.name}
          <br/> */}
            {(data.meters / 1000).toFixed(2) + "Km"}
          </Tooltip>

          {data.enable === true ? (
            <Popup closeOnEscapeKey minWidth={300} closeButton={true}>
              <div
                style={{
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: 5,
                  marginTop: 5,
                  fontWeight: "bold",
                  marginBottom: 5,
                  width: 100
                }}
              >
                <center>
                  <h4> {data.name}</h4>
                </center>
              </div>
              <CTabs activeTab="page_1">
                <CTabContent>
                  {" "}
                  <CTabPane data-tab="page_1">
                    <div
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        borderRadius: 5,
                        marginTop: 5,
                        fontWeight: "bold",
                        marginBottom: 5,

                      }}
                    >
                      <center>
                        <h5> Choose Line width For Fiber Line</h5>
                        <CRow className={"justify-content-center"}>
                          {PolyWith.map((Cdata) => (
                            <div
                              style={{
                                backgroundColor:
                                  data.polylinedata.line_width === Cdata.width
                                    ? "#0066AA"
                                    : "#ffffff",
                                width: 45,
                                height: 20,
                                borderRadius: 15,
                                color:
                                  data.polylinedata.line_width === Cdata.width
                                    ? "#ffffff"
                                    : "#000000",
                                // marginBottom: 5,
                                // marginRight: 2,
                                margin: 2,
                                cursor: "pointer",
                                border: "1px solid rgba(10, 9, 9)",
                              }}
                              onClick={() =>
                                EditPolyLineWithById(data.id, Cdata.width)
                              }
                            >
                              <CRow className={"justify-content-center"}>
                                <h5>{Cdata.width}</h5>
                              </CRow>
                            </div>
                          ))}
                        </CRow>
                        <br />
                        <h5> Choose Color For Fiber Line</h5>
                        <CRow className={"justify-content-center"}>
                          {PolyColors.map((Cdata) => (
                            <CTooltip content={Cdata.name} placement="top">
                              <div
                                style={{
                                  backgroundColor: Cdata.color,
                                  width: 45,
                                  height: 45,
                                  borderRadius: 5,
                                  // marginBottom: 5,
                                  // marginRight: 2,
                                  color: Cdata.fontcolor,
                                  margin: 2,
                                  cursor: "pointer",
                                  border: "1px solid rgba(10, 9, 9)",
                                }}
                                onClick={() =>
                                  EditPolyLineColorById(data.id, Cdata.color)
                                }
                              >
                                <center>
                                  <h5>{Cdata.number}</h5>{" "}
                                </center>
                              </div>
                            </CTooltip>
                          ))}
                        </CRow>

                      </center>
                    </div>
                  </CTabPane>
                  <CTabPane data-tab="page_2">
                    <table class="table table-bordered table-sm">
                      <tbody>
                        <tr>
                          <td>Optical Fiber Company</td>
                          <td>
                            <CInput
                              value={OFCompany}
                              onChange={(e) => setOFCompany(e.target.value)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Fiber Core Select</td>
                          <td>
                            <CDropdown className="mt-0 w-`100`">
                              <select
                                caret
                                color="secondary"
                                className="w-100 h-20 custom-select-lg dropdowns"
                                // name="patientTypeRegister"
                                value={FCSelect}
                                onChange={(e) => setFCSelect(e.target.value)}
                              >
                                <option value="1F">1F</option>
                                <option value="2F">2F</option>
                                <option value="4F">4F</option>
                                <option value="8F">8F</option>
                                <option value="12F">12F</option>
                                <option value="24F">24F</option>
                                <option value="48F">48F</option>
                                <option value="96F">96F</option>
                              </select>
                            </CDropdown>
                          </td>
                        </tr>
                        <tr>
                          <td>Core Type</td>
                          <td>
                            <CDropdown className="mt-0 w-`100`">
                              <select
                                caret
                                color="secondary"
                                className="w-100 h-20 custom-select-lg dropdowns"
                                // name="patientTypeRegister"
                                value={CType}
                                onChange={(e) => setCType(e.target.value)}
                              >
                                <option value="FTTH" selected>
                                  FTTH
                                </option>
                                <option value="UNARMERD_2GI">
                                  UNARMERD 2GI
                                </option>
                                <option value="UNARMERD_2_FRP">
                                  UNARMERD 2 FRP
                                </option>
                                <option value="NARMERD_2_FRP_Yarn">
                                  NARMERD 2 FRP Yarn
                                </option>
                                <option value="AURMERED">AURMERED</option>
                                <option value="AURMERED_MULTITUBE">
                                  AURMERED MULTITUBE
                                </option>
                              </select>
                            </CDropdown>
                          </td>
                        </tr>
                        <tr>
                          <td>Number of Tube</td>
                          <td>
                            <CDropdown className="mt-0 w-`100`">
                              <select
                                caret
                                color="secondary"
                                className="w-100 h-20 custom-select-lg dropdowns"
                                value={noTube}
                                onChange={(e) => setnoTube(e.target.value)}
                              >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                              </select>
                            </CDropdown>
                          </td>
                        </tr>
                        <tr>
                          <td>Select Tube Color</td>
                          <td>
                            <CDropdown className="mt-0 w-`100`">
                              <select
                                caret
                                color="secondary"
                                className="w-100 h-20 custom-select-lg dropdowns"
                                value={stColor}
                                onChange={(e) => setstColor(e.target.value)}
                              >
                                {PolyColors.map((Cdata, index) => (
                                  <option
                                    value={Cdata.color}
                                    style={{
                                      backgroundColor: Cdata.color,
                                      color: Cdata.fontcolor,
                                    }}
                                    onClick={() => {
                                      if (
                                        noTube > SelectedTubeColorArr.length
                                      ) {
                                        AddPolyLineTubeColorById(
                                          data.id,
                                          Cdata.color,
                                          index
                                        );
                                      } else {
                                        swal({
                                          title: "Ops!",
                                          text:
                                            "You can not select more Tube color\n you select " +
                                            noTube,
                                          icon: "warning",
                                          button: "Okay!",
                                        });
                                      }
                                    }}
                                  >
                                    {Cdata.name}
                                  </option>
                                ))}
                              </select>
                            </CDropdown>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h5>Tube Color</h5>
                    <CRow className={"justify-content-center"}>
                      {SelectedTubeColorArr.map((Cdata, index) => {
                        if (SelectedTubeColorArr.length !== 0)
                          return (
                            <>
                              <div
                                style={{
                                  backgroundColor: Cdata.color,
                                  width: 45,
                                  height: 45,
                                  borderRadius: 5,

                                  margin: 2,
                                  cursor: "pointer",
                                  border: "1px solid rgba(10, 9, 9)",
                                }}
                              >
                                <center>
                                  <DeleteOutline
                                    onClick={() =>
                                      DeletePolyLineTubeColorById(Cdata.id)
                                    }
                                    style={{ color: "#ff0000" }}
                                  />
                                </center>
                              </div>
                            </>
                          );
                      })}
                    </CRow>
                    <br />
                  </CTabPane>
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>
                          <CButton
                            color="info"
                            block
                            onClick={() =>
                              EditPolyLineById(
                                data.id,
                                OFCompany,
                                FCSelect,
                                CType,
                                noTube
                              )
                            }
                          >
                            Save
                          </CButton>
                        </td>
                        <td>
                          <CButton
                            disabled
                            color="warning"
                            block
                            style={{ color: "black", borderRadius: 15 }}
                          >
                            {(data.meters / 1000).toFixed(2) + "Km"}
                          </CButton>
                        </td>
                        <td>
                          <CButton
                            onClick={() => DeleteDataByID(data.id)}
                            color="danger"
                            block
                          >
                            Delete Fiber Line
                          </CButton>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </CTabContent>
                <CNav variant="tabs" justified="center">
                  <CNavItem>
                    <CNavLink
                      data-tab="page_1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      {" "}
                      Page 1{" "}
                    </CNavLink>
                  </CNavItem>

                  <CNavItem>
                    <CNavLink
                      data-tab="page_2"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Page 2
                    </CNavLink>
                  </CNavItem>
                </CNav>
              </CTabs>

              <CCol></CCol>
            </Popup>
          ) : (
            <></>
          )}

        </Polyline>
        {checkPolyLines === true ? (
          <>
            {data.status === "Enable" ? (
              <Marker position={newData.aaData[newData.aaData.length - 1]}>
                <Popup closeOnEscapeKey minWidth={400} closeButton={false}>
                  <b>

                    <h4>Join your Fiber Line with</h4>
                  </b>

                  {/* <CButton
                    onClick={() => {
                      setcheckPolyLines(false);
                      onCloseDevices();
                    }}
                    color="info"
                    block
                    variant="outline"
                  >
                    Show Devices
                    <ArrowForward />
                  </CButton>
                  <br /> */}
                  <CCard style={{ width: "100%" }}>
                    <CCardHeader
                      style={{
                        backgroundColor: "#0090FF",
                        color: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => setUnableDevice(!UnableDevice)}
                    >
                      Show Devices
                    </CCardHeader>


                    <CCollapse show={UnableDevice}>

                      <CTabs activeTab="Coulper">
                        <CNav variant="tabs">
                          <CNavItem>
                            <CNavLink data-tab="Coulper">
                              Coulper
                            </CNavLink>
                          </CNavItem>
                          <CNavItem>
                            <CNavLink data-tab="Joint">
                              Joint
                            </CNavLink>
                          </CNavItem>
                          <CNavItem>
                            <CNavLink data-tab="Loops">
                              Loops
                            </CNavLink>
                          </CNavItem>
                          <CNavItem>
                            <CNavLink data-tab="Optical-receiver">
                              OR
                            </CNavLink>
                          </CNavItem> <CNavItem>
                            <CNavLink data-tab="optical-receiver-passive">
                              ORP
                            </CNavLink>
                          </CNavItem>
                        </CNav>
                        <CTabContent>
                          <CTabPane data-tab="Coulper">
                            <CCardBody>
                              <CTooltip content={"Add Coupler"}>


                                <Add style={{ cursor: "pointer", marginBottom: 10, backgroundColor: "InfoBackground" }} onClick={() =>
                                  AddCouplorWhenLineDrawing(
                                    data,
                                    LatLngData[LatLngData.length - 1]
                                  )

                                } />
                              </CTooltip>

                              <CRow>
                                {mapDataFinal.coupler.map((Cdata, i) => {
                                  return (
                                    <div>
                                      <CButton
                                        onClick={() =>
                                          PostPolilineContinueByLatLangCoupler(
                                            Cdata.latlang.lat,
                                            Cdata.latlang.lang,
                                            Cdata.id,
                                            data
                                          )
                                        }
                                        color="info"
                                        style={{
                                          margin: 1,
                                          borderRadius: 15,
                                        }}
                                      >
                                        {Cdata.name}
                                      </CButton>
                                    </div>
                                  );
                                })}
                              </CRow>
                            </CCardBody>
                          </CTabPane>
                          <CTabPane data-tab="Joint">
                            <CCardBody>
                              <CTooltip content={"Add Joint"}>


                                <Add style={{ cursor: "pointer", marginBottom: 10, backgroundColor: "InfoBackground" }}
                                  onClick={() =>
                                    AddJointWhenLineDrawing(
                                      data,
                                      LatLngData[LatLngData.length - 1]
                                    )
                                  } />
                              </CTooltip>
                              <CRow>
                                <CCol>
                                  <CRow>
                                    <CCol>
                                      <CRow>
                                        {mapDataFinal.joint.map((Cdata, i) => {
                                          return (
                                            <div>
                                              <CButton
                                                onClick={() =>
                                                  PostPolilineContinueByLatLangCoupler(
                                                    Cdata.latlang.lat,
                                                    Cdata.latlang.lang,
                                                    Cdata.id,
                                                    data
                                                  )
                                                }
                                                style={{
                                                  backgroundColor: "black",
                                                  color: "white",
                                                  margin: 2,
                                                  borderRadius: 15,
                                                }}
                                              >
                                                {Cdata.name}
                                              </CButton>
                                            </div>
                                          );
                                        })}
                                      </CRow>
                                    </CCol>
                                  </CRow>
                                </CCol>
                              </CRow>
                            </CCardBody>
                          </CTabPane>
                          <CTabPane data-tab="Loops">
                            <CCardBody>
                              <CTooltip content={"Add Loops"}>


                                <Add style={{ cursor: "pointer", marginBottom: 10, backgroundColor: "InfoBackground" }} onClick={() =>
                                  AddLoopsWhenLineDrawing(
                                    data,
                                    LatLngData[LatLngData.length - 1]
                                  )

                                } />
                              </CTooltip>
                              <CRow>
                                <CCol>
                                  <CRow>
                                    <CCol>
                                      <CRow>
                                        <CCol>
                                          <CRow>
                                            <CCol></CCol>
                                          </CRow>
                                          <CRow style={{ marginLeft: 1 }}>
                                            {mapDataFinal.loop.map(
                                              (Cdata, i) => {
                                                return (
                                                  <div>
                                                    <CButton
                                                      onClick={() =>
                                                        PostPolilineContinueByLatLangCoupler(
                                                          Cdata.latlang.lat,
                                                          Cdata.latlang.lang,
                                                          Cdata.id,
                                                          data
                                                        )
                                                      }
                                                      style={{
                                                        backgroundColor:
                                                          "#D1B100",
                                                        color: "black",
                                                        borderRadius: 15,
                                                        margin: 2,
                                                      }}
                                                    >
                                                      {Cdata.name}
                                                    </CButton>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </CRow>
                                        </CCol>
                                      </CRow>
                                    </CCol>
                                  </CRow>
                                </CCol>
                              </CRow>
                            </CCardBody>
                          </CTabPane>
                          <CTabPane data-tab="Optical-receiver">
                            <CCardBody>
                              <CTooltip content={"Add Optical Receiver"}>


                                <Add style={{ cursor: "pointer", marginBottom: 10, backgroundColor: "InfoBackground" }} onClick={() =>
                                  AddORWhenLineDrawing(
                                    data,
                                    LatLngData[
                                    LatLngData.length - 1
                                    ],
                                    "optical_reciver"
                                  )

                                } />
                              </CTooltip>  <CRow style={{ marginLeft: 1 }}>
                                {mapDataFinal.optical_reciver.map(
                                  (Cdata, i) => {
                                    return (
                                      <div>
                                        <CButton
                                          onClick={() =>
                                            PostPolilineContinueByLatLangCoupler(
                                              Cdata.latlang.lat,
                                              Cdata.latlang.lang,
                                              Cdata.id,
                                              data
                                            )
                                          }
                                          style={{
                                            backgroundColor:
                                              "#00A65A",
                                            color: "white",
                                            borderRadius: 15,
                                            margin: 2,
                                          }}
                                        >
                                          {Cdata.name}
                                        </CButton>
                                      </div>
                                    );
                                  }
                                )}
                              </CRow>

                            </CCardBody>
                          </CTabPane> <CTabPane data-tab="optical-receiver-passive">
                            <CCardBody>
                              <CTooltip content={"Add Optical Receiver Passive"}>


                                <Add style={{ cursor: "pointer", marginBottom: 10, backgroundColor: "InfoBackground" }} onClick={() =>
                                  AddORWhenLineDrawing(
                                    data,
                                    LatLngData[
                                    LatLngData.length - 1
                                    ],
                                    "optical_reciver_passive"
                                  )
                                } />
                              </CTooltip>     <CRow style={{ marginLeft: 1 }}>
                                {mapDataFinal.optical_reciver_passive.map(
                                  (Cdata, i) => {
                                    return (
                                      <div>
                                        <CButton
                                          onClick={() =>
                                            PostPolilineContinueByLatLangCoupler(
                                              Cdata.latlang.lat,
                                              Cdata.latlang.lang,
                                              Cdata.id,
                                              data
                                            )
                                          }
                                          style={{
                                            backgroundColor:
                                              "#00A65A",
                                            color: "white",
                                            borderRadius: 15,
                                            margin: 2,
                                          }}
                                        >
                                          {Cdata.name}
                                        </CButton>
                                      </div>
                                    );
                                  }
                                )}
                              </CRow>

                            </CCardBody>
                          </CTabPane>
                        </CTabContent>
                      </CTabs>

                    </CCollapse>
                  </CCard>


                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <CButton
                            block
                            color="danger"
                            onClick={() => BackwordPolyLineById(data.id)}
                          >
                            Back
                          </CButton>
                        </td>
                        <td>
                          <CButton
                            block
                            color="success"
                            onClick={() => ForwordPolyLineById(data.id)}
                          >
                            Forword
                          </CButton>

                        </td>
                        <td>
                          <CButton
                            block
                            color="primary"
                            onClick={() => {
                              setcheckPolyLines(true);
                              setPoliId(data.id);
                            }}
                          >
                            Continue Fiber Line
                          </CButton>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Popup>
              </Marker>
            ) : (
              <></>
            )}
          </>
        ) : (
          <div></div>
        )}
        <AddDevicesModalFromFiberLineForTrans
          MapKey={MapKey}
          data={data}
          getMasteData={() => {
            getMasteData();
            getPoliLineMasterData();
          }}
          mapDataFinal={mapDataFinal}
          setcheckPolyLines={() => setcheckPolyLines(false)}
          setcheckPolyLinesContinue={() => setcheckPolyLines(true)}
          show={showDevices}
          CLossSpliceJoint={CLossSpliceJoint}
          onClose={() => onCloseDevices()}
        />
      </>
    );
  }
  function BackwordPolyLineById(id) {
    setPoliId(id);
    MapApi.BackWordPoliline(id, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getMasteData();
        getPoliLineMasterData();
        getDistance(id);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function ForwordPolyLineById(id) {
    setPoliId(id);
    MapApi.ForwordPoliline(id, MapKey)
      .then((response) => {
        console.log(response.data);
        getDistance(id);
        if (response.data.success === false) {
          swal({
            title: "Warning!",
            text: "You can not Forword Fiber Line",
            icon: "warning",
            button: "Okay!",
          });
        }
        getMasteData();
        getPoliLineMasterData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //Coupler
  function PostCouplerData() {
    const map = useMapEvents({
      click(event) {
        const { lat, lng } = event.latlng;

        var data = {
          latlang: {
            lat: lat,
            lang: lng,
          },
          master_id: mapDataFinal.id,
          loss_id: 1,
          name: "Coupler",
          icon_type: "coupler",
          icon_name: "this",
          type: "coupler",
        };

        MapApi.addMaster(data, MapKey)
          .then((respose) => {
            console.log(respose.data);
            getMasteData();
            setEnableCoupler(false);
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });

    return null;
  }
  function AddCouplorWhenLineDrawing(data, latlang) {
    var data = {
      latlang: {
        // polyline_id: data.id,
        lat: latlang.lat,
        lang: latlang.lang,
      },
      type: "coupler",
      master_id: data.master_id,
      loss_id: 1,
      point_id: data.id,
      name: "Coupler",
      icon_type: "coupler",
      icon_name: "this",
    };
    MapApi.addMaster(data, MapKey)
      .then((respose) => {
        console.log(respose.data);
        getMasteData();
        // setEnableCoupler(false);
        setcheckPolyLines(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function EditCouplrRotationIconById(id, InputValue) {
    var data = {
      icon_type: InputValue,
    };
    MapApi.UpdateMasterDataById(id, data, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddPointIdforCoupler(id, PointId) {
    var data = {
      point_id: PointId,
    };
    MapApi.UpdateMasterDataById(id, data, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function CustomeMarkersShow({ data, index }) {
    const [InputData, setInputData] = useState("");
    const [InputImage, setInputImage] = useState(null);
    useEffect(() => {
      setInputData(data.name);
    }, [data]);

    return (
      <>
        <Marker
          position={[
            parseFloat(data.latlang.lat),
            parseFloat(data.latlang.lang),
          ]}
          icon={
            data.icon_type === "row"
              ? L.icon({
                iconUrl:
                  data.loss !== null
                  ? MapApi.getCouplerIcon(data.loss.low_name, data.loss.high_name, "row")
                  : MapApi.getCouplerIcon(0, 0, "row"),
                iconRetinaUrl:
                  data.loss !== null
                  ? MapApi.getCouplerIcon(data.loss.low_name, data.loss.high_name, "row")
                  : MapApi.getCouplerIcon(0, 0, "row"),
                iconAnchor: [35, 35],
                popupAnchor: [10, -44],
                iconSize: [70, 40],
              })
              : L.icon({
                iconUrl:
                  data.loss !== null
                  ? MapApi.getCouplerIcon(data.loss.low_name, data.loss.high_name, "row")
                  : MapApi.getCouplerIcon(0, 0, "row"),
                iconRetinaUrl:
                  data.loss !== null
                  ? MapApi.getCouplerIcon(data.loss.low_name, data.loss.high_name, "row")
                  : MapApi.getCouplerIcon(0, 0, "row"),
                iconAnchor: [35, 35],
                popupAnchor: [10, -44],
                iconSize: [40, 70],
              })
          }
        >
          {data.images !== null ? (
            <Tooltip sticky>
              <center>{data.name}</center>
              <img src={data.images} width="200" height={"200"} />
            </Tooltip>
          ) : (
            <>
              <Tooltip sticky>{data.name}</Tooltip>
            </>
          )}
          {/* <Tooltip sticky>{data.name}</Tooltip> */}
          <Popup minWidth={300} closeButton={false}>
            <center>
              <h5>Couplor</h5>
              {data.loss_type ?? ""}
            </center>
            <br />
            <CTabs activeTab="loss">
              <CTabContent>
                <CTabPane data-tab="loss">
                  <div>

                    <CRow>
                      <CCol>
                        <CButton
                          onClick={() => {
                            setCouplorIDForSaveLossData(data.id);
                            setSelectedLossId(data.loss_id);
                            sethandleLossModal(!handleLossModal);
                          }}
                          color="info"
                          variant="outline"
                          block
                        >
                          Select Couplor <ArrowForward />
                        </CButton>

                        {/* {data.loss !== null ? (
                          <center>
                            <b>
                              {" "}
                              <div
                                style={{
                                  backgroundColor: "#bfd8ff",
                                  padding: 10,
                                  borderRadius: 5,
                                  margin: 5,
                                }}
                              >
                                {data.loss.high_name ?? ""}
                                {" X "}
                                {data.loss.low_name ?? ""}
                              </div>
                            </b>

                          </center>
                        ) : (
                          <div></div>
                        )} */}
                      </CCol>
                    </CRow>
                    <br />
                    <table class="table table-bordered table-sm">
                      <tbody>
                        <tr>

                          <td><center
                            style={{
                              color: "black",
                              fontsize: 26,
                              marginBottom: 5,
                              marginTop: 5,
                            }}
                          >
                            {" "} {" "}
                            {data.loss !== null ? (
                              data.loss.high_name
                            ) : (
                              <div>Max</div>
                            )}{" "}

                          </center></td>
                          <td>
                            OP Power
                            <br />
                            <div class="input-box-DB">
                              <CInput
                                // style={{ width: "100%", marginBottom: 10 }}
                                // mask="99 . 99"
                                // readOnly
                                value={data.max !== null ? data.max.total_loss : 0}
                              />
                              <span class="prefix">DB</span>
                            </div></td>
                          <td>{data.max !== null ? (
                            <><center>
                              {data.max.status === "Disable" ? (
                                <CButton color="primary" style={{ padding: 0 }} block disabled>
                                  {/* <img src={drawpoliicon} height="20" /> */}
                                  <span style={{ fontSize: 10 }}>Add FiberLine</span>
                                  {/* <ShowChartRounded /> */}
                                </CButton>
                              ) : data.max.polyline_id !== null ? (
                                <CButton
                                  color="primary"
                                  block
                                  style={{ padding: 0 }}
                                  onClick={() => {
                                    setPoliId(data.max.id);
                                    setcheckPolyLines(true);
                                    // closePopups();
                                  }}
                                >
                                  <span style={{ fontSize: 11 }}> FiberLine Continue</span>

                                  {/* <img src={drawpoliicon} height="20" /> */}
                                </CButton>
                              ) : (
                                <CButton
                                  color="primary"
                                  block
                                  style={{ padding: 0 }}
                                  onClick={() =>
                                    PostPoliline(
                                      data.max.id,
                                      parseFloat(data.latlang.lat),
                                      parseFloat(data.latlang.lang)
                                    )
                                  }
                                >
                                  <span style={{ fontSize: 11 }}>Add FiberLine</span>
                                </CButton>
                              )}
                            </center> </>
                          ) : (
                            <div></div>
                          )}</td>
                        </tr>
                        <tr>

                          <td>   <center>
                            {" "}
                            {data.loss !== null ? (
                              data.loss.low_name
                            ) : (
                              <div>Min</div>
                            )}
                            <br />
                          </center>
                          </td>
                          <td>     OP Power  <div class="input-box-DB">
                            <CInput
                              // style={{ width: "100%", marginBottom: 10 }}
                              // readOnly
                              value={data.min !== null ? data.min.total_loss : 0}
                            />
                            <span class="prefix">DB</span>
                          </div></td>
                          <td>
                            {data.min !== null ? (
                              <>
                                {data.min.status === "Disable" ? (
                                  <CButton color="primary"
                                    style={{ padding: 0 }}
                                    block disabled>
                                    {/* <img src={drawpoliicon} height="20" /> */}
                                    <span style={{ fontSize: 11 }}>Add FiberLine</span>

                                    {/* <ShowChartRounded /> */}
                                  </CButton>
                                ) : data.max.polyline_id !== null ? (
                                  <CButton
                                    color="primary"
                                    style={{ padding: 0 }}
                                    block
                                    onClick={() => {
                                      setPoliId(data.min.id);
                                      setcheckPolyLines(true);
                                    }}
                                  >
                                    <span style={{ fontSize: 11 }}> FiberLine Continue</span>

                                    {/* <img src={drawpoliicon} height="20" /> */}
                                  </CButton>
                                ) : (
                                  <CButton
                                    color="primary"
                                    block
                                    style={{ padding: 0 }}
                                    onClick={() =>
                                      PostPoliline(
                                        data.min.id,

                                        parseFloat(data.latlang.lat),
                                        parseFloat(data.latlang.lang)
                                      )
                                    }
                                  >
                                    <span style={{ fontSize: 11 }}>Add FiberLine</span>
                                  </CButton>
                                )}
                              </>
                            ) : (
                              <div></div>
                            )}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CTabPane>
                <CTabPane data-tab="other_info">



                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td> <CInput
                          onChange={(v) => setInputData(v.target.value)}
                          value={InputData}
                        ></CInput></td>
                        <td><CInput
                          type="file"
                          placeholder=""
                          onChange={(v) => setInputImage(v.target.files[0])}
                        // value={InputImage}
                        ></CInput></td>
                        <td> <CButton
                          onClick={() =>
                            EditCouplrById(data.id, InputData, InputImage)
                          }
                          color="primary"
                          block
                        // variant="outline"
                        >
                          {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                          Save{" "}
                        </CButton></td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>  <CTooltip content={"Landscape Coupler"} placement="top">
                          <CButton
                            onClick={() =>
                              EditCouplrRotationIconById(data.id, "row")
                            }
                            color="info"
                            block
                            variant="outline"
                          >
                            Landscape
                            {/* <Autorenew color="#ff0000" style={{ color: "blue" }} /> */}
                          </CButton>
                        </CTooltip></td>
                        <td> <CTooltip content={"Portrait Coupler"} placement="top">
                          <CButton
                            onClick={() =>
                              EditCouplrRotationIconById(data.id, "Column")
                            }
                            color="info"
                            block
                            variant="outline"
                          >
                            Portrait
                            {/* <Sync color="#ff0000" style={{ color: "blue" }} /> */}
                          </CButton>
                        </CTooltip></td>
                        <td> <CButton
                          onClick={() => DeleteDataByID(data.id)}
                          color="secondary"
                          block
                          variant="outline"
                        >
                          <Delete color="#ff0000" style={{ color: "red" }} />
                        </CButton></td>
                      </tr>
                    </tbody>
                  </table>

                </CTabPane>
              </CTabContent>
              <CNav variant="tabs" justified="center">
                <CNavItem>
                  <CNavLink
                    data-tab="loss"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    Loss{" "}
                  </CNavLink>
                </CNavItem>

                <CNavItem>
                  <CNavLink
                    data-tab="other_info"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    Other Info
                  </CNavLink>
                </CNavItem>
              </CNav>
            </CTabs>

            <br />
            <CRow>
              <CCol>
                <CButton
                  variant="outline"
                  color="primary"
                  block
                  onClick={() => {
                    getMyLocation({
                      lat: parseFloat(mapDataFinal.latlang.lat),
                      lang: parseFloat(mapDataFinal.latlang.lang),
                    });
                    setLoadingModalShowForDirectionOpenMap(true);
                  }}
                >
                  {LoadingModalShowForDirectionOpenMap
                    ? "Wait Loading..."
                    : "Get Location"}{" "}
                  {/* <ArrowForward /> */}
                </CButton>
                <br />
                {getlatforcurruntLocation !== "" ? (
                  <center>
                    {" "}
                    <a
                      href={
                        "https://www.google.com/maps/dir/" +
                        getlatforcurruntLocation +
                        "," +
                        getlangforcurruntLocation +
                        "/" +
                        parseFloat(data.latlang.lat) +
                        "," +
                        parseFloat(data.latlang.lang)
                      }
                      target="_BLANK"
                    >
                      Get Directon <ArrowForward />
                    </a>
                  </center>
                ) : (
                  ""
                )}
              </CCol>
            </CRow>
          </Popup>
        </Marker>
      </>
    );
  }
  function EditCouplrById(id, InputValue, InputImage) {
    const form = new FormData();
    form.append("name", InputValue);
    // form.append("loss_id", SelectedLossId);

    if (InputImage !== null) {
      form.append("images", InputImage, InputImage.name);
    }
    console.log(form);
    // var data = {
    //   name: InputValue,
    //   images: InputImage,
    // };
    MapApi.UpdateMasterDataById(id, form, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //Joints
  function PostJointData() {
    const map = useMapEvents({
      click(event) {
        const { lat, lng } = event.latlng;
        var data = {
          type: "joint",
          latlang: {
            lat: lat,
            lang: lng,
          },
          // point_id: PoliId,
          master_id: mapDataFinal.id,
          // polyline_id: PolyIdForJoint,
          loss_id: 1,
          meters: CLossSpliceJoint[0].high_value,
          name: "New Joint",
          icon_type: "Joint",
          icon_name: "this",
        };
        MapApi.addMaster(data, MapKey)
          .then((respose) => {
            console.log(respose.data);
            getMasteData();
            setCheckJoint(false);
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });

    return null;
  }
  function CustomeJointsShow({ data }) {
    const [InputData, setInputData] = useState("");
    const [InputMeter, setInputMeter] = useState("");

    const [InputImage, setInputImage] = useState(null);
    const [InputSwapCheck, setInputSwapCheck] = useState(false);
    useEffect(() => {
      setInputData(data.name);
      setInputMeter(data.meters);
      if (data.core_swap === 0) {
        setInputSwapCheck(false);
      } else {
        setInputSwapCheck(true);
      }
    }, [data]);

    return (
      <>
        <Marker
          position={[
            parseFloat(data.latlang.lat),
            parseFloat(data.latlang.lang),
          ]}
          icon={L.icon({
            iconUrl: jointIcon,
            iconRetinaUrl: jointIcon,
            iconAnchor: [5, 55],
            popupAnchor: [10, -44],
            iconSize: [60, 90],
          })}
        >
          {data.images !== null ? (
            <Tooltip sticky>
              <center>{data.name}</center>
              <img src={data.images} width="200" height={"200"} />
            </Tooltip>
          ) : (
            <>
              <Tooltip sticky>{data.name}</Tooltip>
            </>
          )}
          <Popup minWidth={300} closeButton={false}>
            <center>
              <h5>Joint</h5>
            </center>
            <CTabs activeTab="loss">
              <CTabContent>
                <CTabPane data-tab="loss">
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <b>Optical Power</b>
                        </td>
                        <td>
                          {" "}
                          <div class="input-box-DB">
                            <CInput
                              value={data.total_loss}
                            // placeholder="Joint Number"
                            // mask="99.99"
                            ></CInput>
                            <span class="prefix">DB</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Splice Loss</b>
                        </td>
                        <td>
                          {" "}
                          <div class="input-box-DB">
                            <InputMask
                              onChange={(v) => setInputMeter(v.target.value)}
                              value={InputMeter}
                              // placeholder="Joint Number"
                              mask="99.99"
                              style={{ width: "100%" }}
                            // className="inputsm"
                            ></InputMask>
                            <span class="prefix">DB</span>
                          </div>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </CTabPane>
                <CTabPane data-tab="other_info">
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <b>Joint Number</b>
                        </td>
                        <td>
                          {" "}
                          <CInput
                            onChange={(v) => setInputData(v.target.value)}
                            value={InputData}
                            placeholder="Joint Number"
                          ></CInput>
                        </td>
                      </tr>{" "}
                      <tr>
                        <td>
                          <p
                            style={{
                              color: "black",
                              fontsize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            Upload Image{" "}
                            {data.images !== null ? (
                              <Satellite
                                onClick={() => handleImageView(data.images)}
                              />
                            ) : (
                              <></>
                            )}
                          </p>
                        </td>
                        <td>
                          <CInput
                            type="file"
                            placeholder=""
                            onChange={(v) => setInputImage(v.target.files[0])}
                          // value={InputImage}
                          ></CInput>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <b>Core Swap</b>
                        </td>
                        <td>
                          <CLabel class="c-switch c-switch-primary">
                            <CInput
                              type="checkbox"
                              class="c-switch-input"
                              onChange={(v) => {
                                if (InputSwapCheck === true) {
                                  setInputSwapCheck(false);
                                } else {
                                  setInputSwapCheck(true);
                                }
                              }}
                              checked={InputSwapCheck}
                            />
                            <span class="c-switch-slider"></span>
                          </CLabel>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CTabPane>
              </CTabContent>
              <CNav variant="tabs" justified="center">
                {" "}
                <CNavItem>
                  <CNavLink
                    data-tab="loss"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    Loss{" "}
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink
                    data-tab="other_info"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    Other Info
                  </CNavLink>
                </CNavItem>
              </CNav>
            </CTabs>
            <div>
              <br />
              <CRow>
                <CCol>
                  <CButton
                    onClick={() =>
                      EditJointById(
                        data.id,
                        InputData,
                        InputImage,
                        InputMeter,
                        InputSwapCheck ? 1 : 0
                      )
                    }
                    color="primary"
                    block
                  // variant="outline"
                  >
                    {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                    Save{" "}
                  </CButton>
                </CCol>
                <CCol>
                  <CButton
                    onClick={() => DeleteDataByID(data.id)}
                    color="info"
                    block
                    variant="outline"
                  >
                    <Delete color="#ff0000" style={{ color: "red" }} />
                  </CButton>
                </CCol>
              </CRow>
              <br />
              <table class="table table-bordered table-sm">
                <tbody>
                  <tr>
                    <td>
                      {data.status === "Disable" ? (
                        <CButton color="primary" block disabled>
                          {/* <img src={drawpoliicon} height="20" /> */}
                          Add FiberLine
                          {/* <ShowChartRounded /> */}
                        </CButton>
                      ) : data.polyline_id !== null ? (
                        <CButton
                          color="primary"
                          block
                          onClick={() => {
                            setPoliId(data.polyline_id);
                            setcheckPolyLines(true);
                            // closePopups();
                          }}
                        >
                          FiberLine Continue
                          {/* <img src={drawpoliicon} height="20" /> */}
                        </CButton>
                      ) : (
                        <CButton
                          variant="outline"
                          color="primary"
                          block
                          onClick={() =>
                            PostPoliline(
                              data.id,

                              parseFloat(data.latlang.lat),
                              parseFloat(data.latlang.lang)
                            )
                          }
                        >
                          Add FiberLine
                        </CButton>
                      )}
                    </td>
                    <td>
                      <CButton
                        variant="outline"
                        color="primary"
                        block
                        onClick={() => {
                          getMyLocation({
                            lat: parseFloat(mapDataFinal.latlang.lat),
                            lang: parseFloat(mapDataFinal.latlang.lang),
                          });
                          setLoadingModalShowForDirectionOpenMap(true);
                        }}
                      >
                        {LoadingModalShowForDirectionOpenMap
                          ? "Wait Loading..."
                          : "Get Location"}{" "}
                        {/* <ArrowForward /> */}
                      </CButton>

                      {getlatforcurruntLocation !== "" ? (
                        <center>
                          {" "}
                          <a
                            href={
                              "https://www.google.com/maps/dir/" +
                              getlatforcurruntLocation +
                              "," +
                              getlangforcurruntLocation +
                              "/" +
                              parseFloat(data.latlang.lat) +
                              "," +
                              parseFloat(data.latlang.lang)
                            }
                            target="_BLANK"
                          >
                            Get Directon <ArrowForward />
                          </a>
                        </center>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Popup>
        </Marker>
      </>
    );
  }
  function EditJointById(
    id,
    InputValue,
    InputImage,
    InputMeter,
    InputSwapCheck
  ) {
    const form = new FormData();
    form.append("name", InputValue);
    form.append("meters", InputMeter);
    form.append("core_swap", InputSwapCheck);
    if (InputImage !== null) {
      form.append("images", InputImage, InputImage.name);
    }

    console.log(form);
    MapApi.UpdateMasterDataById(id, form, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddJointWhenLineDrawing(data, latlang) {
    var data = {
      type: "joint",
      latlang: {
        polyline_id: data.id,
        lat: latlang.lat,
        lang: latlang.lang,
      },
      point_id: data.id,
      master_id: data.master_id,
      meters: CLossSpliceJoint[0].high_value,
      loss_id: 1,
      name: "Joint",
      icon_type: "coupler",
      icon_name: "this",
    };
    MapApi.addMaster(data, MapKey)
      .then((respose) => {
        console.log(respose.data);
        getMasteData();
        // setEnableCoupler(false);
        setcheckPolyLines(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //loops
  function PostLoopData() {
    const map = useMapEvents({
      click(event) {
        const { lat, lng } = event.latlng;
        var data = {
          latlang: {
            lat: lat,
            lang: lng,
          },
          // point_id: PoliId,

          type: "loop",
          meters: "0",
          master_id: mapDataFinal.id,
          loss_id: 1,
          name: "loop",
          icon_type: "loop",
          icon_name: "this",
        };
        MapApi.addMaster(data, MapKey)
          .then((respose) => {
            console.log(respose.data);
            getMasteData();

            setCheckLoop(false);
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });

    return null;
  }
  function AddLoopsWhenLineDrawing(data, latlang) {
    var data = {
      latlang: {
        // polyline_id: data.id,
        lat: latlang.lat,
        lang: latlang.lang,
      },
      type: "loop",
      meters: "0",
      point_id: data.id,
      master_id: data.master_id,
      loss_id: 1,
      name: "loop",
      icon_type: "loop",
      icon_name: "this",
    };
    MapApi.addMaster(data, MapKey)
      .then((respose) => {
        console.log(respose.data);
        getMasteData();
        // setEnableCoupler(false);
        setcheckPolyLines(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function CustomeLoopsShow({ data }) {
    const [InputNo, setInputNo] = useState("");
    const [InputImage, setInputImage] = useState(null);
    const [InputMeter, setInputMeter] = useState(0);
    useEffect(() => {
      setInputNo(data.name);
      setInputMeter(data.meters);
    }, [data]);

    return (
      <>
        <Marker
          position={[
            parseFloat(data.latlang.lat),
            parseFloat(data.latlang.lang),
          ]}
          icon={L.icon({
            iconUrl: loopIcon,
            iconRetinaUrl: loopIcon,
            iconAnchor: [5, 55],
            popupAnchor: [10, -44],
            iconSize: [60, 90],
          })}
        >
          {data.images !== null ? (
            <Tooltip sticky>
              <center>{InputNo}</center>

              <img src={data.images} width="200" height={"200"} />
            </Tooltip>
          ) : (
            <Tooltip sticky>
              <center>{InputNo}</center>
            </Tooltip>
          )}
          <Popup minWidth={300} closeButton={false}>
            <center>
              <h5>Loop</h5>
            </center>
            <CTabs activeTab="loss">
              <CTabContent>
                <CTabPane data-tab="loss">
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <b>Optical Power</b>
                        </td>
                        <td>
                          {" "}
                          <div class="input-box-DB">
                            <CInput
                              value={data.total_loss}
                            // placeholder="Joint Number"
                            // mask="99.99"
                            ></CInput>
                            <span class="prefix">DB</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <b>Total Meter</b>
                        </td>
                        <td>
                          {" "}
                          <div class="input-box-DB">
                            <CInput
                              onChange={(v) => setInputMeter(v.target.value)}
                              value={InputMeter}
                              placeholder="Enter Meter"
                            ></CInput>
                            <span class="prefix">Meter</span>
                          </div>
                        </td>
                      </tr>{" "}
                    </tbody>
                  </table>
                </CTabPane>
                <CTabPane data-tab="other_info">
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <b>Loop No</b>
                        </td>
                        <td>
                          {" "}
                          <CInput
                            onChange={(v) => setInputNo(v.target.value)}
                            value={InputNo}
                            placeholder="Enter Loop No"
                          ></CInput>
                        </td>
                      </tr>{" "}
                      <tr>
                        <td>
                          {" "}
                          <p
                            style={{
                              color: "black",
                              fontsize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            Upload Image{" "}
                            {data.images !== null ? (
                              <Satellite
                                onClick={() => handleImageView(data.images)}
                              />
                            ) : (
                              <></>
                            )}
                          </p>
                        </td>
                        <td>
                          {" "}
                          <CInput
                            type="file"
                            placeholder=""
                            onChange={(v) => setInputImage(v.target.files[0])}
                          // value={InputImage}
                          ></CInput>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CTabPane>
              </CTabContent>
              <CNav variant="tabs" justified="center">
                <CNavItem>
                  <CNavLink
                    data-tab="loss"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    Loss{" "}
                  </CNavLink>
                </CNavItem>

                <CNavItem>
                  <CNavLink
                    data-tab="other_info"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    Other Info
                  </CNavLink>
                </CNavItem>
              </CNav>
            </CTabs>

            <div>
              <br />
              <CRow>
                <CCol>
                  <CButton
                    onClick={() =>
                      EditLoopsById(data.id, InputNo, InputImage, InputMeter)
                    }
                    color="primary"
                    block
                  // variant="outline"
                  >
                    {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                    Save{" "}
                  </CButton>
                </CCol>
                <CCol>
                  <CButton
                    onClick={() => DeleteDataByID(data.id)}
                    color="info"
                    block
                    variant="outline"
                  >
                    <Delete color="#ff0000" style={{ color: "red" }} />
                  </CButton>
                </CCol>
              </CRow>
              <br />
              {data.status === "Disable" ? (
                <CButton color="primary" block disabled>
                  {/* <img src={drawpoliicon} height="20" /> */}
                  Add FiberLine
                  {/* <ShowChartRounded /> */}
                </CButton>
              ) : data.polyline_id !== null ? (
                <CButton
                  color="primary"
                  block
                  onClick={() => {
                    setPoliId(data.polyline_id);
                    setcheckPolyLines(true);

                    // closePopups();
                  }}
                >
                  FiberLine Continue
                  {/* <img src={drawpoliicon} height="20" /> */}
                </CButton>
              ) : (
                <CButton
                  variant="outline"
                  color="primary"
                  block
                  onClick={() =>
                    PostPoliline(
                      data.id,
                      parseFloat(data.latlang.lat),
                      parseFloat(data.latlang.lang)
                    )
                  }
                >
                  Add FiberLine
                </CButton>
              )}
            </div>
            <br />
            <CRow>
              <CCol>
                <CButton
                  variant="outline"
                  color="primary"
                  block
                  onClick={() => {
                    getMyLocation({
                      lat: parseFloat(mapDataFinal.latlang.lat),
                      lang: parseFloat(mapDataFinal.latlang.lang),
                    });
                    setLoadingModalShowForDirectionOpenMap(true);
                  }}
                >
                  {LoadingModalShowForDirectionOpenMap
                    ? "Wait Loading..."
                    : "Get Location"}{" "}
                  {/* <ArrowForward /> */}
                </CButton>
                <br />
                {getlatforcurruntLocation !== "" ? (
                  <center>
                    {" "}
                    <a
                      href={
                        "https://www.google.com/maps/dir/" +
                        getlatforcurruntLocation +
                        "," +
                        getlangforcurruntLocation +
                        "/" +
                        parseFloat(data.latlang.lat) +
                        "," +
                        parseFloat(data.latlang.lang)
                      }
                      target="_BLANK"
                    >
                      Get Directon <ArrowForward />
                    </a>
                  </center>
                ) : (
                  ""
                )}
              </CCol>
            </CRow>
          </Popup>
        </Marker>
      </>
    );
  }
  function EditLoopsById(id, InputValue, InputImage, InputMeter) {
    const form = new FormData();
    form.append("name", InputValue);
    form.append("meters", InputMeter);

    if (InputImage !== null) {
      form.append("images", InputImage, InputImage.name);
    }
    MapApi.UpdateMasterDataById(id, form, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //Optical Receiver
  function PostORData({ type }) {
    const map = useMapEvents({
      click(event) {
        const { lat, lng } = event.latlng;
        var data = {
          // customer: {
          //   name: "w",
          //   phone: 898565232,
          //   address: "ewqewq",
          //   setupbox_company: "ewqw",
          // },
          type: type,
          // point_id: PoliId,
          latlang: {
            // polyline_id: data.id,
            lat: lat,
            lang: lng,
          },
          master_id: mapDataFinal.id,
          loss_id: 1,
          name: "OR",
          meters: 0,
          icon_type: "or",
          icon_name: "this",
        };
        MapApi.addMaster(data, MapKey)
          .then((respose) => {
            console.log(respose.data);
            getMasteData();
            setCheckOR(false);
            setCheckORP(false);
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });

    return null;
  }
  function AddORWhenLineDrawing(data, latlang, type) {
    var data = {
      // customer: {
      //   name: "w",
      //   phone: 898565232,
      //   address: "ewqewq",
      //   setupbox_company: "ewqw",
      // },
      latlang: {
        // polyline_id: data.id,
        lat: latlang.lat,
        lang: latlang.lang,
      },
      type: type,
      point_id: data.id,
      master_id: data.master_id,
      loss_id: 1,
      name: "Optical reciver",
      // op_power: 0,
      meters: 0,
      icon_type: "coupler",
      icon_name: "this",
    };
    MapApi.addMaster(data, MapKey)
      .then((respose) => {
        console.log(respose.data);
        getMasteData();
        // setEnableCoupler(false);
        setcheckPolyLines(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function CustomeORShow({ data, type }) {
    const [InputData, setInputData] = useState("");
    const [InputImage, setInputImage] = useState(null);
    useEffect(() => {
      setInputData(data.name);
    }, [data]);

    return (
      <>
        <Marker
          position={[
            parseFloat(data.latlang.lat),
            parseFloat(data.latlang.lang),
          ]}
          icon={
            type === "orp"
              ? L.icon({
                iconUrl: opticalpassive,
                iconRetinaUrl: opticalpassive,
                iconAnchor: [5, 55],
                popupAnchor: [10, -44],
                iconSize: [60, 90],
              })
              : L.icon({
                iconUrl: opticalIcon,
                iconRetinaUrl: opticalIcon,
                iconAnchor: [5, 55],
                popupAnchor: [10, -44],
                iconSize: [60, 90],
              })
          }
        >
          {data.images !== null ? (
            <Tooltip sticky>
              <center>{InputData}</center>

              <img src={data.images} width="200" height={"200"} />
            </Tooltip>
          ) : (
            <Tooltip sticky>
              <center>{InputData}</center>
            </Tooltip>
          )}{" "}
          {data.images !== null ? (
            <Tooltip sticky>
              <center>{InputData}</center>

              <img src={data.images} width="200" height={"200"} />
            </Tooltip>
          ) : (
            <Tooltip sticky>
              <center>{InputData}</center>
            </Tooltip>
          )}
          <Popup minWidth={300} closeButton={false}>
            <center>
              <h5>
                {type === "orp"
                  ? "Optical Reciver Passive"
                  : "Optical Reciver Active"}
              </h5>
            </center>
            <CTabs activeTab="loss">
              <CTabContent>
                <CTabPane data-tab="loss">
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <b>Optical Power Receive</b>
                        </td>
                        <td>
                          {" "}
                          <div class="input-box-DB">
                            <CInput
                              // readOnly
                              placeholder="Enter Optical Power"
                              value={data.total_loss}
                            ></CInput>
                            <span class="prefix">DB</span>
                          </div>
                        </td>
                      </tr>{" "}
                    </tbody>
                  </table>
                </CTabPane>{" "}
                <CTabPane data-tab="other_info">
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <b>Optical Power</b>
                        </td>
                        <td>
                          {" "}
                          <CInput
                            onChange={(v) => setInputData(v.target.value)}
                            placeholder="Enter Optical Power"
                            value={InputData}
                          ></CInput>
                        </td>
                      </tr>{" "}
                      <tr>
                        <td>
                          {" "}
                          <p
                            style={{
                              color: "black",
                              fontsize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            <p
                              style={{
                                color: "black",
                                fontsize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Upload Image{" "}
                              {data.images !== null ? (
                                <Satellite
                                  onClick={() => handleImageView(data.images)}
                                />
                              ) : (
                                <></>
                              )}
                            </p>
                          </p>
                        </td>
                        <td>
                          {" "}
                          <CInput
                            type="file"
                            placeholder=""
                            onChange={(v) => setInputImage(v.target.files[0])}
                          ></CInput>
                        </td>
                      </tr>{" "}
                    </tbody>
                  </table>
                  <div>
                    <CButton
                      onClick={() => {
                        handleShowCustomerModal();
                        setCustomerID(data.id);
                      }}
                      type="button"
                      block
                      color="info"
                      variant="outline"
                    >
                      Show Customer <ArrowForward />
                    </CButton>
                    <br />
                    <CRow>
                      <CCol>
                        <CButton
                          onClick={() =>
                            EditOpticalReceiver(data.id, InputData, InputImage)
                          }
                          color="primary"
                          block
                        // variant="outline"
                        >
                          {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                          Save{" "}
                        </CButton>
                      </CCol>
                      <CCol>
                        <CButton
                          onClick={() => DeleteDataByID(data.id)}
                          color="info"
                          block
                          variant="outline"
                        >
                          <Delete color="#ff0000" style={{ color: "red" }} />
                        </CButton>
                      </CCol>
                    </CRow>
                    <br />
                  </div>
                </CTabPane>
              </CTabContent>
              <CNav variant="tabs" justified="center">
                <CNavItem>
                  <CNavLink
                    data-tab="loss"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    Loss{" "}
                  </CNavLink>
                </CNavItem>

                <CNavItem>
                  <CNavLink
                    data-tab="other_info"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    Other Info
                  </CNavLink>
                </CNavItem>
              </CNav>
            </CTabs>
            <br />
            <CRow>
              <CCol>
                <CButton
                  variant="outline"
                  color="primary"
                  block
                  onClick={() => {
                    getMyLocation({
                      lat: parseFloat(mapDataFinal.latlang.lat),
                      lang: parseFloat(mapDataFinal.latlang.lang),
                    });
                    setLoadingModalShowForDirectionOpenMap(true);
                  }}
                >
                  {LoadingModalShowForDirectionOpenMap
                    ? "Wait Loading..."
                    : "Get Location"}{" "}
                  {/* <ArrowForward /> */}
                </CButton>
                <br />
                {getlatforcurruntLocation !== "" ? (
                  <center>
                    {" "}
                    <a
                      href={
                        "https://www.google.com/maps/dir/" +
                        getlatforcurruntLocation +
                        "," +
                        getlangforcurruntLocation +
                        "/" +
                        parseFloat(data.latlang.lat) +
                        "," +
                        parseFloat(data.latlang.lang)
                      }
                      target="_BLANK"
                    >
                      Get Directon <ArrowForward />
                    </a>
                  </center>
                ) : (
                  ""
                )}
              </CCol>
            </CRow>
          </Popup>
        </Marker>
      </>
    );
  }
  function EditOpticalReceiver(id, InputValue, InputImage, InputMeter) {
    const form = new FormData();
    form.append("name", InputValue);
    form.append("meters", InputMeter);

    if (InputImage !== null) {
      form.append("images", InputImage, InputImage.name);
    }
    MapApi.UpdateMasterDataById(id, form, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //fab icon
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [CLossSpliceJoint, setCLossSpliceJoint] = useState([]);

  function getJointLossSplices(catid) {
    LossAPI.getLossData(catid)
      .then(function (response) {
        setCLossSpliceJoint(response.data.loss);

        console.log(CLossSpliceJoint);
      })
      .catch(function (err) {
        console.log("err");
      });
  }

  const [handleLossModal, sethandleLossModal] = useState(false);

  return (
    <div class="containermap">
      <CModal
        show={EnableMarkerModal}
        onClose={() => setEnableMarkerModal(!EnableMarkerModal)}
        // className="modal-centered"
        // centered
        size="sm"
        // closeOnBackdrop={false}
        fade
      >
        <CModalHeader closeButton>Unable Points</CModalHeader>
        <CCardBody style={{ backgroundColor: "#6ad4f7" }}>
          <center>
            <CTooltip content={"Unable Points"} placement="top">
              <CLabel class="c-switch c-switch-primary">
                <CInput
                  type="checkbox"
                  class="c-switch-input"
                  onChange={(v) => {
                    //console.log(value.portEnable);
                    if (checkPolyLines === true) {
                      setcheckPolyLines(false);
                    } else {
                      setcheckPolyLines(true);
                    }
                  }}
                  checked={checkPolyLines}
                />
                <span class="c-switch-slider"></span>
              </CLabel>
            </CTooltip>
          </center>
        </CCardBody>
      </CModal>
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        style={{ margin: 0, right: 20, bottom: 20, position: "fixed" }}
        hidden={false}
        icon={<SpeedDialIcon openIcon={<AddLocation />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            style={{ backgroundColor: "#000000" }}
            color={"#000000"}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.onClick}
            href={action.link}
          />
        ))}
      </SpeedDial>
      {mapDataFinal !== null ? (
        <CRow style={{ marginBottom: 10, marginRight: 10, marginLeft: 10 }}>
          <Backdrop open={open} />
          {/* <div>
            <CButton onClick={() => RefreshCalculateData()}>Calculate</CButton>
          </div> */}
          <MapContainer
            ref={setMap}
            center={[
              parseFloat(mapDataFinal.latlang.lat),
              parseFloat(mapDataFinal.latlang.lang),
            ]}
            dragging={true}
            doubleClickZoom={true}
            scrollWheelZoom={true}
            attributionControl={true}
            //   zoomControl={false}
            // onPopupClose={handlePopupClose}
            zoom={13}
          // onClick={(e) => handleClick(e)}
          >
            <TileLayer
              url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
              maxZoom={20}
              subdomains={["mt1", "mt2", "mt3"]}
            />

            <AddMainMarker />
            {EnableCoupler === true ? <PostCouplerData /> : null}
            {checkPolyLines === true ? <PostPolilineContinue /> : null}
            {CheckJoint === true ? <PostJointData /> : null}
            {CheckLoop === true ? <PostLoopData /> : null}
            {CheckOR === true ? <PostORData type={"optical_reciver"} /> : null}
            {CheckORP === true ? (
              <PostORData type={"optical_reciver_passive"} />
            ) : null}

            {mapDataFinal.coupler.map((data, i) => (
              <CustomeMarkersShow data={data} index={i} />
            ))}
            {mapDataFinal.joint.map((data) => (
              <CustomeJointsShow data={data} />
            ))}
            {mapDataFinal.loop.map((data) => (
              <CustomeLoopsShow data={data} />
            ))}
            {mapDataFinal.optical_reciver.map((data) => (
              <CustomeORShow data={data} type={"ora"} />
            ))}

            {mapDataFinal.optical_reciver_passive.map((data) => (
              <CustomeORShow data={data} type={"orp"} />
            ))}
            <AddFiberLine />
            <AddDataFiberLine />
            {MapFiberLineData !== null
              ? MapFiberLineData.polylines.map((datap, i) => (
                <ShowFiberLine data={datap} i={i} />
              ))
              : null}

            {addFiberLineCheck === true ? <AddPoliMarkers /> : null}
          </MapContainer>
        </CRow>
      ) : (
        <div style={{ backgroundColor: "#ffffff" }}>
          <center>
            <img src={maploadingg} />
          </center>
        </div>
      )}

      <Customers
        id={CustomerID}
        ShowModal={ShowModalForCustomer}
        close={() => setShowModalForCustomer(!ShowModalForCustomer)}
        MapKey={MapKey}
      />
      <LossChart
        CouplorIDForSaveLossData={CouplorIDForSaveLossData}
        show={handleLossModal}
        onClose={() => sethandleLossModal(!handleLossModal)}
        onupdateData={() => getMasteData()}
        MapKey={MapKey}
      />
      <ImageModal
        show={handleImageModal}
        img={imgURL}
        onClose={() => sethandleImageModal(!handleImageModal)}
      />
    </div>
  );
}

export default MapForTransmitter;
