import React, { useEffect, useState } from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from "@coreui/react";
import { useHistory } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { ProfileApi } from "src/Config/API";
import swal from "sweetalert";
const TheHeaderDropdown = () => {
  const history = useHistory();

  const [firstName, setfirstName] = useState("");

  useEffect(() => {
    ProfileApi.getPrfileData()
      .then((response) => {
        // console.log(response.data.user);
        setfirstName(response.data.user.full_name);
      })
      .catch((error) => { });
  }, []);
  const logout = () => {
    /* eslint-disable */


    swal({
      title: "Logout?",
      text: "Are you sure to logout ?",
      icon: "warning",
      buttons: [
        'No, cancel it!',
        'Yes, Logout!'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("userData");
        window.localStorage.clear();
        history.push("/login");
        window.location.reload();

      } else {
        swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    });

    // const toLogout = confirm("Are you sure to logout ?");
    // /* eslint-enable */
    // if (toLogout) {
    //   window.localStorage.removeItem("token");
    //   window.localStorage.removeItem("userData");
    //   window.localStorage.clear();
    //   history.push("/login");
    //   window.location.reload();
    // }
  };

  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg

            src={"https://igenerate.odhavtech.com/profile?text=" + firstName}
            className="c-avatar-img"
          // alt={UserData.email}
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">

        <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>Account</strong>
        </CDropdownItem>
        <CDropdownItem to={"/app/profile"}>
          <CIcon name="cil-user" className="mfe-2" />
          Profile
        </CDropdownItem>


        <CDropdownItem divider />
        <CDropdownItem onClick={logout}>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          Logout Account
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdown;
