import { useCallback } from 'react';
import { Handle, Position } from 'reactflow';

const handleStyle = { left: 10 };

function ONUNode({ data, isConnectable }) {
    return (
        <div >
            <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
            <div style={{
                fontSize: 20,
                
                width: 70,
                borderRadius:30,
                alignContent: "center",
            }} className='ornode'>
              <center>
              ONU
              </center>
            </div>
            {/* {data.otherdata.max !== null ? (
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            left: 50,
            borderStyle: 2,
            borderColor: "black",
            background: "green",
          }}
          id={data.otherdata.max_id}
          isConnectable={isConnectable}
        />
      ) : (
        <></>
      )} */}
        </div>
    );
}

export default ONUNode;
