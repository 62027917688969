import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CSubheader,
  CBreadcrumbRouter,
  CLink,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CImg,
  CTooltip,
  CLabel,
  CInput,
  CSwitch,
  CRow,
  CCol,
} from "@coreui/react";
import { useHistory } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { DeviceApi, ProfileApi } from "src/Config/API";
// routes config
import routes from "../routes";
import jointIcon from "../assets/mapsicons/joint.svg";

import {
  TheHeaderDropdown,
  TheHeaderDropdownSetting,
  TheHeaderDropDownDevices,
} from "./index";
import TheHeaderShowCustomerList from "./TheHeaderShowCustomerList";
import DeviceTipsDrawer from "src/views/Tips/DeviceTipsDrawer";
import { ArrowBackIos, ArrowBackIosOutlined, ArrowBackIosRounded, BackspaceRounded, BarChart, Fullscreen, Home, Refresh, Search, SearchRounded } from "@material-ui/icons";
import MapTips from "src/views/Tips/MapTips";
import TransmitterMapTips from "src/views/Tips/TransmitterTips";

const TheHeaderPlanScreenPhoneScreen = (
  {
    mapFunctionallity,
    couplorfun,
    onufun,
    ornfun,
    ornpfun,
    plcfun,
    jointfun,
    loopfun,
    orfun,
    orpfun,
    fttxfun,
    checkPolyLines,
    setcheckPolyLines,
    cs,
    onu,
    orn,
    ornp,
    joints,
    ls,
    ors,
    orps,
    fttx,
    ps,
    mapdevicename,
    DeviceID,
    portNumber,
    RefreshCustomerListFun,
    portID,
    drawNav,
    title,
    onclickSerach,
    navigtionroutesname,
    isfttx
  }
) => {
  const [PortData, setPortData] = useState(null);
  const [portName,setportName]=useState("");
  const [firstName, setfirstName] = useState("");

  useEffect(() => {
    ProfileApi.getPrfileData()
      .then((response) => {
        // console.log(response.data.user);
        setfirstName(response.data.user.full_name);
      })
      .catch((error) => { });
    if (DeviceID !== 0) {
      console.log(DeviceID);
      DeviceApi.getdevicesDataIndivitual(mapdevicename, DeviceID)
        .then(function (response) {
          setPortData(response.data.data);
        })
        .catch(function (error) {
        });
    }

  }, []);
 
  return (


    <CHeader>
       
        <CHeaderNav className="d-md-down-none mr-auto " >
        {mapFunctionallity === true ? <h3 > {title} </h3> : ""}

        </CHeaderNav>
        <CHeaderNav className="px-3 c-header-nav-items mx-2">
        {mapFunctionallity === true ? <h5 ><b> {title} </b></h5> : ""}

        &nbsp;
        &nbsp;
        &nbsp;
        {isfttx ===true ? <></>: <>
        <CTooltip content={"Search Devices"}> 
        <SearchRounded onClick={onclickSerach} className="iconHVR" />
       </CTooltip> &nbsp;
        &nbsp;</>}
        {mapFunctionallity === true ? <CTooltip content={"Refresh Map"}>
          <Refresh className="iconHVR" onClick={() => {
            window.location.reload();
          }} />
        </CTooltip> : ""}
        &nbsp;
        &nbsp;
        &nbsp;
        {/* {isfttx ===true ? <></>: <>
        {mapFunctionallity === true ? <TheHeaderShowCustomerList custCallFun={() => RefreshCustomerListFun()} portId={portID} /> : ""}
         </>} */}
      
 
        {mapFunctionallity === true ? <TheHeaderDropdownSetting /> : ""}
        &nbsp;
        &nbsp;&nbsp;
        {mapFunctionallity === true ? mapdevicename==="transmitter" ? <TransmitterMapTips />: <MapTips /> : ""}
        </CHeaderNav>
    </CHeader>

  );
};

export default TheHeaderPlanScreenPhoneScreen;
