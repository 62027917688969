import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,

} from "@coreui/react";


// sidebar nav config
import Logo from "../assets/logo1.png";
import LogoMini from "../assets/minilogo.png";

import "../views/style/style.css";
import { DeviceApi } from "src/Config/API";
const TheSidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.sidebarShow);
  const [nav, setnav] = useState([]);
  useEffect(() => {
    // console.log(localStorage.getItem("token"));
    getdata();
  }, []);

  const getdata = () => {
    DeviceApi.getdevicesData()
      .then(function (response) {
        // console.log(response.data.data);
        response.data.data.map((value) => {
          setnav((datas) => [
            ...datas,
            {
              _tag: "CSidebarNavItem",
              name: value.name,
              badge: {
                color: "info",
                text: value.devices.length,
              },
              to: "/app/device/" + value.slug,
            },
          ]);
        });
      })
      .catch(function (response) {
        // console.log(response);
      });
  };
  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch({ type: "set", sidebarShow: val })}
    >
      <CSidebarBrand
        className="d-md-down-none"
      >
        <div className="c-sidebar-brand-full circle">
          <center>
            <img src={Logo} className="imglogosidebar" />
          </center>
        </div>
        <div
          className=" c-sidebar-brand-minimized circleminilogo"
        >
          <img src={LogoMini} className="imglogosidebarmini" />
        </div>
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={[
            {
              _tag: "CSidebarNavItem",
              name: "Dashboard",
              to: "/app/dashboard",
              icon: "cil-home",

            },
            {
              _tag: "CSidebarNavItem",
              name: "Information",
              to: "/app/informations",

              icon: "cil-drop",
            },
            {
              _tag: "CSidebarNavDropdown",
              name: "Devices",
              onclick: () => getdata(),
              icon: "cil-puzzle",
              _children: nav,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Cutomers Locations",
              to: "/app/customers",
              icon: "cil-map",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Report",
              to: "/app/reports",

              icon: "cil-chart-pie",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Subscription",
              to: "/app/subscription",

              icon: "cil-credit-card",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Feedback",
              to: "/app/feedback",
              icon: "cil-pencil",
            },

          ]}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
