import React, { useEffect, useState } from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CLabel,
} from "@coreui/react";
import { DeviceApi } from "src/Config/API";

const TheHeaderDropDownDevices = () => {
  const [nav, setnav] = useState([]);
  useEffect(() => {
    // console.log(localStorage.getItem("token"));
    getdata();
  }, []);

  const getdata = () => {
    DeviceApi.getdevicesData()
      .then(function (response) {
        // console.log(response.data.data);
        setnav(response.data.data);
      })
      .catch(function (response) {
        // console.log(response);
      });
  };
  const itemsCount = 5;
  return (
    <CDropdown inNav className="c-header-nav-item mx-1">
      <CDropdownToggle className="c-header-nav-link">Devices</CDropdownToggle>
      <CDropdownMenu
        className="pt-0 dropdownHeader"
        style={{ backgroundColor: "#ffffff" }}
      >

        {nav.map((data) => (
          <CDropdownItem className="dropdownHeader" to={"/app/device/" + data.slug} >
            <div className="flex-fill">
              <CLabel style={{ float: "left", margin: 0 }}>{data.name}</CLabel>
            </div>
          </CDropdownItem>
        ))}

      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropDownDevices;
