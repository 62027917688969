import React, {

  useEffect,
  useState,
} from "react";
import {
  CCard,
  CCardBody,
  CContainer,
  CForm,
  CButton,
  CModal,
} from "@coreui/react";
import "../style/style.css";
import "../style/lossstyle.css";

import InputMask from "react-input-mask";
import simpleload from "../../assets/Loading/simpleload.gif";
import lcpc from "../../assets/losses/lc-pc.jpg";
import scapc from "../../assets/losses/sc-apc.jpg";
import scupc from "../../assets/losses/sc-upc.jpg";
import swal from "sweetalert";

import lcpcadapter from "../../assets/losses/lcpcadapter.jpg";
import scapcadapter from "../../assets/losses/sc-apc-adapter.jpg";
import scupcadapter from "../../assets/losses/sc-upc-adapter.jpg";
import fiber from "../../assets/losses/fiber.jpg";
import plcswc from "../../assets/losses/plcswc.jpg";
import plcswoc from "../../assets/losses/plcswoc.jpg";
import optwoc from "../../assets/losses/optwoc.jpg";
import optwc from "../../assets/losses/optwc.jpeg";
import { LossAPI } from "src/Config/API";

function CalculateLoss(props) {
  const [LossArr, setLossArr] = React.useState([]);
  const [LoadingToUpdate, setLoadingToUpdate] = useState(false);
  useEffect(() => {
    setLossArr(props.lossdata.loss);
    // console.log(props.lossdata.loss);
  });

  const handleChange = (index, e) => {
    const values = [...LossArr];
    values[index][e.target.name] = e.target.value;
    setLossArr(values);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    setLoadingToUpdate(true);
    // console.log(LossArr);
    var data = {
      loss_ids: LossArr,
      // type:"default"
    };
    LossAPI.UpdateLossData(data)
      .then((response) => {
        setLoadingToUpdate(false);

        swal({
          // title: "Success",
          text: "Your Loss Hase been Updated",
          icon: "success",
          button: "Okay!",
        });
      })
      .catch((error) => { });
    // console.log(LossArr);
  };
  const handleResete = () => {
    swal({
      title: "Are you sure?",
      text: "Reset your all losses!",
      icon: "warning",
      buttons: ["No, cancel it!", "Yes, I am sure!"],
      dangerMode: false,
    }).then(function (isConfirm) {
      if (isConfirm) {
        var data = {
          loss_ids: LossArr,
          // type:"default"
        };
        LossAPI.resetLoss(data)
          .then((response) => {
            props.onresetData();
            swal({
              // title: "Success",
              text: "Your Loss Hase been reseted.",
              icon: "success",
              button: "Okay!",
            });
          })
          .catch((error) => { });
        // console.log(LossArr);
      } else {
        swal("Cancelled", "You cancelled reseting loss:)", "error");
      }
    });
  };
  function handleClose() {
    props.onHide();
  }
  return (
    <div>
      <CModal {...props} className="modal-centered" closeOnBackdrop={false}>
        <br />
        {props.loading ? (
          <div>
            <img src={simpleload} width="100%" />
          </div>
        ) : props.forOpen === "fiberloss" ? (
          <div>
            <center>
              <h4>Optical Fiber Loss&nbsp;&nbsp;&nbsp;<img
                src={fiber}
                width="20%"
                className="imgstyle"
              /></h4>
            </center>
            <CForm onSubmit={handleUpdate}>
              <CContainer>
                <CCard>
                  <CCardBody>
                    <table class="table table-borderless">
                      {LossArr?.map((Loss, index) => (
                        <tr key={index}>
                          <td>{Loss.high_name}</td>
                          <td>&#10140;</td>
                          <td>
                            <InputMask
                              mask="99.99"
                              type="text"
                              className="inputsm"
                              name="high_value"
                              value={Loss.high_value}
                              onChange={(e) => handleChange(index, e)}
                            />{" "}
                            DB
                          </td>
                        </tr>
                      ))}
                    </table>
                    <center>
                      <CButton
                        color="info"
                        type="submit"
                        style={{ display: "inline-block" }}
                      >
                        {LoadingToUpdate ? "Saving.." : "Save"}
                      </CButton>
                      &nbsp;&nbsp;
                      <CButton color="info" onClick={() => handleClose()}>
                        Close
                      </CButton>
                      &nbsp;&nbsp;
                      <CButton color="success" onClick={() => handleResete()}>
                        Reset
                      </CButton>
                    </center>
                  </CCardBody>
                </CCard>
              </CContainer>
            </CForm>
          </div>
        ) : props.forOpen === "couplerlosswith" ? (
          <div>
            <center>
              <h4 style={{ display: "inline-block" }}>
                Optical Coupler Loss (With Connector)&nbsp;&nbsp;&nbsp;<img
                  src={optwc}
                  width="20%"
                  className="imgstyle"
                />
              </h4>
            </center>
            <CForm onSubmit={handleUpdate}>
              <CContainer>
                <CCard>
                  <CCardBody>
                    <table class="table table-borderless table-sm">
                      {LossArr?.map((Loss, index) => (
                        <tr>
                          <td>
                            {Loss.high_name} &#10006; {Loss.low_name}
                          </td>
                          <td>&#10140;</td>
                          <td>
                            <InputMask
                              mask="99.99"
                              className="inputsm"
                              type="text"
                              name="high_value"
                              value={Loss.high_value}
                              onChange={(e) => handleChange(index, e)}
                            ></InputMask>{" "}
                            DB
                          </td>
                          <td>
                            <InputMask
                              mask="99.99"
                              className="inputsm"
                              type="text"
                              name="low_value"
                              value={Loss.low_value}
                              onChange={(e) => handleChange(index, e)}
                            ></InputMask>{" "}
                            DB
                          </td>
                        </tr>
                      ))}
                    </table>
                    <center>
                      <CButton
                        color="info"
                        style={{ display: "inline-block" }}
                        type="submit"
                      >
                        {LoadingToUpdate ? "Saving.." : "Save"}
                      </CButton>
                      &nbsp;&nbsp;
                      <CButton color="info" onClick={() => handleClose()}>
                        Close
                      </CButton>
                      &nbsp;&nbsp;
                      <CButton color="success" onClick={() => handleResete()}>
                        Reset
                      </CButton>
                    </center>
                  </CCardBody>
                </CCard>
              </CContainer>
            </CForm>
          </div>
        ) : props.forOpen === "couplerlosswithout" ? (
          <div>
            <center>
              <h4 style={{ display: "inline-block" }}>
                Optical Coupler Loss (Without Connector)&nbsp;&nbsp;&nbsp;<img
                  src={optwoc}
                  width="20%"
                  className="imgstyle"
                />
              </h4>
            </center>
            <CForm onSubmit={handleUpdate}>
              <CContainer>
                <CCard>
                  <CCardBody>
                    <table class="table table-borderless table-sm">
                      {LossArr?.map((Loss, index) => (
                        <tr>
                          <td>
                            {Loss.high_name} &#10006; {Loss.low_name}
                          </td>
                          <td>&#10140;</td>
                          <td>
                            <InputMask
                              mask="99.99"
                              className="inputsm"
                              type="text"
                              name="high_value"
                              value={Loss.high_value}
                              onChange={(e) => handleChange(index, e)}
                            ></InputMask>{" "}
                            DB
                          </td>
                          <td>
                            <InputMask
                              mask="99.99"
                              className="inputsm"
                              type="text"
                              name="low_value"
                              value={Loss.low_value}
                              onChange={(e) => handleChange(index, e)}
                            ></InputMask>{" "}
                            DB
                          </td>
                        </tr>
                      ))}
                    </table>
                    <center>
                      <CButton
                        color="info"
                        style={{ display: "inline-block" }}
                        type="submit"
                      >
                        {LoadingToUpdate ? "Saving.." : "Save"}
                      </CButton>
                      &nbsp;&nbsp;
                      <CButton color="info" onClick={() => handleClose()}>
                        Close
                      </CButton>
                      &nbsp;&nbsp;
                      <CButton color="success" onClick={() => handleResete()}>
                        Reset
                      </CButton>
                    </center>
                  </CCardBody>
                </CCard>
              </CContainer>
            </CForm>
          </div>
        ) : props.forOpen === "splicesloss" ? (
          <div>
            <center>
              <h4 style={{ display: "inline-block" }}>Splices Loss</h4>
            </center>
            <CForm onSubmit={handleUpdate}>
              <CContainer>
                <CCard>
                  <CCardBody>
                    <table class="table table-borderless table-sm">
                      {LossArr?.map((Loss, index) => (
                        <tr>
                          <center>
                            <td>
                              <InputMask
                                mask="99.99"
                                className="inputsm"
                                type="text"
                                name="high_value"
                                value={Loss.high_value}
                                onChange={(e) => handleChange(index, e)}
                              ></InputMask>{" "}
                              DB
                            </td>
                          </center>
                        </tr>
                      ))}
                    </table>
                    <center>
                      <CButton
                        color="info"
                        style={{ display: "inline-block" }}
                        type="submit"
                      >
                        {LoadingToUpdate ? "Saving.." : "Save"}
                      </CButton>
                      &nbsp;&nbsp;
                      <CButton color="info" onClick={() => handleClose()}>
                        Close
                      </CButton>{" "}
                      &nbsp;&nbsp;
                      <CButton color="success" onClick={() => handleResete()}>
                        Reset
                      </CButton>
                    </center>
                  </CCardBody>
                </CCard>
              </CContainer>
            </CForm>
          </div>
        ) : props.forOpen === "adapterloss" ? (
          <div>
            <center>
              <h4 style={{ display: "inline-block" }}>Adapter Loss</h4>
            </center>
            <CForm onSubmit={handleUpdate}>
              <CContainer>
                <CCard>
                  <CCardBody>
                    <table class="table table-borderless">
                      {LossArr?.map((Loss, index) => (
                        <tr>
                          <td className="col-2">
                            {Loss.high_name === "SC - APC" ? (
                              <img
                                src={scapcadapter}
                                width="100%"
                                className="imgstyle"
                              />
                            ) : Loss.high_name === "SC - UPC" ? (
                              <img
                                src={scupcadapter}
                                width="100%"
                                className="imgstyle"
                              />
                            ) : (
                              <img
                                src={lcpcadapter}
                                width="100%"
                                className="imgstyle"
                              />
                            )}
                            {/* <img src="/img3.PNG" /> */}
                          </td>
                          <td>{Loss.high_name}</td>
                          <td>&#10140;</td>
                          <td>
                            <InputMask
                              mask="99.99"
                              type="text"
                              className="inputsm"
                              name="high_value"
                              value={Loss.high_value}
                              onChange={(e) => handleChange(index, e)}
                            ></InputMask>{" "}
                            DB
                          </td>
                        </tr>
                      ))}
                    </table>
                    <center>
                      <CButton
                        color="info"
                        style={{ display: "inline-block" }}
                        type="submit"
                      >
                        save
                      </CButton>
                      &nbsp;&nbsp;
                      <CButton color="info" onClick={() => handleClose()}>
                        Close
                      </CButton>
                      &nbsp;&nbsp;
                      <CButton color="success" onClick={() => handleResete()}>
                        Reset
                      </CButton>
                    </center>
                  </CCardBody>
                </CCard>
              </CContainer>
            </CForm>
          </div>
        ) : props.forOpen === "patchcoard" ? (
          <div>
            <center>
              <h4 style={{ display: "inline-block" }}>Patch Coard</h4>
            </center>
            <CForm onSubmit={handleUpdate}>
              <CContainer>
                <CCard>
                  <CCardBody>
                    <table class="table table-borderless">
                      {LossArr?.map((Loss, index) => (
                        <tr>
                          <td className="col-2">
                            {Loss.high_name === "Sc-Apc" ? (
                              <img
                                src={scapc}
                                width="100%"
                                className="imgstyle"
                              />
                            ) : Loss.high_name === "Sc-Upc" ? (
                              <img
                                src={scupc}
                                width="100%"
                                className="imgstyle"
                              />
                            ) : (
                              <img
                                src={lcpc}
                                width="100%"
                                className="imgstyle"
                              />
                            )}
                            {/* <img src="/img3.PNG" /> */}
                          </td>
                          <td>{Loss.high_name}</td>
                          <td>&#10140;</td>
                          <td>
                            <InputMask
                              mask="99.99"
                              type="text"
                              className="inputsm"
                              name="high_value"
                              value={Loss.high_value}
                              onChange={(e) => handleChange(index, e)}
                            ></InputMask>{" "}
                            DB
                          </td>
                        </tr>
                      ))}
                    </table>
                    <center>
                      <CButton
                        color="info"
                        style={{ display: "inline-block" }}
                        type="submit"
                      >
                        {LoadingToUpdate ? "Saving.." : "Save"}
                      </CButton>
                      &nbsp;&nbsp;
                      <CButton color="info" onClick={() => handleClose()}>
                        Close
                      </CButton>
                      &nbsp;&nbsp;
                      <CButton color="success" onClick={() => handleResete()}>
                        Reset
                      </CButton>
                    </center>
                  </CCardBody>
                </CCard>
              </CContainer>
            </CForm>
          </div>
        ) : props.forOpen === "PLCsplitterwith" ? (
          <div>
            <center>
              <h4 style={{ display: "inline-block" }}>
                PLC Splitter (With Connecter)&nbsp;&nbsp;&nbsp;<img
                  src={plcswc}
                  width="20%"
                  className="imgstyle"
                />
              </h4>
            </center>
            <CForm onSubmit={handleUpdate}>
              <CContainer>
                <CCard>
                  <CCardBody>
                    <table class="table table-borderless">
                      {LossArr?.map((Loss, index) => (
                        <tr>
                          <td>1 &#10006; {Loss.high_name}</td>
                          <td>&#10140;</td>
                          <td>
                            <InputMask
                              mask="99.99"
                              className="inputsm"
                              type="text"
                              name="high_value"
                              value={Loss.high_value}
                              onChange={(e) => handleChange(index, e)}
                            ></InputMask>{" "}
                            DB
                          </td>
                        </tr>
                      ))}
                    </table>
                    <center>
                      <CButton
                        color="info"
                        style={{ display: "inline-block" }}
                        type="submit"
                      >
                        {LoadingToUpdate ? "Saving.." : "Save"}
                      </CButton>
                      &nbsp;&nbsp;
                      <CButton color="info" onClick={() => handleClose()}>
                        Close
                      </CButton>
                      &nbsp;&nbsp;
                      <CButton color="success" onClick={() => handleResete()}>
                        Reset
                      </CButton>
                    </center>
                  </CCardBody>
                </CCard>
              </CContainer>
            </CForm>
          </div>
        ) : (
          <div>
            <center>
              <h4 style={{ display: "inline-block" }}>
                PLC Splitter (Without Connecter)&nbsp;&nbsp;&nbsp;<img
                  src={plcswoc}
                  width="20%"
                  className="imgstyle"
                />
              </h4>
            </center>
            <CForm onSubmit={handleUpdate}>
              <CContainer>
                <CCard>
                  <CCardBody>
                    <table class="table table-borderless">
                      {LossArr?.map((Loss, index) => (
                        <tr>
                          <td>1 &#10006; {Loss.high_name}</td>
                          <td>&#10140;</td>
                          <td>
                            <InputMask
                              mask="99.99"
                              className="inputsm"
                              type="text"
                              name="high_value"
                              value={Loss.high_value}
                              onChange={(e) => handleChange(index, e)}
                            ></InputMask>{" "}
                            DB
                          </td>
                        </tr>
                      ))}
                    </table>
                    <center>
                      <CButton
                        color="info"
                        style={{ display: "inline-block" }}
                        type="submit"
                      >
                        {LoadingToUpdate ? "Saving.." : "Save"}
                      </CButton>
                      &nbsp;&nbsp;
                      <CButton color="info" onClick={() => handleClose()}>
                        Close
                      </CButton>
                      &nbsp;&nbsp;
                      <CButton color="success" onClick={() => handleResete()}>
                        Reset
                      </CButton>
                    </center>
                  </CCardBody>
                </CCard>
              </CContainer>
            </CForm>
          </div>
        )}
      </CModal>
    </div>
  );
}

export default CalculateLoss;
