import React from 'react'

function Privacy() {
    return (
        <div>  <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <br />
                    <br />
                    <h2>Privacy Policy for Navya techs</h2>
                    <br />
                    <p>
                        At Navya techs, we take your privacy very seriously. This Privacy
                        Policy outlines the types of information we collect from users of
                        our Navya techs application and how we use and protect that
                        information.
                    </p>
                    <h4>1. Personal Information</h4>
                    <p>
                        We may collect certain personal information from you when you interact with our website, such as a
                        Name,Email address, Contact information,Billing information (for purchases) This information is collected to
                        help us improve the functionality of the app and to provide better
                        service to our users.
                    </p>
                    <h4>2. Use of Google Maps</h4>
                    <p>
                        Our application uses Google Maps to display maps and enable users to
                        draw lines on the map. Google Maps may collect and process
                        information about your use of the maps, including your IP address
                        and location information. Please refer to Google's Privacy Policy
                        for more information on how Google collects and processes data.
                    </p>

                    <h4>3. Use of Location Information</h4>

                    <p>
                        Our application uses location information to allow you to set your
                        location, and to obtain the latitude and longitude for mapping
                        purposes. We do not store this information on our servers, and it is
                        only used within the application to provide the intended
                        functionality.
                    </p>

                    <h4>4. Third-Party Services</h4>

                    <p>
                        Our application may use third-party services, such as Google
                        Analytics, to help us analyze how our application is being used.
                        These third-party services may collect non-personal information
                        about your use of the app, such as the pages you view and the
                        actions you take while using the app.
                    </p>

                    <h4>5. Security</h4>

                    <p>
                        We take reasonable measures to protect your personal and
                        non-personal information from unauthorized access, use, or
                        disclosure. We use industry-standard encryption technology to
                        protect any information transmitted between your device and our
                        servers.
                    </p>

                    <h4>6. Changes to Privacy Policy</h4>

                    <p>
                        We may update this Privacy Policy from time to time to reflect
                        changes to our practices. We will notify you of any changes to this
                        policy by posting the updated policy on our website and within the
                        app.
                    </p>


                    <h4>7. Contact Us</h4>

                    <p>
                        If you have any questions or concerns about our Privacy Policy,
                        please contact us at Your Company Name via email at&nbsp;
                        <b><a href="mailto:navyatechs33@gmail.com">navyatechs33@gmail.com</a></b>
                    </p>

                    <p>
                        By using our Navya techs, you agree to the terms of this Privacy
                        Policy. If you do not agree with this policy, please do not use our
                        application.
                    </p>
                </div>
            </div>
        </div></div>
    )
}

export default Privacy