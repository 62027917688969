import { useCallback } from "react";
import { Handle, Position } from "reactflow";

const handleStyle = { left: 20 };

function CouplerNode({ data, isConnectable }) {
  return (
    <div>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
        style={{ borderStyle: 2, borderColor: "black", background: "green" }}
      />
      <div
        style={{
          fontSize: 15,
          height: 35,
          paddingTop: 3,
          width: 70,
          alignContent: "center",
        }}
        className="cutomernode"
      >
        <center>
          {data.otherdata.loss !== null
            ? data.otherdata.loss.high_name +
              " | " +
              data.otherdata.loss.low_name
            : "0   |   0"}
        </center>
      </div>
    
      {data.otherdata.max !== null ? (
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            left: 20,
            borderStyle: 2,
            borderRadius:1,
            borderColor: "black",
            background: "green",
          }}
          id={data.otherdata.max_id}
          isConnectable={isConnectable}
        />
      ) : (
        <></>
      )}
        {data.otherdata.min !== null ? (
        <Handle
          type="source"
          position={Position.Bottom}
          id={data.otherdata.min_id}
          style={{
            left: 50,
            borderStyle: 2,
            borderRadius:1,

            borderColor: "black",
            background: "green",
          }}
          // style={handleStyle}
          isConnectable={isConnectable}
        />
      ) : ( 
        <></>
      )}
    </div>
  );
}

export default CouplerNode;
