import { CModal, CModalHeader } from "@coreui/react";
import React from "react";

function ImageModal({ show, onClose, img }) {
  return (
    <CModal
      show={show}
      onClose={onClose}
      className="modal-centered"
      closeOnBackdrop={false}
      fade
    >
      <CModalHeader closeButton></CModalHeader>

      <img src={img} />
    </CModal>
  );
}

export default ImageModal;
