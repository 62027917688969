import React, { useEffect, useState } from "react";
import { LossAPI, MapApi } from "src/Config/API";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CModalHeader,
  CModal,
  CForm,
  CRow,
  CContainer,
} from "@coreui/react";
import InputMask from "react-input-mask";
import plcwc from "./../../../../assets/losses/plcswc.jpg";
import plcwoc from "./../../../../assets/losses/plcswoc.jpg";
import { ArrowBack } from "@material-ui/icons";
import swal from "sweetalert";

function PLCLossChart({
  show,
  onClose,
  CouplorIDForSaveLossData,
  onupdateData,
  MapKey,
}) {
  const [SelectedLossId, setSelectedLossId] = useState(0);
  const [PLossWithData, setPLossWithData] = useState([]);
  const [PLossWithoutData, setPLossWithoutData] = useState([]);
  const [checkTypeOfLoss, setcheckTypeOfLoss] = useState("");
  useEffect(() => {
    getCoulporLossWith(7);
    getCoulporLossWithout(8);
  }, []);

  function getCoulporLossWith(catid) {
    LossAPI.getLossData(catid)
      .then(function (response) {
        setPLossWithData(response.data.loss);

        console.log(response.data);
      })
      .catch(function (err) {
        console.log("err");
      });
  }
  function getCoulporLossWithout(catid) {
    LossAPI.getLossData(catid)
      .then(function (response) {
        setPLossWithoutData(response.data.loss);

        console.log(response.data);
      })
      .catch(function (err) {
        console.log("err");
      });
  }
  const handleUpdateLossData = (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("loss_id", SelectedLossId);
    MapApi.UpdateMasterDataById(
      CouplorIDForSaveLossData,
      {
        loss_id: SelectedLossId,
      },
      MapKey
    )
      .then((rsponse) => {
        console.log(rsponse.data);
        onClose();
        onupdateData();
      })
      .catch((error) => {
        console.log(error);
        swal({
          title: "Ops!",
          text: error.response.data.error,
          icon: "warning",
          button: "Okay!",
        });
      });
  };
  return (
    <CModal
      show={show}
      onClose={onClose}
      className="modal-centered"
      // size="lg"
      closeOnBackdrop={false}
      fade
    >
      <CModalHeader closeButton>
        <h3>
          <center>Select Loss From Charts</center>
        </h3>
      </CModalHeader>
      {checkTypeOfLoss === "" ? (
        <>
          <CCard style={{ padding: 20 }}>
            <br />
            <br />
            <CRow>
              <CCol>
                <CButton
                  onClick={() => setcheckTypeOfLoss("losswith")}
                  color="info"
                  block
                >
                  PLC Splitter (With Connecter)
                </CButton>
              </CCol>
              <CCol>
                <CButton
                  onClick={() => setcheckTypeOfLoss("losswithout")}
                  color="info"
                  block
                >
                  PLC Splitter (Without Connecter)
                </CButton>
              </CCol>
            </CRow>
            <br />
          </CCard>
        </>
      ) : (
        <></>
      )}

      <CRow>
        {checkTypeOfLoss === "losswith" ? (
          <CCol>
            <div>
              <ArrowBack
                style={{ margin: 20, cursor: "pointer" }}
                onClick={() => setcheckTypeOfLoss("")}
              />
              <center>
                <h4 style={{ display: "inline-block" }}>
                  PLC Splitter (With Connecter)&nbsp;&nbsp;&nbsp;
                  <img src={plcwc}
                    width="10%"
                    className="imgstyle"
                  />
                </h4>
              </center>
              <CForm onSubmit={handleUpdateLossData}>
                <CContainer>
                  <CCard>
                    <CCardBody>
                      <table class="table table-borderless">
                        {PLossWithData?.map((Loss, index) => (
                          <tr>
                            <td>1 &#10006; {Loss.high_name}</td>
                            <td>&#10140;</td>
                            <td>
                              <InputMask
                                mask="99.99"
                                className="inputsm"
                                type="text"
                                name="high_value"
                                value={Loss.high_value}
                                //   onChange={(e) => handleChange(index, e)}
                              ></InputMask>{" "}
                              DB
                            </td>
                            <td>
                              <input
                                type="radio"
                                name="selection"
                                value={Loss.id}
                                // checked={Loss.id === SelectedLossId ? true :false}
                                onChange={(v) => {
                                  console.log(v.target.value);
                                  setSelectedLossId(v.target.value);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </table>
                      <center>
                        <CButton
                          color="info"
                          style={{ display: "inline-block" }}
                          type="submit"
                        >
                          Save
                          {/* {LoadingToUpdate ? "Saving.." : "Save"} */}
                        </CButton>
                        &nbsp;&nbsp;
                        <CButton color="info" onClick={() => onClose()}>
                          Close
                        </CButton>
                        &nbsp;&nbsp;
                      </center>
                    </CCardBody>
                  </CCard>
                </CContainer>
              </CForm>
            </div>
          </CCol>
        ) : checkTypeOfLoss === "losswithout" ? (
          <CCol>
            <div>
              <ArrowBack
                style={{ margin: 20, cursor: "pointer" }}
                onClick={() => setcheckTypeOfLoss("")}
              />
              <center>
                <h4 style={{ display: "inline-block" }}>
                  PLC Splitter (Without Connecter)&nbsp;&nbsp;&nbsp;
                  <img src={plcwoc}
                    width="10%"
                    className="imgstyle"
                  />
                </h4>
              </center>
              <CForm onSubmit={handleUpdateLossData}>
                <CContainer>
                  <CCard>
                    <CCardBody>
                      <table class="table table-borderless">
                        {PLossWithoutData?.map((Loss, index) => (
                          <tr>
                            <td>1 &#10006; {Loss.high_name}</td>
                            <td>&#10140;</td>
                            <td>
                              <InputMask
                                mask="99.99"
                                className="inputsm"
                                type="text"
                                name="high_value"
                                value={Loss.high_value}
                                //   onChange={(e) => handleChange(index, e)}
                              ></InputMask>{" "}
                              DB
                            </td>
                            <td>
                              <input
                                type="radio"
                                name="selection"
                                value={Loss.id}
                                // checked={Loss.id === SelectedLossId ? true :false}
                                onChange={(v) => {
                                  console.log(v.target.value);
                                  setSelectedLossId(v.target.value);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </table>
                      <center>
                        <CButton
                          color="info"
                          style={{ display: "inline-block" }}
                          type="submit"
                        >
                          Save
                          {/* {LoadingToUpdate ? "Saving.." : "Save"} */}
                        </CButton>
                        &nbsp;&nbsp;
                        <CButton color="info" onClick={() => onClose()}>
                          Close
                        </CButton>
                        &nbsp;&nbsp;
                      </center>
                    </CCardBody>
                  </CCard>
                </CContainer>
              </CForm>
            </div>
          </CCol>
        ) : (
          <></>
        )}
      </CRow>
    </CModal>
  );
}

export default PLCLossChart;
