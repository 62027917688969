import React, {
  useEffect,
  useState,
} from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CDataTable,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CRow,
  CTooltip,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Link, Redirect, useHistory } from "react-router-dom";
import CalculateLoss from "src/views/CalculateLoss/calculateloss";
import { CustomersLocationAPI, LossAPI, MapApi } from "src/Config/API";
const TheHeaderShowCustomerList = ({ custCallFun, portId }) => {

  useEffect(() => {
    getCustomerLocation();
  }, [])
  const history = useHistory();


  const [Data, setData] = useState([]);
  const fields = [
    { key: "user_name", label: "Customer Name" },
    { key: "phone", label: "Phone Number" },
    { key: "city", label: "Locations" },
    { key: "action", label: "Add" },
  ];
  const getCustomerLocation = () => {
    CustomersLocationAPI.getCustomersData().then((res) => {
      setData(res.data.data);
    }).catch((err) => {
    })
  }


  function PostCustomerData(customer_id, port_id, lat, lng) {
    var data = {
      port_id: port_id,
      latitude: lat,
      longitude: lng,
      customer_id: customer_id,
    };
    MapApi.addCustomersData(port_id, data)
      .then((respose) => {
        custCallFun();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <div>
      <CRow>
        <CDropdown inNav className="c-header-nav-item mx-2">
          <CDropdownToggle className="c-header-nav-link " caret={false}>
            <CTooltip content={"Customers Locations"}>
              <CIcon name="cil-people" className="iconHVR" style={{ width: 18, height: 18 }} />
            </CTooltip>
            &nbsp;&nbsp;&nbsp;
          </CDropdownToggle>
          <CDropdownMenu placement="bottom-end" className="pt-0">
            <CDropdownItem
              header
              tag="div"
              className="text-center"
              color="light"
            >
              {/* <strong>Customer List</strong> */}
            </CDropdownItem>
            <CCard>
              <CCardHeader>
                <CButton className={"float-right"} color='info' variant='outline'
                  onClick={() => {
                    history.push({
                      pathname: `/app/customers/addnew`,
                      state: {
                        id: 1
                      },
                    });
                  }}
                >Add New Customer</CButton>
              </CCardHeader>


              <CCardBody>
                <CDataTable
                  items={Data}
                  fields={fields}
                  itemsPerPageSelect
                  itemsPerPage={5}
                  hover
                  columnFilter
                  pagination
                  scopedSlots={{
                    action: (item) => {
                      return <><td>
                        <CButton color="info" onClick={() => {
                          PostCustomerData(item.id, portId, item.latitude, item.longitude);
                        }}>
                          Add
                        </CButton></td></>
                    }
                  }}
                />
              </CCardBody>


            </CCard>

          </CDropdownMenu>
        </CDropdown>
      </CRow>

    </div>
  );
};

export default TheHeaderShowCustomerList;
