import { useCallback } from "react";
import { Handle, Position } from "reactflow";

const handleStyle = { left: 20 };

function PLCNode({ data, isConnectable }) {
  return (
    <div>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
        style={{ borderStyle: 2, borderColor: "black", background: "green" }}
      />
      <div
        style={{
          fontSize: 15,
          height: 35,
          paddingTop: 3,
          width: 70,
          alignContent: "center",
        }}
        className="cutomernode"
      >
        <center>
          {" "}
          {data.otherdata.loss !== null
            ? "1 X " + data.otherdata.loss.high_name
            : "0   X   0"}
        </center>{" "}
      </div>
      {data.otherdata.max !== null ? (
        <Handle
          id={data.otherdata.max_id}
          type="source"
          position={Position.Bottom}
          style={{
            left: 35,
            borderStyle: 2,
            // borderColor: "black",
            background: "green",
          }}
          isConnectable={isConnectable}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default PLCNode;
