import {
  CCol,
  CDataTable,
  CModal,
  CModalBody,
  CModalHeader,
} from "@coreui/react";
import React, { useEffect } from "react";

function OnuList({ show, onClose, data }) {
  const fields = [
    { key: "Name", _style: { width: "15%" } },
    { key: "ONU_Brand", _style: { width: "10%" } },
    { key: "Distance", _style: { width: "10%" } },
    { key: "OutPut_Power", _style: { width: "20%" }, label: "ONU Tx Power (DB)\n 1310 NM" },
    { key: "OLT_Return_Power", _style: { width: "20%" }, label: "OLT Receive Power (DB)\n 1310 NM  "},
    { key: "Onu_Return_Power", _style: { width: "20%" }, label: "ONU Receive Power (DB)\n 1490 NM  "},
  ];
  return (
    <CModal
      show={show}
      onClose={onClose}
      className="modal-centered"
      size="xl"
      closeOnBackdrop={false}
      fade
    >
      <CModalHeader closeButton>ONU</CModalHeader>
      <CModalBody>
        {data !== null ? (
          <>
            <CDataTable
              items={data}
              fields={fields}
              columnFilter
              tableFilter
              // footer
              itemsPerPageSelect
              itemsPerPage={5}
              hover
              sorter
              pagination
              scopedSlots={{
                Name: (item) => <td>{item.name ? item.name : "-"}</td>,
                ONU_Brand: (item) => (
                  <td>
                    {item.onudata.company_name
                      ? item.onudata.company_name
                      : "-"}
                  </td>
                ),
                Distance: (item) => (
                  <td style={{ color: item.onudata.highlited === true ? 'red' : "" }}>{item.onudata.distance ?
                    item.onudata.distance + " KM" : "-"}</td>
                ),

                OutPut_Power: (item) => (
                  <td>{item.onudata.power_tx ? item.onudata.power_tx : "-"}</td>
                ),

                OLT_Return_Power: (item) => (
                  <td>{item.onudata.power_rx ? item.onudata.power_rx : "-"}</td>
                ),
                Onu_Return_Power: (item) => (
                  <td>{item.onudata.power_rx ? item.loss_1490NM : "-"}</td>
                ),
              }}
            />
          </>
        ) : (
          <></>
        )}
      </CModalBody>
    </CModal>
  );
}

export default OnuList;