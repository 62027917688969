export const sygnet = ['160 160', `
  <title>Navya Techs</title>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 188 188">
  <g id="Group_20" data-name="Group 20" transform="translate(24 1054)">
    <circle id="Ellipse_1" data-name="Ellipse 1" cx="94" cy="94" r="94" transform="translate(-24 -1054)" fill="#80d0ff"/>
    <g id="Group_16" data-name="Group 16" transform="translate(-412.75 -815.507)">
      <g id="Group_12" data-name="Group 12">
        <g id="temp_abstract-703BR370BR3_temp" transform="translate(419 -201.17)">
          <path id="Path_25" data-name="Path 25" d="M24.14,36.078a6.781,6.781,0,0,0-2.515-2.516,6.894,6.894,0,0,0-3.459-.914,7.03,7.03,0,0,0-5.091,1.985,6.885,6.885,0,0,0-1.555,2.291A6.813,6.813,0,0,0,11,39.634V120H41.388V65.11L29.117,44.448l-4.936-8.316Z" transform="translate(0 -0.83)" fill="#fff"/>
          <path id="Path_26" data-name="Path 26" d="M78.629,1V55.944L95.838,84.973a6.775,6.775,0,0,0,2.513,2.542,6.889,6.889,0,0,0,3.473.926,7.45,7.45,0,0,0,1.344-.126,1.372,1.372,0,0,0,.275-.071,7.738,7.738,0,0,0,1.031-.292l.128-.058a7.253,7.253,0,0,0,2.179-1.33c.151-.136.309-.339.464-.492a4.421,4.421,0,0,0,.309-.339,6.464,6.464,0,0,0,.467-.729c.086-.146.189-.285.265-.441a7.206,7.206,0,0,0,.344-.909c.055-.176.13-.339.172-.522a7.4,7.4,0,0,0,.2-1.7V1Z" transform="translate(0.251)" fill="#fff"/>
          <path id="Path_27" data-name="Path 27" d="M109.251,119.17V89.433a11.886,11.886,0,0,1-7.191,2.439,11.064,11.064,0,0,1-5.547-1.442,10.748,10.748,0,0,1-4.022-4L73.483,54.673l-.035-.054-4.39-7.348a2.027,2.027,0,0,1-.121-.179L41.371,1.013H11.014V30.843A11.886,11.886,0,0,1,18.2,28.4a11.085,11.085,0,0,1,5.5,1.415,10.772,10.772,0,0,1,4.022,3.935h0l.045.071L55.424,79.976l.083.138L78.9,119.166Z" fill="#fff"/>
        </g>
        <path id="Path_28" data-name="Path 28" d="M68.6,85.679,104.941,21.97a1.3,1.3,0,0,0-.019-1.315,1.348,1.348,0,0,0-.5-.479A1.392,1.392,0,0,0,103.75,20H86.158a7.82,7.82,0,0,0-3.887,1.035,7.555,7.555,0,0,0-2.81,2.813L46.026,82.436l-.012.024a1.5,1.5,0,0,1-.155.3l-1.671,2.924Z" transform="translate(521.041 -281.254) rotate(60)" fill="#fff"/>
      </g>
    </g>
  </g>
</svg>

`]
