import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function TestTrans({match}) {
  const location = useLocation();

    useEffect(() => {
        console.log(location);
        console.log(match);
    }, [])
    
  return (
    <div>TestTrans</div>
  )
}

export default TestTrans