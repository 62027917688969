import {
  CBadge,
  CButton,
  CCol,
  CDataTable,
  CDropdown,
  CForm,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalHeader,
  CRow,
  CSelect,
} from "@coreui/react";
import React, { useEffect, useState } from "react";

import swal from "sweetalert";

import { MapApi } from "src/Config/API";
import { Edit, Delete } from "@material-ui/icons";
import LoadingModel from "src/views/components/loadingmodel";
const Customers = ({ id, ShowModal, close, MapKey }) => {
  const [getCustomerData, setgetCustomerData] = useState([]);

  const [customerName, setcustomerName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [address, setaddress] = useState("");
  const [setUpBox, setsetUpBox] = useState("");
  const [type, settype] = useState("SD");
  const [activeUser, setactiveUser] = useState(1);
  const [editBtnVisibility, seteditBtnVisibility] = useState(false);
  const [CustomerId, setCustomerId] = useState(0);
  const [Loading, setLoading] = useState(true);
  const handleSumbitCustomer = (e) => {
    e.preventDefault();

    const data = {
      name: customerName,
      phone: phoneNumber,
      address: address,
      setupbox_company: setUpBox,
      active: activeUser,
      type: type,
      optical_receiver_id: id,
    };
    MapApi.addCustomerDataByOrId(data, MapKey)
      .then((response) => {
        console.log(response.data);
        getData();
        handleClearData();
      })
      .catch((error) => {
        console.log(error);
        swal({
          title: "Ops!",
          text: error.response.data.msg,
          icon: "warning",
          button: "Okay!",
        });
      });
    console.log(data);
  };
  const handleClearData = () => {
    setcustomerName("");
    setphoneNumber("");
    setaddress("");
    setsetUpBox("");
    settype("SD");
    setactiveUser(1);
    seteditBtnVisibility(false);
  };
  useEffect(() => {
    setgetCustomerData([]);
    setLoading(true);
    getData();
  }, [id]);
  const getData = () => {
    MapApi.getCustomerDataByOrId(id, MapKey)
      .then((resposne) => {
        console.log(resposne.data.data);
        setgetCustomerData(resposne.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleDelete = (id) => {
    MapApi.DeleteCustomerDataByOrId(id, MapKey)
      .then((response) => {
        getData();
      })
      .catch((error) => { });
  };
  const fields = [
    { key: "name", _style: { width: "20%" } },
    { key: "phone", _style: { width: "30%" } },
    { key: "address", _style: { width: "30%" } },
    { key: "setupbox_company", _style: { width: "30%" } },
    { key: "active", _style: { width: "30%" } },
    { key: "type", _style: { width: "30%" } },
    { key: "oprations", _style: { width: "30%" } },
  ];
  const handleEdit = (e) => {
    e.preventDefault();
    const data = {
      name: customerName,
      phone: phoneNumber,
      address: address,
      setupbox_company: setUpBox,
      active: activeUser,
      type: type,
      // optical_receiver_id: CustomerId,
    };
    MapApi.EditCustomerDataByOrId(CustomerId, data, MapKey)
      .then((response) => {
        getData();
      })
      .catch((error) => {


      });
    console.log(data);
  };
  return (
    <div>
      <CModal
        show={ShowModal}
        onClose={close}
        className="modal-centered"
        centered
        size="xl"
        closeOnBackdrop={false}
        fade
      >
        <CModalHeader closeButton>Customers</CModalHeader>
        <CModalBody>
          {Loading === true ? (
            <LoadingModel
              handleModel={Loading}
              handleCloseModel={setLoading(!Loading)}
            />
          ) : (
            <>
              <CCol>
                <CRow>
                  <CForm onSubmit={editBtnVisibility ? handleEdit : handleSumbitCustomer}>
                    <CCol>
                      <CRow >
                        <CCol md="3" py="3" style={{ marginBottom: 10 }}>
                          <CInput
                            placeholder="name*"
                            type="text"
                            required
                            value={customerName}
                            onChange={(e) => setcustomerName(e.target.value)}
                          ></CInput>
                        </CCol>

                        <CCol md="3" py="3" style={{ marginBottom: 10 }}>
                          <CInput
                            placeholder="Phone Number*"
                            required
                            type="number"
                            value={phoneNumber}
                            onChange={(e) => setphoneNumber(e.target.value)}
                          ></CInput>
                        </CCol>
                        <CCol md="3" py="3" style={{ marginBottom: 10 }} >
                          <CInput
                            placeholder="Address"
                            value={address}
                            onChange={(e) => setaddress(e.target.value)}
                          ></CInput>
                        </CCol>
                        <CCol md="3" py="3" style={{ marginBottom: 10 }}>
                          <CInput
                            placeholder="SetUpBox Company"
                            value={setUpBox}
                            onChange={(e) => setsetUpBox(e.target.value)}
                          ></CInput>
                        </CCol>
                      </CRow>
                      <br />
                      <CRow >
                        <CCol md="3" py="3" style={{ marginBottom: 10 }}>
                          {/* <CLabel style={{ marginRight: 20 }}>Type</CLabel> */}
                          <CDropdown style={{ width: "100%" }}>
                            <CSelect onChange={(e) => settype(e.target.value)} required>
                              <option value="" style={{ display: "none" }}>Type</option>
                              <option value="sd">SD</option>
                              <option value="hd">HD</option>
                              <option value="hd">ANDROID</option>
                              <option value="hd">IPTV</option>
                            </CSelect>
                          </CDropdown>
                        </CCol>
                        <CCol md="3" py="3" style={{ marginBottom: 10 }}>
                          <CRow>
                            {/* <CLabel style={{ marginRight: 20 }}>Active User</CLabel> */}

                            <CDropdown style={{ width: "100%" }}>
                              <CSelect onChange={(e) => setactiveUser(e.target.value)} required>
                                <option value="" style={{ display: "none" }}>Active User</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                              </CSelect>
                            </CDropdown>
                          </CRow>
                        </CCol>
                        <CCol md="3" style={{ marginBottom: 10 }}>
                          <CButton
                            color="primary"
                            block
                            type={"submit"}
                          // onClick={() =>

                          // }
                          >
                            {editBtnVisibility ? "Update" : "Submit"}
                          </CButton>
                        </CCol>
                        <CCol md="3" style={{ marginBottom: 10 }}>
                          <CButton
                            color="warning"
                            block
                            onClick={() => handleClearData()}
                          >
                            Clear
                          </CButton>
                        </CCol>
                      </CRow>
                      <hr />
                    </CCol>

                  </CForm>



                </CRow>
                <CRow>
                  {getCustomerData ? (
                    <CDataTable
                      items={getCustomerData}
                      fields={fields}
                      columnFilter
                      tableFilter
                      // footer
                      itemsPerPageSelect
                      itemsPerPage={5}
                      hover
                      sorter
                      pagination
                      scopedSlots={{
                        active: (item) => (
                          <td>
                            <CBadge
                              color={item.active === 1 ? "success" : "danger"}
                            >
                              {item.active === 1 ? "Yes" : "No"}
                            </CBadge>
                          </td>
                        ),
                        oprations: (item) => (
                          <td>
                            <Edit
                              style={{
                                marginLeft: 5,
                                marginRight: 5,
                                cursor: "pointer",
                                color: "black",
                              }}
                              onClick={() => {
                                setcustomerName(item.name);
                                setphoneNumber(item.phone);
                                setaddress(item.address);
                                setsetUpBox(item.setupbox_company);
                                settype(item.type);
                                setactiveUser(item.active);
                                seteditBtnVisibility(true);
                                setCustomerId(item.id);
                              }}
                            />
                            <Delete
                              style={{
                                marginLeft: 5,
                                marginRight: 5,
                                cursor: "pointer",
                                color: "red",
                              }}
                              onClick={() => {
                                handleDelete(item.id);
                              }}
                            />
                          </td>
                        ),
                      }}
                    />
                  ) : (
                    <div></div>
                  )}
                </CRow>
              </CCol>



            </>
          )}
        </CModalBody>
      </CModal>
    </div>
  );
};

export default Customers;
